import React from 'react';
import HTMLFlipBook from 'react-pageflip';
import { SliderNext, SliderPrev } from '../../../../Arrows';
import { MDLandscapeNormalSpec } from '../../../../Meeting/MeetingLayout/MDLandscapeNormal';
import { SMLandscapeNormalSpec } from '../../../../Meeting/MeetingLayout/SMLandscapeNormal';
import { MagazinePageDimension } from './Magazine';

interface IProps {
  width?: number;
  height?: number;
  size?: 'fixed' | 'stretch';
  minWidth?: number;
  maxWidth?: number;
  minHeight?: number;
  maxHeight?: number;
  drawShadow?: boolean;
  flippingTime?: number;
  usePortrait?: boolean;
  startZIndex?: number;
  autoSize?: boolean;
  maxShadowOpacity?: number;
  showCover?: boolean;
  mobileScrollSupport?: boolean;
  onFlip?: (e: any) => void;
  clickEventForward?: boolean;
}

interface Props {
  close: boolean;
  pages: React.ReactNode[];
  onPageChange: (page: number) => void;
  activePage: number;
  pageDimension: MagazinePageDimension;
}

const MagazineFlip = ({
  close,
  pages,
  onPageChange,
  activePage,
  pageDimension
}: Props) => {
  const magRef = React.useRef<any>();
  const [props, setProps] = React.useState<IProps>();
  const isFirstPage = activePage === 0;
  const isLastPage = activePage === pages.length - 1;

  const handleFlip = (e) => {
    if (!close) {
      onPageChange(e.data);
    }
    return null;
  };

  React.useEffect(() => {
    const initProps: IProps = {
      size: 'stretch',
      width: pageDimension.width,
      height: pageDimension.height,
      maxWidth: pageDimension.maxWidth,
      maxHeight: pageDimension.maxHeight,
      showCover: true,
      autoSize: true,
      clickEventForward: true,
      drawShadow: false,
      onFlip: handleFlip
    };
    setProps(initProps);
  }, [pageDimension]);

  React.useEffect(() => {
    if (close) {
      onPageChange(0);
    }
  }, [close]);

  React.useEffect(() => {
    magRef?.current?.getPageFlip()?.flip(activePage);
  }, [activePage, magRef]);

  return (
    <div className="wrapper">
      <div className="book-container">
        {props && (
          <HTMLFlipBook {...props} ref={magRef}>
            {pages.map((page, i) => (
              <div className="flipPage" key={i}>
                {page}
              </div>
            ))}
          </HTMLFlipBook>
        )}
      </div>
      {!isFirstPage && (
        <SliderPrev onClick={() => magRef.current.getPageFlip().flipPrev()} />
      )}
      {!isLastPage && (
        <SliderNext onClick={() => magRef.current.getPageFlip().flipNext()} />
      )}
      <style jsx>{`
        p {
          color: #fff;
          position: fixed;
          bottom: 0;
          left: 0;
          right: 0;
          text-align: center;
          font-size: 2em;
        }

        .book-container {
          width: 100%;
          margin: auto;
        }

        :global(.in-meeting.MDLandscape) .book-container {
          width: calc(100% - ${MDLandscapeNormalSpec.contentArea.left}px);
        }
        :global(.in-meeting.SMLandscape) .book-container {
          width: calc(100% - ${SMLandscapeNormalSpec.contentArea.left}px);
        }

        .wrapper {
          text-align: center;
          overflow: hidden;
          position: fixed;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          z-index: 100;
        }

        :global(.in-meeting.MDLandscape) .wrapper {
          left: ${MDLandscapeNormalSpec.contentArea.left}px;
        }
        :global(.in-meeting.SMLandscape) .wrapper {
          left: ${SMLandscapeNormalSpec.contentArea.left}px;
        }

        .flipPage {
          box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
        }

        .flipPage img {
          width: 100%;
          height: auto;
        }
        .htmlPage {
          background: #fff;
        }
        .book-container {
          margin: auto;
        }
        :global(.stf__parent) {
          text-align: center;
          margin: auto;
        }
        :global(.stf__wrapper) {
          transition: transform 0.4s ease-in-out;
          transform: ${isFirstPage
            ? `translateX(-25%)`
            : isLastPage
            ? `translateX(25%)`
            : 'none'};
        }
      `}</style>
    </div>
  );
};

export default MagazineFlip;
