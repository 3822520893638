import { useDispatch } from 'react-redux';
import { actionUpdateLoubiAirwaysPlanePlan } from '../../../../../redux/customActions/loubiAirways';
import { useLoubiAirwaysState } from '../../../../hooks/loubiAirways';
import LoubiButtonWrapper from './LoubiButtonWrapper';
import { logEvent } from '../../../../../analytics';
import { DID_CLICK_PLANE_PLAN_BUTTON } from '../../../../../utils/constants';

const LoubiAirwaysPlanMapAccessButton = ({
  imageUrl
}: {
  imageUrl?: string;
}) => {
  const dispatch = useDispatch();

  const planState = useLoubiAirwaysState()?.planePlan;

  const handleClick = () => {
    dispatch(
      actionUpdateLoubiAirwaysPlanePlan({
        ...planState,
        selectedSpot: undefined,
        open: !planState?.open
      })
    );
    logEvent(DID_CLICK_PLANE_PLAN_BUTTON, DID_CLICK_PLANE_PLAN_BUTTON);
  };
  return (
    <LoubiButtonWrapper>
      <img
        src={imageUrl || '/asset/louboutin/plan_map_access.svg'}
        alt="open plan map"
        onClick={handleClick}
        className={`loubi-circle-button top-right hide-for-client-meeting`}
      />
    </LoubiButtonWrapper>
  );
};

export default LoubiAirwaysPlanMapAccessButton;
