import React from 'react';
import { useDispatch } from 'react-redux';
import { logEvent } from '../../../../../analytics';
import {
  LoubiAirwaysAnimation,
  REMOTE_ACTION
} from '../../../../../interfaces/loubiairways';
import { actionUpdateLoubiAirwaysSelectedModel } from '../../../../../redux/customActions/loubiAirways';
import {
  DID_CLICK_2D_PRODUCT_HOT_SPOT,
  DID_HOVER_2D_PRODUCT_HOTSPOT
} from '../../../../../utils/constants';
import { useLoubiAirwaysState } from '../../../../hooks/loubiAirways';
import { AnimationContext } from '../../../../ThreejsAnimation/louboutin/AnimationContext';
import { selectEl } from '../animationUtils';
import {
  get2DProductImage,
  get2DAnimationProductByModel
} from './TwoDProductsList';

const TwoDProductHotSpot = ({
  model,
  className = '',
  left = 0,
  top = 0,
  width,
  height,
  rotate = 0,
  onClick,
  type = 'image',
  flip = false
}: {
  model: string;
  className?: string;
  left?: number;
  top?: number;
  width?: number;
  height?: number;
  onClick?: () => void;
  rotate?: number;
  type?: 'image' | 'overlay';
  flip?: boolean;
}) => {
  const dispatch = useDispatch();
  const { setSelectedModel, activeAnimation } = React.useContext(
    AnimationContext
  );
  const selectedModel = useLoubiAirwaysState()?.selectedModel;

  const [opened, setOpened] = React.useState(false);

  const product = get2DAnimationProductByModel(model);
  const imgUrl = get2DProductImage(product);
  const ref = React.useRef<HTMLImageElement>();

  const hotspotState = selectedModel?.state;

  const selectModel = () => {
    setSelectedModel(product);
    if (onClick) {
      onClick();
    }
    logEvent(DID_CLICK_2D_PRODUCT_HOT_SPOT, DID_CLICK_2D_PRODUCT_HOT_SPOT, {
      model
    });
  };

  const animateImgOpening = () => {
    setOpened(true);
    selectEl(ref)
      .removeClass('closing')
      .addClass('appear')
      .addClass('opened', 800);
  };

  const animateImgClosing = () => {
    setOpened(false);
    selectEl(ref)
      .addClass('closing')
      .addClass('appear')
      .removeClass('appear', 400)
      .removeClass('opened');
  };

  const handleMouseEnter = () => {
    if (!opened) {
      dispatch(
        actionUpdateLoubiAirwaysSelectedModel({
          model: product,
          state: REMOTE_ACTION.FOCUS
        })
      );
    }
    logEvent(DID_HOVER_2D_PRODUCT_HOTSPOT, DID_HOVER_2D_PRODUCT_HOTSPOT, {
      model,
      state: REMOTE_ACTION.FOCUS
    });
  };

  const handleMouseOut = () => {
    if (!opened) {
      dispatch(actionUpdateLoubiAirwaysSelectedModel(undefined));
    }
    logEvent(DID_HOVER_2D_PRODUCT_HOTSPOT, DID_HOVER_2D_PRODUCT_HOTSPOT, {
      model,
      state: REMOTE_ACTION.UN_FOCUS
    });
  };

  React.useEffect(() => {
    if (type === 'image') {
      if (!selectedModel && opened) {
        setTimeout(animateImgClosing, 200);
      }
      if (
        hotspotState === REMOTE_ACTION.OPEN &&
        selectedModel?.model?.modelCode === product.modelCode &&
        !opened
      ) {
        setTimeout(
          animateImgOpening,
          activeAnimation === LoubiAirwaysAnimation.TRAY_TABLE ? 700 : 0
        );
      }
    }
  }, [selectedModel]);

  if (!product || !imgUrl) return null;

  return (
    <>
      {type === 'image' ? (
        <img
          ref={ref}
          src={imgUrl}
          className={`img-spot closing ${className} ${
            (selectedModel?.model?.modelCode === model && hotspotState) || ''
          }`}
          onClick={selectModel}
          onMouseEnter={handleMouseEnter}
          onMouseOut={handleMouseOut}
        />
      ) : (
        <button
          className={`btn btn-spot ${className}`}
          onClick={selectModel}
          onMouseEnter={handleMouseEnter}
          // onMouseOut={handleMouseOut}
        />
      )}

      <style jsx>{`
        .dev {
          opacity: 1 !important;
          background: red;
        }
        .img-spot {
          transition: all 0.4s ease-in-out;
          z-index: 200;
          opacity: 1;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: rotate(0);
        }
        .img-spot.closing {
          opacity: 0.01;
          cursor: pointer;
          transform: scale(${flip ? -1 : 1}, 1) rotate(${rotate}deg);
          margin-left: ${left || 0}%;
          margin-top: ${top || 0}%;
          width: ${width ? width + '%' : 'auto'};
          height: ${height ? height + '%' : 'auto'};
          z-index: 100;
        }

        .img-spot.FOCUS {
          transform: rotate(${flip ? rotate * -1 : rotate}deg)
            scale(${flip ? -1.2 : 1.2}, 1.2);
        }

        .img-spot.appear {
          opacity: 1;
          pointer-events: none;
        }

        .img-spot.opened {
          opacity: 0;
        }

        .btn-spot {
          position: absolute;
          z-index: 100;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%) rotate(${rotate || 0}deg);
          margin-left: ${left || 0}%;
          margin-top: ${top || 0}%;
          width: ${width || 20}%;
          height: ${height || 20}%;
        }
        .btn-spot:focus {
          box-shadow: none;
          outline: none;
        }
        .btn-spot div {
          pointer-events: none;
        }
        @media (orientation: landscape) {
          .img-spot {
            width: 80vh;
            margin-left: -40vh;
            margin-top: -40vh;
          }
        }
        @media (orientation: portrait) {
          .img-spot {
            width: 100vw;
            margin-left: -50vw;
            margin-top: -50vw;
          }
        }
      `}</style>
    </>
  );
};

export default TwoDProductHotSpot;
