import React from 'react';
import * as THREE from 'three';
import { assetBaseUrl } from '../../../config';
import { isUserOnMobile } from '../../../utils/deviceDetector';
import {
  IAnimationProduct,
  ILoubiBeautyCartAnimationState,
  REMOTE_ACTION
} from '../../../interfaces/loubiairways';
import {
  modelWorkshopEnvironmentImagePath,
  beautyCartImg,
  lipstickTexturesUrl,
  fragranceProducts,
  lipstick,
  lipstickLow,
  lipstickColors,
  lipstick2D
} from './assets';
import BackToAnimationButton from '../../VirtualBoutique/CustomComponent/LouboutinCustomComponent/Buttons/BackToAnimationButton';

let beautyCartRemoteState = undefined;
let clickBack = false;
let backDiv;
let perfumeDiv;
const isMobileOrTab = isUserOnMobile();
const onClickBack = () => {
  clickBack = true;
};
const BeautyCart = ({
  remoteState,
  updateRemoteState,
  didSelectModel,
  onReady,
  viewOnly
}: {
  remoteState?: ILoubiBeautyCartAnimationState;
  updateRemoteState: (state: ILoubiBeautyCartAnimationState) => void;
  didSelectModel: (model: IAnimationProduct) => void;
  onReady: (val) => void;
  viewOnly?: boolean;
}) => {
  const SCL = 1;
  const maxScale = 16 * SCL;
  const minScale = 5 * SCL;
  const incScale = 0.5 * SCL;
  const mouseDownPos = new THREE.Vector2();
  const mouse = new THREE.Vector2();
  const mouse_2 = new THREE.Vector2();
  const raycaster = new THREE.Raycaster();
  const rayCasterScale = new THREE.Vector3(5, 4, 1);
  const rayCasterModelScale = new THREE.Vector3(0.05, 0.14, 0.05);
  const focusItemPosition = new THREE.Vector3(0, 0, 15);
  const focusPerfumePosition = new THREE.Vector3(0, 0.3, 8);
  const NO_OF_FRAGRANCE = fragranceProducts.length;
  const ImgDiffX = 5.5;
  let zoomModel = undefined;
  let isLoading = false;
  let doubleTapClick = 0;
  const touchType = {
    touchDown: 'touchDown',
    touchMove: 'touchMove',
    touchUp: 'touchUp'
  };
  let initDistance = 0;
  let initScale = 1;
  let controls = undefined;
  let isDown = false;
  let isTweening = false;
  let transparentPlane = undefined;
  const transparentMat = new THREE.MeshBasicMaterial({
    transparent: true,
    opacity: 0.71,
    color: 0x000000
  });
  const imgPerfumes = [];
  let imgLipstick = [];
  let colorLipstick = [];
  let textureLipstick = [];
  let itemsChildForCatchingCartClick = [];
  let itemsChildForCatchingModelClick = [];
  let isModelLive = false;
  let textureCart;
  const canvasRef = React.useRef();
  let camera, GLTFLoader, RGBELoader, TWEEN, ObjectControls, renderer;
  let scene = new THREE.Scene();
  let loadingCount = 0;

  let blackMattePackMap,
    blackMattePackNormal,
    blackMattePackMetallic,
    blackShinyPackMap,
    blackShinyPackNormal,
    blackShinyPackMetallic,
    blackSnakeSkinPackMap,
    blackSnakeSkinPackNormal,
    blackSnakeSkinPackMetallic,
    goldenMattePackMap,
    goldenMattePackNormal,
    goldenMattePackMetallic,
    goldenSilkyPackMap,
    goldenSilkyPackNormal,
    goldenSilkyPackMetallic,
    goldenSnakeSkinPackMap,
    goldenSnakeSkinPackNormal,
    goldenSnakeSkinPackMetallic,
    matteFluidMap,
    matteFluidNormal,
    matteFluidMetallic,
    metalMatteFluidMap,
    metalMatteFluidNormal,
    metalMatteFluidMetallic;

  const matName = [
    'Bullet',
    'Bullet',
    'Bulb',
    'Bullet',
    'Bullet',
    'Bulb',
    'Cap',
    'Cap'
  ];
  const perfumeTextArray = [
    'FLORAL ROSE\nBLACKCURRANT\nTURKISH ROSE\nPATCHOULI',
    'FLORAL FRUITY\nSTRAWBERRIES\nROSE BOUQUET\nCEDAR WOOD',
    'FLORAL WOODY\nJASMINE\nTUBEROSE\nMUSK',
    'ORIENTAL SPICY\nCARDAMOME\nIRIS\nVANILLA',
    'LEATHER WOODY\nSUEDE LEATHER\nPINK PEPPER\nCEDAR WOOD',
    'WOODY ORIENTAL\nPATCHOULI\nCEDAR WOOD\nTONKABEAN',
    'SPICY ORIENTAL\nMYRRH\nCYPRIOL\nSANTAL WOOD'
  ];
  const [strPerfume, setStrPerfume] = React.useState('');
  const loadingManager = new THREE.LoadingManager(() => {
    callback();
  });
  const STRZOOMRAYCASTER = 'zoomRaycaster';
  const loadZoom = (id) => {
    isModelLive = true;
    isLoading = true;
    onReady(false);
    const gltfLoader = new GLTFLoader();
    const ItemId = id;
    transparentPlane.visible = true;
    let url = '';
    if (ItemId < NO_OF_FRAGRANCE) {
      url = fragranceProducts[ItemId].highPolyModelUrl;
    } else {
      url = isMobileOrTab
        ? lipstickLow[ItemId - NO_OF_FRAGRANCE]
        : lipstick[ItemId - NO_OF_FRAGRANCE];
    }

    gltfLoader.load(url, (gltf) => {
      const group = new THREE.Group();
      const root = gltf.scene;

      group.add(root);
      if (scene) scene.add(group);
      if (zoomModel) {
        loadNew(false, 0);
      }
      transparentPlane.visible = true;
      const zoomRaycaster = createRayCasterCatcher(rayCasterModelScale);

      zoomRaycaster.visible = false;
      zoomRaycaster.name = STRZOOMRAYCASTER;
      group.add(zoomRaycaster);
      zoomModel = group;
      zoomModel.ItemId = ItemId;
      group.scale.set(8, 8, 8);
      if (ItemId < NO_OF_FRAGRANCE) {
        zoomModel.name = fragranceProducts[ItemId].modelName;
        root.position.set(0, -0.07, 0);
      } else {
        updateMaterialForMobile(root, ItemId - NO_OF_FRAGRANCE);
        zoomModel.name = lipstickColors[ItemId - NO_OF_FRAGRANCE][0].modelName;
        root.scale.set(2, 2, 2);
        root.position.set(0, -0.0, 0);
        if (ItemId < 13) zoomModel.getObjectByName('Cap').position.y = 0.03;
        changeColor(ItemId - NO_OF_FRAGRANCE, 0);
      }
      if (isModelLive === false) {
        loadNew(false, 0);
      } else if (zoomModel) {
        focusNew(zoomModel, focusItemPosition, 8);
      }
      onReady(true);
      isLoading = false;
    });
  };
  const loadNew = (isLoad, id) => {
    if (isLoad) {
      if (zoomModel === undefined) loadZoom(id);
    } else {
      if (zoomModel === undefined) return;
      if (zoomModel.ItemId >= NO_OF_FRAGRANCE + lipstick.length - 2) {
        transparentPlane.visible = false;
        backDiv.style.display = 'none';
      }
      transparentPlane.position.set(0, 1.5, -30);
      didSelectModel(undefined);
      zoomModel.traverse((object) => {
        if (!object.isMesh) return;
        scene.remove(object);
        object.geometry.dispose();
        if (object.material.isMaterial) {
          cleanMaterial(object.material);
        } else {
          for (const material of object.material) cleanMaterial(material);
        }
        object.geometry = undefined;
        object = undefined;
      });
      scene.remove(zoomModel);
      zoomModel = undefined;
    }
  };
  const changeColor = (color, id) => {
    if (textureLipstick[color] === undefined) return;
    if (textureLipstick[color][id] === undefined) return;
    if (textureLipstick[color][id].color === undefined) return;
    if (zoomModel.getObjectByName(matName[color]) === undefined) return;

    setMaterialMap(
      zoomModel.getObjectByName(matName[color]),
      textureLipstick[color][id].color,
      undefined
    );
    if (color > 5) {
      if (zoomModel.getObjectByName('Body') === undefined) return;
      setMaterialMap(
        zoomModel.getObjectByName('Body'),
        textureLipstick[color][id].color,
        undefined
      );
    }
  };

  const updateMaterialForMobile = (model, id) => {
    switch (id) {
      case 0:
        setMaterialOnBaseObject(
          model,
          blackMattePackMap,
          blackMattePackNormal,
          blackMattePackMetallic,
          blackMattePackMetallic,
          blackMattePackMetallic
        );
        return;
      case 1:
        setMaterialOnBaseObject(
          model,
          blackShinyPackMap,
          blackShinyPackNormal,
          blackShinyPackMetallic,
          blackShinyPackMetallic,
          blackShinyPackMetallic
        );
        return;
      case 2:
        setMaterialOnBaseObject(
          model,
          blackSnakeSkinPackMap,
          blackSnakeSkinPackNormal,
          blackSnakeSkinPackMetallic,
          blackSnakeSkinPackMetallic,
          blackSnakeSkinPackMetallic
        );
        return;
      case 3:
        setMaterialOnBaseObject(
          model,
          goldenMattePackMap,
          goldenMattePackNormal,
          goldenMattePackMetallic,
          goldenMattePackMetallic,
          goldenMattePackMetallic
        );
        return;
      case 4:
        setMaterialOnBaseObject(
          model,
          goldenSilkyPackMap,
          goldenSilkyPackNormal,
          goldenSilkyPackMetallic,
          goldenSilkyPackMetallic,
          goldenSilkyPackMetallic
        );
        return;
      case 5:
        setMaterialOnBaseObject(
          model,
          goldenSnakeSkinPackMap,
          goldenSnakeSkinPackNormal,
          goldenSnakeSkinPackMetallic,
          goldenSnakeSkinPackMetallic,
          goldenSnakeSkinPackMetallic
        );
        return;
      case 6:
        setMaterialOnBaseObject(
          model,
          matteFluidMap,
          matteFluidNormal,
          matteFluidMetallic,
          matteFluidMetallic,
          matteFluidMetallic
        );
        return;

      case 7:
        setMaterialOnBaseObject(
          model,
          metalMatteFluidMap,
          metalMatteFluidNormal,
          metalMatteFluidMetallic,
          metalMatteFluidMetallic,
          metalMatteFluidMetallic
        );
        return;
    }
  };

  const setMaterialOnBaseObject = (
    model,
    map,
    normal,
    metallic,
    roughnessMap?,
    aoMap?
  ) => {
    if (!model) return;
    model.traverse((object) => {
      if (!object['isMesh']) return;
      if (object['material'].isMaterial) {
        const met = object['material'];
        if (met.map && map) {
          met.map.image = map.image;
          met.map.needsUpdate = true;
        }
        if (met.normalMap && normal) {
          met.normalMap.image = normal.image;
          met.normalMap.needsUpdate = true;
        }
        if (met.metalnessMap && metallic) {
          met.metalnessMap.image = metallic.image;
          met.metalnessMap.needsUpdate = true;
        }
        if (met.aoMap && aoMap) {
          met.aoMap.image = aoMap.image;
          met.aoMap.needsUpdate = true;
        }
        if (met.roughnessMap && roughnessMap) {
          met.roughnessMap.image = roughnessMap.image;
          met.roughnessMap.needsUpdate = true;
        }
      }
    });
  };
  const setMaterialMap = (model, texture, material) => {
    if (!model) return;
    model.traverse((object) => {
      if (!object['isMesh']) return;
      if (object['material'].isMaterial) {
        if (material == undefined) {
          const met = object['material'];
          material = met.clone();
          material.map = met.map.clone();
          material.map.image = texture.image;
          material.map.needsUpdate = true;
        }
        object['material'] = material;
      }
    });
  };

  const cleanMaterial = (material) => {
    // dispose textures
    for (const key of Object.keys(material)) {
      const value = material[key];
      if (value && typeof value === 'object' && 'minFilter' in value) {
        value.dispose();
      }
    }
    material.dispose();
    material = undefined;
  };
  const updateObjectControlForModel = (model) => {
    if (
      model === undefined ||
      camera === undefined ||
      renderer === undefined ||
      viewOnly
    )
      return;
    if (!controls) {
      controls = new ObjectControls(camera, renderer.domElement, model);
    } else {
      controls.setObjectToMove(model);
    }
    controls.setDistance(2, 400); // set min - max distance for zoom
    controls.setZoomSpeed(0.5); // set zoom speed
    controls.enableVerticalRotation();
    controls.enableHorizontalRotation();
    // controls.enableZoom();
    controls.disableZoom();
    controls.setRotationSpeed(0.1);
  };

  const selectColorAnim = (model, colorModel, colorObj) => {
    if (!model) return;
    didSelectModel(undefined);
    didSelectModel(colorObj);
    model.visible = true;
    isTweening = true;
    new TWEEN.Tween(model.rotation)
      .to({ y: model.rotation.y + Math.PI * 2 }, 500)
      .easing(TWEEN.Easing.Exponential.InOut)
      .onComplete(() => {
        isTweening = false;
      })
      .start();
    new TWEEN.Tween(colorModel.scale)
      .to({ x: 0.91, y: 0.91, z: 0.91 }, 100)
      .easing(TWEEN.Easing.Exponential.InOut)
      .onComplete(() => {
        new TWEEN.Tween(colorModel.scale)
          .to({ x: 1, y: 1, z: 1 }, 100)
          .easing(TWEEN.Easing.Exponential.InOut)
          .start();
      })
      .start();
  };
  const focusNew = (model, pos, scale) => {
    if (!model) return;
    const object3d = model;
    object3d.visible = true;
    isTweening = true;
    didSelectModel(undefined);
    if (object3d?.ItemId < NO_OF_FRAGRANCE) {
      didSelectModel(fragranceProducts[object3d.ItemId]);
      setStrPerfume(perfumeTextArray[object3d.ItemId]);
      perfumeDiv.style.display = 'block';
    } else {
      didSelectModel(lipstickColors[object3d.ItemId - NO_OF_FRAGRANCE][0]);
    }
    new TWEEN.Tween(object3d.position)
      .to(
        {
          x: pos.x,
          y: pos.y,
          z: pos.z
        },
        1000
      )
      .easing(TWEEN.Easing.Exponential.InOut)
      .onComplete(() => {
        if (object3d && object3d?.ItemId >= NO_OF_FRAGRANCE && zoomModel) {
          updateObjectControlForModel(object3d);
          const diff = 0.2;
          const color = object3d?.ItemId - NO_OF_FRAGRANCE;
          const length = textureLipstick[color]?.length;
          for (
            let i = 0;
            i < colorLipstick.length && length > 1 && length != undefined;
            i++
          ) {
            colorLipstick[i].position.set(
              -diff * 0.5 * (length - 1) + i * diff,
              -0.8,
              16
            );
            colorLipstick[i].visible = i < length;
            if (i < length) {
              colorLipstick[i].traverse(function (child) {
                if (child.isMesh) {
                  child.material.map = textureLipstick[color][i].chooser;
                }
              });
            }
          }
        }
        isTweening = false;
      })
      .start();

    new TWEEN.Tween(object3d.scale)
      .to({ x: scale, y: scale, z: scale }, 1000)
      .easing(TWEEN.Easing.Exponential.InOut)
      .start();
  };
  const focus2DPerfume = (model, scale, pos) => {
    if (!model) return;
    model.visible = true;
    isTweening = true;
    new TWEEN.Tween(model.scale)
      .to(
        {
          x: scale,
          y: scale,
          z: scale
        },
        500
      )
      .easing(TWEEN.Easing.Exponential.InOut)
      .onComplete(() => {
        isTweening = false;
      })
      .start();

    new TWEEN.Tween(model.position)
      .to({ x: pos.x, y: pos.y, z: pos.z }, 500)
      .easing(TWEEN.Easing.Exponential.InOut)
      .start();
  };
  const focus2D = (model, scale) => {
    if (!model) return;
    model.visible = true;
    isTweening = true;
    new TWEEN.Tween(model.scale)
      .to(
        {
          x: scale,
          y: scale,
          z: scale
        },
        500
      )
      .easing(TWEEN.Easing.Exponential.InOut)
      .onComplete(() => {
        isTweening = false;
      })
      .start();
  };
  const close2D = (model, scale) => {
    if (!model) return;
    const obje = model;
    model.visible = true;
    isTweening = true;
    new TWEEN.Tween(model.scale)
      .to(
        {
          x: scale,
          y: scale,
          z: scale
        },
        500
      )
      .easing(TWEEN.Easing.Exponential.InOut)
      .onComplete(() => {
        obje.visible = false;
        isTweening = false;
      })
      .start();
    new TWEEN.Tween(model.position)
      .to(
        {
          x: 0,
          y: 0,
          z: -28
        },
        500
      )
      .easing(TWEEN.Easing.Exponential.InOut)
      .onComplete(() => {
        obje.visible = false;
        isTweening = false;
      })
      .start();
  };

  const updateRemoteObjectControlForModel = () => {
    if (zoomModel) {
      updateRemoteState({
        flag: REMOTE_ACTION.MOUSE_CONTROL,
        focusItem: zoomModel.id,
        position: [
          zoomModel.position.x,
          zoomModel.position.y,
          zoomModel.position.z
        ],
        scale: [zoomModel.scale.x, zoomModel.scale.y, zoomModel.scale.z],
        rotation: [
          zoomModel.rotation.x,
          zoomModel.rotation.y,
          zoomModel.rotation.z
        ]
      });
    }
  };
  const detectFirstTouchedObject = (e, type, sys, objects) => {
    const CANVAS_HEIGHT = window.innerHeight;
    const CANVAS_WIDTH = window.innerWidth;
    if (e.touches != null) {
      if (e.touches.length > 0) {
        mouse.x = (e.touches[0].pageX / window.innerWidth) * 2 - 1;
        mouse.y = -(e.touches[0].pageY / window.innerHeight) * 2 + 1;
      }
    } else {
      const elem = renderer.domElement,
        boundingRect = elem.getBoundingClientRect();
      const x =
        (e.clientX - boundingRect.left) * (elem.width / boundingRect.width);
      const y =
        (e.clientY - boundingRect.top) * (elem.height / boundingRect.height);
      mouse.x = (x / CANVAS_WIDTH) * 2 - 1;
      mouse.y = -(y / CANVAS_HEIGHT) * 2 + 1;
    }
    raycaster.setFromCamera(mouse, camera);

    const intersects = raycaster.intersectObjects(objects);
    if (intersects.length) {
      return intersects[0];
    }
  };

  const onPinch = (ev) => {
    if (remoteState != undefined || isTweening == true) return;
    if (zoomModel === undefined && ev.type == 'doubletap') {
      const perfumesVisible = imgPerfumes.find((p) => p.visible === true);
      if (perfumesVisible) {
        const perfumesPos = imgPerfumes.find((p) => p.position.z > 1);
        if (perfumesPos) {
          selectedPerfumBack(perfumesPos.ItemId);
          updateRemoteState({
            flag: REMOTE_ACTION.UN_FOCUS,
            focusItem: perfumesPos.ItemId
          });
        } else {
          closeGate();
          updateRemoteState({ flag: REMOTE_ACTION.CLOSE_GATE_1, focusItem: 1 });
        }
      }
      const lipsticVisible = imgLipstick.find((p) => p.visible === true);
      if (lipsticVisible) {
        closeGate();
        updateRemoteState({ flag: REMOTE_ACTION.CLOSE_GATE_1, focusItem: 1 });
      }
    }
    if (
      zoomModel !== undefined &&
      (ev.type == 'pinchstart' || ev.type == 'pinchmove')
    ) {
      if (ev.type === 'pinchstart') {
        initScale = zoomModel.scale.x || 1;
      }
      zoomModel?.scale.set(
        initScale * ev.scale,
        initScale * ev.scale,
        initScale * ev.scale
      );
      if (zoomModel?.scale.x > maxScale)
        zoomModel?.scale.set(maxScale, maxScale, maxScale);
      if (zoomModel?.scale.x < minScale)
        zoomModel?.scale.set(minScale, minScale, minScale);
      updateRemoteObjectControlForModel();
    }
  };
  const setTouchValues = (e, type, sys) => {
    const CANVAS_HEIGHT = window.innerHeight;
    const CANVAS_WIDTH = window.innerWidth;
    if (e.touches !== null && e.touches !== undefined) {
      if (e.touches.length > 0) {
        mouse.x = (e.touches[0].pageX / window.innerWidth) * 2 - 1;
        mouse.y = -(e.touches[0].pageY / window.innerHeight) * 2 + 1;
        if (e.touches.length === 2) {
          mouse_2.x = (e.touches[1].pageX / window.innerWidth) * 2 - 1;
          mouse_2.y = -(e.touches[1].pageY / window.innerHeight) * 2 + 1;
          if (type === touchType.touchDown) {
            initDistance = mouse_2.distanceTo(mouse);
            onPinch({ type: 'pinchstart', scale: 1 });
          } else {
            const currentDistance = mouse_2.distanceTo(mouse);
            onPinch({
              type: 'pinchmove',
              scale: currentDistance / initDistance
            });
          }
        }
      }
    } else {
      const elem = renderer.domElement,
        boundingRect = elem.getBoundingClientRect();
      const x =
        (e.clientX - boundingRect.left) * (elem.width / boundingRect.width);
      const y =
        (e.clientY - boundingRect.top) * (elem.height / boundingRect.height);
      mouse.x = (x / CANVAS_WIDTH) * 2 - 1;
      mouse.y = -(y / CANVAS_HEIGHT) * 2 + 1;
    }
    if (type === touchType.touchDown) {
      mouseDownPos.x = mouse.x;
      mouseDownPos.y = mouse.y;
      const diff = new Date().getTime() - doubleTapClick;
      if (diff < 300) {
        if (e.touches !== null && e.touches !== undefined) {
          if (e.touches.length === 1) {
            onPinch({ type: 'doubletap', scale: 1 });
          }
        } else {
          onPinch({ type: 'doubletap', scale: 1 });
        }
      }
      doubleTapClick = new Date().getTime();
    }
    raycaster.setFromCamera(mouse, camera);
  };
  const onwheelEvent = (event) => {
    if (remoteState != undefined || zoomModel == undefined) return;

    let modelScale = zoomModel.scale.x;
    if (event.deltaY > 0) {
      if (modelScale < maxScale) modelScale += incScale;
    } else {
      if (modelScale > minScale) modelScale -= incScale;
    }
    zoomModel.scale.set(modelScale, modelScale, modelScale);
    updateRemoteObjectControlForModel();
  };
  const createRayCasterCatcher = (scl) =>
    new THREE.Mesh(
      new THREE.BoxBufferGeometry(scl.x, scl.y, scl.z),
      new THREE.MeshBasicMaterial({})
    );
  const callback = () => {
    loadingCount++;
    onReady(true);
    textureCart.generateMipmaps = false;
    textureCart.minFilter = THREE.LinearFilter;
    textureCart.needsUpdate = true;
  };

  const load2D = () => {
    const textureLoader = new THREE.TextureLoader(loadingManager);

    textureCart = textureLoader.load(beautyCartImg);
    textureCart.encoding = THREE.sRGBEncoding;

    let scl = 0.049;
    const trolleyPlan = new THREE.Mesh(
      new THREE.PlaneGeometry(2048 * scl, 1024 * scl),
      new THREE.MeshBasicMaterial({ map: textureCart })
    );
    trolleyPlan.position.set(0, 3, -30);
    scene.add(trolleyPlan);
    scl = 0.012;

    for (let i = 0; i < fragranceProducts.length; i++) {
      const texturePerfumes = textureLoader.load(
        fragranceProducts[i].lowPolyModelUrl
      );
      texturePerfumes.encoding = THREE.sRGBEncoding;
      imgPerfumes.push(
        new THREE.Mesh(
          new THREE.PlaneGeometry(1024 * scl, 2048 * scl),
          new THREE.MeshBasicMaterial({
            map: texturePerfumes,
            transparent: true
          })
        )
      );
      imgPerfumes[i].position.set(0, 3, -28);
      scene.add(imgPerfumes[i]);
      imgPerfumes[i].visible = false;
      imgPerfumes[i].ItemId = i;
    }

    for (let i = 0; i < 3; i++) {
      scl = 4;
      const texture_Lipstick = textureLoader.load(lipstick2D[i]);
      texture_Lipstick.encoding = THREE.sRGBEncoding;
      imgLipstick.push(
        new THREE.Mesh(
          new THREE.PlaneGeometry((i == 2 ? 1 : 4) * scl, 4 * scl),
          new THREE.MeshBasicMaterial({
            map: texture_Lipstick,
            transparent: true
          })
        )
      );
      imgLipstick[i].position.set(0, 3, -28);
      imgLipstick[i]['ItemId'] = i;
      scene.add(imgLipstick[i]);
      imgLipstick[i].visible = false;
    }
    imgLipstick.push(imgLipstick[2].clone());
    imgLipstick[3].visible = false;
    imgLipstick[3]['ItemId'] = 3;
    scene.add(imgLipstick[3]);
    for (let i = 0; i < 5; i++) {
      const ItemID = i;
      itemsChildForCatchingCartClick[i] =
        createRayCasterCatcher(rayCasterScale);
      itemsChildForCatchingCartClick[i].visible = false;
      itemsChildForCatchingCartClick[i].itemId = ItemID;
      scene.add(itemsChildForCatchingCartClick[i]);
    }
    itemsChildForCatchingCartClick[0].position.set(-2.3, 11.5, -30);
    itemsChildForCatchingCartClick[1].position.set(-2.3, 6.3, -30);
    itemsChildForCatchingCartClick[2].position.set(-2.3, 1.5, -30);
    itemsChildForCatchingCartClick[3].position.set(-2.3, -3.2, -30);
    itemsChildForCatchingCartClick[4].position.set(-2.3, -9.4, -30);

    for (let i = 0; i < 7; i++) {
      const ItemID = i;
      itemsChildForCatchingModelClick[i] =
        createRayCasterCatcher(rayCasterScale);
      itemsChildForCatchingModelClick[i].position.set(
        -ImgDiffX * 3 + i * ImgDiffX,
        2.8,
        -29
      );
      itemsChildForCatchingModelClick[i].scale.set(1, 2.6, 1);
      itemsChildForCatchingModelClick[i].visible = false;
      itemsChildForCatchingModelClick[i].itemId = ItemID;
      scene.add(itemsChildForCatchingModelClick[i]);
    }

    const diff = 0.2;

    lipstickColors.forEach((element) => {
      const textures = [];
      element.forEach((url) => {
        const textureChooser = textureLoader.load(url.highPolyModelUrl);
        textureChooser.encoding = THREE.sRGBEncoding;
        textures.push({
          color: textureLoader.load(url.lowPolyModelUrl),
          chooser: textureChooser
        });
      });
      textureLipstick.push(textures);
    });

    for (let i = 0; i < textureLipstick[6].length; i++) {
      colorLipstick.push(
        new THREE.Mesh(
          new THREE.PlaneGeometry(0.12, 0.24),
          new THREE.MeshBasicMaterial({
            map: textureLipstick[6][i].chooser,
            transparent: true
          })
        )
      );
      scene.add(colorLipstick[i]);
      colorLipstick[i].position.set(-diff * 3 + i * diff, -1.2, 16);
      colorLipstick[i].visible = false;
    }
  };
  const loadEnvironment = () => {
    if (!scene || !renderer) return;
    transparentPlane = new THREE.Mesh(
      new THREE.PlaneGeometry(100, 50),
      transparentMat
    );
    transparentPlane.position.set(0, 1.5, 1); //rotation.x=-Math.PI * 0.1346
    transparentPlane.position.set(0, 1.5, -30);
    scene?.add(transparentPlane);
    transparentPlane.visible = false;
    if (isMobileOrTab) {
      const textureLoader = new THREE.TextureLoader(loadingManager);
      blackMattePackMap = textureLoader.load(
        lipstickTexturesUrl.blackMattePackMap
      );
      blackMattePackNormal = textureLoader.load(
        lipstickTexturesUrl.blackMattePackNormal
      );
      blackMattePackMetallic = textureLoader.load(
        lipstickTexturesUrl.blackMattePackMetallic
      );
      blackShinyPackMap = textureLoader.load(
        lipstickTexturesUrl.blackShinyPackMap
      );
      blackShinyPackNormal = textureLoader.load(
        lipstickTexturesUrl.blackShinyPackNormal
      );
      blackShinyPackMetallic = textureLoader.load(
        lipstickTexturesUrl.blackShinyPackMetallic
      );
      blackSnakeSkinPackMap = textureLoader.load(
        lipstickTexturesUrl.blackSnakeSkinPackMap
      );
      blackSnakeSkinPackNormal = textureLoader.load(
        lipstickTexturesUrl.blackSnakeSkinPackNormal
      );
      blackSnakeSkinPackMetallic = textureLoader.load(
        lipstickTexturesUrl.blackSnakeSkinPackMetallic
      );
      goldenMattePackMap = textureLoader.load(
        lipstickTexturesUrl.goldenMattePackMap
      );
      goldenMattePackNormal = textureLoader.load(
        lipstickTexturesUrl.goldenMattePackNormal
      );
      goldenMattePackMetallic = textureLoader.load(
        lipstickTexturesUrl.goldenMattePackMetallic
      );
      goldenSilkyPackMap = textureLoader.load(
        lipstickTexturesUrl.goldenSilkyPackMap
      );
      goldenSilkyPackNormal = textureLoader.load(
        lipstickTexturesUrl.goldenSilkyPackNormal
      );
      goldenSilkyPackMetallic = textureLoader.load(
        lipstickTexturesUrl.goldenSilkyPackMetallic
      );
      goldenSnakeSkinPackMap = textureLoader.load(
        lipstickTexturesUrl.goldenSnakeSkinPackMap
      );
      goldenSnakeSkinPackNormal = textureLoader.load(
        lipstickTexturesUrl.goldenSnakeSkinPackNormal
      );
      goldenSnakeSkinPackMetallic = textureLoader.load(
        lipstickTexturesUrl.goldenSnakeSkinPackMetallic
      );
      matteFluidMap = textureLoader.load(lipstickTexturesUrl.matteFluidMap);
      matteFluidNormal = textureLoader.load(
        lipstickTexturesUrl.matteFluidNormal
      );
      matteFluidMetallic = textureLoader.load(
        lipstickTexturesUrl.matteFluidMetallic
      );
      metalMatteFluidMap = textureLoader.load(
        lipstickTexturesUrl.metalMatteFluidMap
      );
      metalMatteFluidNormal = textureLoader.load(
        lipstickTexturesUrl.metalMatteFluidNormal
      );
      metalMatteFluidMetallic = textureLoader.load(
        lipstickTexturesUrl.metalMatteFluidMetallic
      );
    }

    const loader = new RGBELoader();
    loader.setDataType(THREE.UnsignedByteType);
    const modelBackgroundPath = modelWorkshopEnvironmentImagePath;
    modelBackgroundPath &&
      loader.load(
        modelBackgroundPath,
        (texture) => {
          if (renderer && scene) {
            let pmremGenerator = new THREE.PMREMGenerator(renderer);
            pmremGenerator.compileEquirectangularShader();
            const envMap = pmremGenerator.fromEquirectangular(texture).texture;
            pmremGenerator = undefined;
            scene.environment = envMap;
          }
        },
        undefined,
        undefined
      );
  };
  const eventDown = (e) => {
    touchEvent(e, touchType.touchDown, 0);
  };
  const eventMove = (e) => {
    touchEvent(e, touchType.touchMove, 0);
  };
  const eventUp = (e) => {
    touchEvent(e, touchType.touchUp, 0);
  };
  const zoomDisable = (e) => {
    e.preventDefault();
    document.body.style['zoom'] = '1';
  };
  const addEventListeners = () => {
    document.addEventListener('mousedown', eventDown);
    document.addEventListener('touchstart', eventDown);

    document.addEventListener('mousemove', eventMove);
    document.addEventListener('touchmove', eventMove);

    document.addEventListener('mouseup', eventUp);
    document.addEventListener('touchend', eventUp);

    document.addEventListener('wheel', onwheelEvent);
    window.addEventListener('resize', onWindowResize, false);
  };
  const closeGate = () => {
    backDiv.style.display = 'none';
    perfumeDiv.style.display = 'none';
    didSelectModel(undefined);
    imgPerfumes
      .filter((model) => model)
      .forEach((model) => {
        if (model.visible) close2D(model, 0.01);
      });
    imgLipstick
      .filter((model) => model)
      .forEach((model) => {
        if (model.visible) close2D(model, 0.01);
      });
    isModelLive = false;
    loadNew(false, 0);
    transparentPlane.visible = false;
  };

  const selectedPerfumBack = (no) => {
    backDiv.style.display = 'block';
    perfumeDiv.style.display = 'none';
    didSelectModel(undefined);
    imgPerfumes
      .filter((model) => model)
      .forEach((model) => {
        model.visible = true;
      });
    imgLipstick
      .filter((model) => model)
      .forEach((model) => {
        model.visible = false;
      });
    isModelLive = false;
    loadNew(false, 0);
    transparentPlane.visible = true;
    transparentPlane.position.set(0, 1.5, -30);
    focus2DPerfume(imgPerfumes[no], 0.51, getPerfumePosition(no));
  };
  const getPerfumePosition = (i) => {
    if (isMobileOrTab) {
      if (i < 4) return { x: -ImgDiffX * 1.5 + i * ImgDiffX, y: 5, z: -28 };
      else return { x: -ImgDiffX + (i - 4) * ImgDiffX, y: -5, z: -28 };
    } else {
      return { x: -ImgDiffX * 3 + i * ImgDiffX, y: 4, z: -28 };
    }
  };
  const updateFocus2D = (no) => {
    backDiv.style.display = 'block';
    imgPerfumes
      .filter((model) => model)
      .forEach((model) => {
        model.visible = false;
      });
    imgLipstick
      .filter((model) => model)
      .forEach((model) => {
        model.visible = false;
      });
    isModelLive = false;
    loadNew(false, 0);
    transparentPlane.visible = true;
    transparentPlane.position.set(0, 1.5, -30);
    switch (no) {
      case 0:
        for (let i = 0; i < imgPerfumes.length; i++) {
          imgPerfumes[i].scale.set(0.01, 0.01, 0.01);
          focus2DPerfume(imgPerfumes[i], 0.51, getPerfumePosition(i));
        }
        break;
      case 1:
      case 2:
      case 3:
      case 4:
        imgLipstick[no - 1].scale.set(0.01, 0.01, 0.01);
        focus2D(imgLipstick[no - 1], 1);
        break;
    }
  };
  const openObject = (no) => {
    backDiv.style.display = 'block';
    transparentPlane.position.set(0, 1.5, -25);
    imgPerfumes
      .filter((model) => model)
      .forEach((model) => {
        model.visible = false;
      });
    imgLipstick
      .filter((model) => model)
      .forEach((model) => {
        model.visible = false;
      });
    if (no < NO_OF_FRAGRANCE) {
      imgPerfumes
        .filter((model) => model)
        .forEach((model) => {
          model.visible = true;
        });
      focusNew(imgPerfumes[no], focusPerfumePosition, 0.31);
      transparentPlane.position.set(0, 1.5, -30);
      new TWEEN.Tween(transparentPlane.position)
        .to({ z: -27 }, 500)
        .easing(TWEEN.Easing.Exponential.In)
        .start();
    } else if (no < NO_OF_FRAGRANCE + 3) {
      imgLipstick[0].visible = true;
    } else if (no < NO_OF_FRAGRANCE + 6) {
      imgLipstick[1].visible = true;
    }

    if (no >= NO_OF_FRAGRANCE) {
      loadNew(false, 0);
      isModelLive = true;
      loadNew(true, no);
    }
  };
  const onClickColor = (lipID, color) => {
    if (lipID === undefined || color === undefined) return;

    if (
      colorLipstick[color] === undefined ||
      lipstickColors[lipID] === undefined
    )
      return;
    if (lipstickColors[lipID][color] === undefined) return;
    changeColor(lipID, color);
    selectColorAnim(
      zoomModel,
      colorLipstick[color],
      lipstickColors[lipID][color]
    );
  };
  const touchEvent = (e, type, sys) => {
    if (
      remoteState != undefined ||
      isLoading ||
      loadingCount < 10 ||
      isTweening
    )
      return;
    setTouchValues(e, type, sys);
    if (type === touchType.touchUp && !zoomModel) {
      const lipsticVisible = imgLipstick.find((p) => p.visible === true);
      const PerfumesVisible = imgPerfumes.find((p) => p.visible === true);
      if (!PerfumesVisible && !lipsticVisible) {
        const clickedFocus = detectFirstTouchedObject(
          e,
          type,
          sys,
          itemsChildForCatchingCartClick
        );
        for (
          let i = 0;
          i < itemsChildForCatchingCartClick.length && clickedFocus;
          i++
        ) {
          if (itemsChildForCatchingCartClick[i] == clickedFocus.object) {
            if (i > 2) {
              const itemId = NO_OF_FRAGRANCE + 3 + i;
              if (itemId > 0) {
                openObject(itemId);
                updateRemoteState({
                  flag: REMOTE_ACTION.FOCUS,
                  focusItem: itemId
                });
              }
            } else {
              updateFocus2D(i);
              updateRemoteState({
                flag: REMOTE_ACTION.CLICK_GATE_1,
                focusItem: i
              });
            }
            return;
          }
        }
      } else if (PerfumesVisible) {
        const clickedFocus = detectFirstTouchedObject(
          e,
          type,
          sys,
          imgPerfumes
        );
        for (let i = 0; i < imgPerfumes.length && clickedFocus; i++) {
          const perfumesPos = imgPerfumes.find((p) => p.position.z > 1);
          if (perfumesPos) {
            if (mouse.y < 0.56) {
              selectedPerfumBack(perfumesPos.ItemId);
              updateRemoteState({ flag: REMOTE_ACTION.UN_FOCUS, focusItem: i });
            }
            return;
          } else if (imgPerfumes[i] == clickedFocus.object) {
            openObject(i);
            updateRemoteState({ flag: REMOTE_ACTION.FOCUS, focusItem: i });
            return;
          }
        }
      } else if (lipsticVisible?.ItemId < 5) {
        let clickedFocus = detectFirstTouchedObject(e, type, sys, [
          itemsChildForCatchingModelClick[3]
        ]);
        if (lipsticVisible?.ItemId < 2)
          clickedFocus = detectFirstTouchedObject(e, type, sys, [
            itemsChildForCatchingModelClick[2],
            itemsChildForCatchingModelClick[3],
            itemsChildForCatchingModelClick[4]
          ]);
        for (
          let i = 0;
          i < itemsChildForCatchingModelClick.length && clickedFocus;
          i++
        ) {
          if (itemsChildForCatchingModelClick[i] == clickedFocus.object) {
            let itemId = -1;
            switch (i) {
              case 2:
                itemId = lipsticVisible?.ItemId * 3 + NO_OF_FRAGRANCE;
                break;
              case 3:
                if (lipsticVisible?.ItemId < 2)
                  itemId = lipsticVisible?.ItemId * 3 + 1 + NO_OF_FRAGRANCE;
                else itemId = 4 + lipsticVisible?.ItemId + NO_OF_FRAGRANCE;
                break;
              case 4:
                itemId = lipsticVisible?.ItemId * 3 + 2 + NO_OF_FRAGRANCE;
                break;
            }
            if (itemId > 0) {
              openObject(itemId);
              updateRemoteState({
                flag: REMOTE_ACTION.FOCUS,
                focusItem: itemId
              });
            }
            return;
          }
        }
      }
    }
    if (type === touchType.touchUp && zoomModel) {
      if (zoomModel.getObjectByName(STRZOOMRAYCASTER)) {
        const clickedZoom = detectFirstTouchedObject(e, type, sys, [
          zoomModel.getObjectByName(STRZOOMRAYCASTER)
        ]);
        if (
          clickedZoom &&
          mouseDownPos.x === mouse.x &&
          mouseDownPos.y == mouse.y
        ) {
          doubleTapClick = 0;
          isModelLive = false;
          loadNew(false, 0);
          updateRemoteState({ flag: REMOTE_ACTION.UN_FOCUS });
          colorLipstick.forEach((element) => {
            element.visible = false;
          });
        }
        if (zoomModel?.ItemId && zoomModel?.ItemId >= NO_OF_FRAGRANCE) {
          const clickedChooser = detectFirstTouchedObject(
            e,
            type,
            sys,
            colorLipstick
          );
          const lipID = zoomModel.ItemId - NO_OF_FRAGRANCE;
          if (
            clickedChooser &&
            mouseDownPos.x === mouse.x &&
            mouseDownPos.y == mouse.y
          ) {
            for (let i = 0; i < colorLipstick.length && clickedChooser; i++) {
              if (
                colorLipstick[i] == clickedChooser.object &&
                colorLipstick[i].visible
              ) {
                onClickColor(lipID, i);
                updateRemoteState({
                  flag: REMOTE_ACTION.SEL_COLOR,
                  focuscolor: i,
                  focusItem: zoomModel.ItemId
                });
              }
            }
          }
        }
      }
    }
    if (touchType.touchDown === type) {
      isDown = true;
    }
    if (touchType.touchUp === type) {
      isDown = false;
    }
    if (zoomModel != undefined && isDown == true) {
      updateRemoteObjectControlForModel();
    }
  };
  const animate = () => {
    if (renderer == undefined || scene == undefined) {
      return;
    }
    if (clickBack && !viewOnly) {
      clickBack = false;
      if (zoomModel) {
        const itemid = zoomModel.ItemId;
        doubleTapClick = 0;
        isModelLive = false;
        loadNew(false, 0);
        updateRemoteState({ flag: REMOTE_ACTION.UN_FOCUS, focusItem: itemid });
        colorLipstick.forEach((element) => {
          element.visible = false;
        });
      } else {
        const perfumesPos = imgPerfumes.find((p) => p.position.z > 1);
        if (perfumesPos) {
          selectedPerfumBack(perfumesPos.ItemId);
          updateRemoteState({
            flag: REMOTE_ACTION.UN_FOCUS,
            focusItem: perfumesPos.ItemId
          });
        } else {
          closeGate();
          updateRemoteState({ flag: REMOTE_ACTION.CLOSE_GATE_1, focusItem: 1 });
        }
      }
    }
    if (loadingCount > 0) {
      loadingCount++;
    }
    if (beautyCartRemoteState?.flag && loadingCount > 10) {
      if (beautyCartRemoteState.flag == REMOTE_ACTION.CLICK_GATE_1) {
        updateFocus2D(beautyCartRemoteState?.focusItem);
      }
      if (beautyCartRemoteState.flag == REMOTE_ACTION.CLOSE_GATE_1) {
        closeGate();
      }
      if (beautyCartRemoteState.flag == REMOTE_ACTION.FOCUS) {
        openObject(beautyCartRemoteState.focusItem);
      }
      if (beautyCartRemoteState.flag == REMOTE_ACTION.UN_FOCUS) {
        isModelLive = false;
        colorLipstick?.forEach((element) => {
          element.visible = false;
        });
        loadNew(false, 0);
        const perfumesPos = imgPerfumes.find((p) => p.position.z > 1);
        if (perfumesPos) {
          selectedPerfumBack(perfumesPos.ItemId);
        }
      }
      if (beautyCartRemoteState.flag == REMOTE_ACTION.SEL_COLOR) {
        if (zoomModel) {
          const lipID = zoomModel?.ItemId - NO_OF_FRAGRANCE;
          if (zoomModel.ItemId === beautyCartRemoteState?.focusItem) {
            onClickColor(lipID, beautyCartRemoteState?.focuscolor);
          }
        }
      }
      if (beautyCartRemoteState.flag == REMOTE_ACTION.MOUSE_CONTROL) {
        if (zoomModel) {
          zoomModel.position.set(
            beautyCartRemoteState.position[0],
            beautyCartRemoteState.position[1],
            beautyCartRemoteState.position[2]
          );
          zoomModel.scale.set(
            beautyCartRemoteState.scale[0],
            beautyCartRemoteState.scale[1],
            beautyCartRemoteState.scale[2]
          );
          zoomModel.rotation.set(
            beautyCartRemoteState.rotation[0],
            beautyCartRemoteState.rotation[1],
            beautyCartRemoteState.rotation[2]
          );
        }
      }
      beautyCartRemoteState.flag = undefined;
      beautyCartRemoteState = undefined;
    }
    TWEEN.update();
    renderer.render(scene, camera);
    requestAnimationFrame(animate);
  };
  const onWindowResize = () => {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(window.innerWidth, window.innerHeight);
  };
  const cleanTexture = (value) => {
    if (value && typeof value === 'object' && 'minFilter' in value) {
      value.dispose();
    }
    value = undefined;
    return undefined;
  };
  const loadThreeJsModulesAsync = async () => {
    GLTFLoader = (await import('three/examples/jsm/loaders/GLTFLoader'))
      .GLTFLoader;
    RGBELoader = (await import('three/examples/jsm/loaders/RGBELoader'))
      .RGBELoader;
    TWEEN = (await import('three/examples/jsm/libs/tween.module.min.js')).TWEEN;
    ObjectControls = (await import('../common/ObjectControls')).ObjectControls;
  };
  const cleanUp = () => {
    if (scene != undefined) {
      scene.traverse((object) => {
        if (!object['isMesh']) return;
        object['geometry'].dispose();
        if (object['material'].isMaterial) {
          cleanMaterial(object['material']);
        } else {
          // an array of materials
          for (const material of object['material']) cleanMaterial(material);
        }
        object['geometry'] = undefined;
        object = undefined;
      });
      scene.children.forEach((model) => {
        scene.remove(model);
      });
    }
    textureLipstick = [];
    imgLipstick = [];
    colorLipstick = [];
    itemsChildForCatchingCartClick = [];
    itemsChildForCatchingModelClick = [];

    blackMattePackMap = cleanTexture(blackMattePackMap);
    blackMattePackNormal = cleanTexture(blackMattePackNormal);
    blackMattePackMetallic = cleanTexture(blackMattePackMetallic);
    blackShinyPackMap = cleanTexture(blackShinyPackMap);
    blackShinyPackNormal = cleanTexture(blackShinyPackNormal);
    blackShinyPackMetallic = cleanTexture(blackShinyPackMetallic);
    blackSnakeSkinPackMap = cleanTexture(blackSnakeSkinPackMap);
    blackSnakeSkinPackNormal = cleanTexture(blackSnakeSkinPackNormal);
    blackSnakeSkinPackMetallic = cleanTexture(blackSnakeSkinPackMetallic);
    goldenMattePackMap = cleanTexture(goldenMattePackMap);
    goldenMattePackNormal = cleanTexture(goldenMattePackNormal);
    goldenMattePackMetallic = cleanTexture(goldenMattePackMetallic);
    goldenSilkyPackMap = cleanTexture(goldenSilkyPackMap);
    goldenSilkyPackNormal = cleanTexture(goldenSilkyPackNormal);
    goldenSilkyPackMetallic = cleanTexture(goldenSilkyPackMetallic);
    goldenSnakeSkinPackMap = cleanTexture(goldenSnakeSkinPackMap);
    goldenSnakeSkinPackNormal = cleanTexture(goldenSnakeSkinPackNormal);
    goldenSnakeSkinPackMetallic = cleanTexture(goldenSnakeSkinPackMetallic);
    matteFluidMap = cleanTexture(matteFluidMap);
    matteFluidNormal = cleanTexture(matteFluidNormal);
    matteFluidMetallic = cleanTexture(matteFluidMetallic);
    metalMatteFluidMap = cleanTexture(metalMatteFluidMap);
    metalMatteFluidNormal = cleanTexture(metalMatteFluidNormal);
    metalMatteFluidMetallic = cleanTexture(metalMatteFluidMetallic);

    if (renderer != undefined) {
      renderer.dispose();
      renderer && renderer.renderLists.dispose();
    }
    if (controls) {
      controls.disableVerticalRotation();
      controls.disableHorizontalRotation();
      controls.disableZoom();
    }
    document.removeEventListener('touchstart', eventDown);
    document.removeEventListener('touchmove', eventMove);
    document.removeEventListener('touchend', eventUp);
    document.removeEventListener('mousedown', eventDown);
    document.removeEventListener('mousemove', eventMove);
    document.removeEventListener('mouseup', eventUp);
    document.removeEventListener('wheel', onwheelEvent);
    window.removeEventListener('resize', onWindowResize);
    document.removeEventListener('gesturestart', zoomDisable);
    document.removeEventListener('gesturechange', zoomDisable);
    document.removeEventListener('gestureend', zoomDisable);
    textureCart = undefined;
    controls = undefined;
    scene = undefined;
    renderer = undefined;
    camera = undefined;
  };
  React.useEffect(() => {
    if (remoteState) {
      beautyCartRemoteState = remoteState;
    }
  }, [remoteState]);
  React.useEffect(() => {
    const canvas = document.querySelector('#c') as HTMLCanvasElement;
    backDiv = document.getElementById('topright');
    backDiv.style.display = 'none';
    perfumeDiv = document.getElementById('centertxt');
    perfumeDiv.style.display = 'none';
    camera = new THREE.PerspectiveCamera(
      45,
      window.innerWidth / window.innerHeight,
      0.1,
      100
    );
    renderer = new THREE.WebGLRenderer({
      canvas,
      antialias: true,
      alpha: true
    });

    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setClearColor(0x000000, 0);
    renderer.outputEncoding = THREE.sRGBEncoding;

    camera.position.set(0, 0, 20);
    // camera.lookAt(new THREE.Vector3(0, 2, 0));
    if (isMobileOrTab) {
      document.addEventListener('gesturestart', zoomDisable);
      document.addEventListener('gesturechange', zoomDisable);
      document.addEventListener('gestureend', zoomDisable);
    }
    loadingCount = 0;
    loadThreeJsModulesAsync().then(() => {
      loadEnvironment();
      load2D();
      if (!viewOnly) {
        addEventListeners();
      }
      animate();
    });
    setStrPerfume(perfumeTextArray[0]);
    return () => {
      cleanUp();
    };
  }, []);

  return (
    <div>
      <canvas id="c" ref={canvasRef}></canvas>
      <p id="topright">
        <BackToAnimationButton
          onClick={onClickBack}
          position="top-second-right"
        />
      </p>
      <span id="centertxt">
        <h1>
          {strPerfume?.split('\n').map((item, i) => (
            <p key={i}>{item}</p>
          ))}
        </h1>
      </span>
      <style jsx>
        {`
          div {
            background: url(${`${assetBaseUrl}/loubiairways/luggage-animation/beautycart/background/v2/beauty_cart_background.png`})
              no-repeat;
            background-position: center center;
            background-size: cover;
            background-attachment: fixed;
          }
          #c,
          div {
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
          }
          a {
            position: fixed;
            top: 2vh;
            left: 2vh;
            font-size: 0.81em;
            text-align: left;
          }

          #centertxt {
            position: fixed;
            left: 50%;
            bottom: 100px;
            transform: translateX(-50%);
            margin: 0 auto;
          }
          #centertxt h1 {
            font-family: 'AntiqueOlive-Bold', Arial;
            color: white;
            font-size: 1em;
            text-align: center;
          }
          #centertxt h1 p {
            margin-bottom: 10px;
            font-size: 14px;
          }
          #centertxt h1 p:first-child {
            font-style: italic;
            font-size: 21px;
          }

          @media (max-width: 768px) {
            #centertxt {
              bottom: 70px;
            }
          }
        `}
      </style>
    </div>
  );
};

export default BeautyCart;
