import React from 'react';
import { logEvent } from '../../../../../analytics';
import { assetBaseUrl } from '../../../../../config';
import { ILoubiAirways2dAnimationState } from '../../../../../interfaces/loubiairways';
import { DID_FLIP_MAGAZINE } from '../../../../../utils/constants';
import { getViewportSize } from '../../../../../utils/window';
import { MDLandscapeNormalSpec } from '../../../../Meeting/MeetingLayout/MDLandscapeNormal';
import { selectEl } from '../animationUtils';
import MagazineFlip from './MagazineFlip';

enum Remote {
  FLIPPING,
  CLOSING,
  OPENING
}

interface Props {
  speed?: number;
  cover: string;
  pages: React.ReactNode[];
  onClose?: () => void;
  delay: number;
  background: string;
  portraitBackground: string;
  remoteState: ILoubiAirways2dAnimationState;
  updateRemoteState: (state: ILoubiAirways2dAnimationState) => void;
  close: boolean;
  defaultPaperHeight?: number;
  paperSizeRatio?: number;
  overlay?: string;
  className?: string;
}

export interface MagazinePageDimension {
  width: number;
  height: number;
  maxWidth: number;
  maxHeight: number;
}
const Magazine = ({
  close,
  speed = 1500,
  cover,
  pages,
  onClose,
  delay,
  background,
  portraitBackground,
  remoteState,
  updateRemoteState,
  defaultPaperHeight = 952,
  paperSizeRatio = 761 / 952,
  overlay,
  className = ''
}: Props) => {
  const activePageRemote = remoteState?.activePage;
  const [showMag, setShowMag] = React.useState(false);
  const [activePage, setActivePage] = React.useState(0);
  const [
    pageDimension,
    setPageDimension
  ] = React.useState<MagazinePageDimension>({
    width: 761,
    height: 952,
    maxWidth: 761,
    maxHeight: 952
  });
  const magNav = React.useRef<HTMLImageElement>();

  const priorActivePage = activePageRemote ?? activePage;
  const closeDelay = priorActivePage === 0 ? 0 : speed;

  const remoteControlMagazine = (remote: Remote, activePage?: number) => {
    switch (remote) {
      case Remote.FLIPPING:
        updateRemoteState({ activePage });
        break;
      case Remote.CLOSING:
        updateRemoteState({ close: true });
        break;
      case Remote.OPENING:
        updateRemoteState({ activePage: 0 });
        break;
      default:
        break;
    }
  };

  const animateCloseMagazine = () => {
    selectEl(magNav)
      .removeClass('hide', closeDelay)
      .addClass('opening', closeDelay)
      .removeClass('opening', closeDelay + speed / 2)
      .addClass('closing', closeDelay + speed / 2);

    setTimeout(() => {
      remoteControlMagazine(Remote.CLOSING);
      setShowMag(false);
    }, closeDelay);

    if (onClose && showMag) {
      setTimeout(() => {
        onClose();
      }, speed + closeDelay);
    }
  };

  const animateOpenMagazine = () => {
    selectEl(magNav)
      .removeClass('closing')
      .addClass('opening')
      .removeClass('opening', speed / 2)
      .addClass('hide', speed);
    remoteControlMagazine(Remote.OPENING);
    setTimeout(() => {
      setShowMag(true);
    }, speed);
  };

  const handleFlipMagazine = (p) => {
    if (!remoteState) {
      setActivePage(p);
      remoteControlMagazine(Remote.FLIPPING, p);
    }
    logEvent(DID_FLIP_MAGAZINE, DID_FLIP_MAGAZINE, {
      page: p
    });
  };

  const calcPageDimension = () => {
    const { vh, vw } = getViewportSize();
    let height = defaultPaperHeight;
    let width = defaultPaperHeight * paperSizeRatio;

    if (vh && vh < defaultPaperHeight) {
      height = vh;
      width = vh * paperSizeRatio;
    }

    if (vw && vw < width * 2) {
      width = vw / 2;
      height = width / paperSizeRatio;
    }

    setPageDimension({
      width,
      height,
      maxWidth: height * paperSizeRatio,
      maxHeight: height
    });
  };

  React.useEffect(() => {
    setTimeout(() => {
      animateOpenMagazine();
    }, delay + speed);

    calcPageDimension();
  }, []);

  React.useEffect(() => {
    if (close) {
      animateCloseMagazine();
    }
  }, [close]);

  return (
    <div className={`magazine-wrapper fixed-full ${className}`}>
      <img className="magNav closing" src={cover} ref={magNav} />
      {overlay && <img className="overlay" src={overlay} />}
      {showMag && (
        <MagazineFlip
          close={close}
          pages={pages}
          onPageChange={handleFlipMagazine}
          activePage={priorActivePage}
          pageDimension={pageDimension}
        />
      )}

      <style jsx global>{`
        .page {
          position: relative;
        }
        .page img {
          width: 100%;
        }
      `}</style>
      <style jsx>{`
        .portrait-veil {
          display: none;
        }
        .magazine-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
        .magNav {
          margin: auto;
          transition: all ${speed * 0.0005}s ease-in-out;
          cursor: pointer;
          z-index: 3;
        }
        .magNav + img {
          display: none;
        }
        .overlay {
          position: fixed;
          z-index: 2;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }

        :global(.in-meeting.MDLandscape) .overlay{
          left:calc(50% + ${MDLandscapeNormalSpec.contentArea?.left / 2}px)
        }
        .magNav.opening + img,
        .magNav.closing + img {
          display: block;
        }
        .magNav.hide {
          visibility: hidden;
        }

        .magNav.closing {
          z-index: 1;
          box-shadow: 0 0 10px #000;
        }

        .overlay {
          width: auto;
          height: 21vh;
          margin-top: 26.4vh;
          margin-left: 0;
        }

        @media (orientation: landscape) {
          .magazine-wrapper {
            background: url('${background}') center center no-repeat;
            background-size: auto 100vh;
          }
          .magNav {
            height: 100%;
            max-height: ${pageDimension.maxHeight}px;
          }
          .magNav.closing {
            height: 16vh;
            transform: translate(-1vh, 22vh) rotate(-4deg);
          }
          .magNav.opening {
            height: 16vh;
            transform: translate(-1vh, -3vh) rotate(0);
          }
        }
        @media (orientation: portrait) {
          .portrait-veil {
            display: block;
            background: rgba(0, 0, 0, 0.7)
              url(${`${assetBaseUrl}/loubiairways/luggage-animation/inflight-magazine/rotate-phone.png`})
              center center no-repeat;
            background-size: 200px auto;
            z-index: 100;
          }
          .magazine-wrapper {
            background: url('${portraitBackground}') center center no-repeat;
            background-size: auto 100vh;
          }
          .magNav {
            height: auto;
            width: 50vw;
            max-height: none;
          }
          .magNav.closing {
            width: 12vh;
            box-shadow: 0 0 10px #000;
            transform: translate(-1vh, 22vh) rotate(-4deg);
          }
          .magNav.opening {
            width: 12vh;
            transform: translate(-1vh, -3vh) rotate(0);
          }
        }
      `}</style>
    </div>
  );
};

export default Magazine;
