import { throttle } from 'lodash';
import React from 'react';
import { useDispatch } from 'react-redux';
import {
  ILoubiAirways2dAnimationState,
  LoubiAirwaysAnimation
} from '../../../../../interfaces/loubiairways';
import { actionDidUpdateLoubiAirwaysAnimation } from '../../../../../redux/customActions/loubiAirways';
import { useRemoteLoubiAirwaysAnimationState } from '../../../../hooks/loubiAirways';
import {
  AnimationContext,
  FADE_SPEED
} from '../../../../ThreejsAnimation/louboutin/AnimationContext';
import { animationVoyageThrottleDurationInMs } from '../animationUtils';
import ContinueFlightButton from '../Buttons/ContinueFlightButton';
import { inflightAsset, inFlightMagazinePages } from './InFlightMagazinePages';
import Magazine from './Magazine';

const InflightMagazine = () => {
  const { setLoaded, onCloseAnimation } = React.useContext(AnimationContext);
  const [animateClose, setAnimateClose] = React.useState(false);
  const dispatch = useDispatch();

  const remoteState = useRemoteLoubiAirwaysAnimationState(
    LoubiAirwaysAnimation.IN_FLIGHT_MAGAZINE
  ) as ILoubiAirways2dAnimationState;

  const throttledUpdateRemoteState = throttle(
    (state: ILoubiAirways2dAnimationState) => {
      dispatch(
        actionDidUpdateLoubiAirwaysAnimation(
          LoubiAirwaysAnimation.IN_FLIGHT_MAGAZINE,
          state
        )
      );
    },
    animationVoyageThrottleDurationInMs
  );

  const updateRemoteState = (state: ILoubiAirways2dAnimationState) => {
    throttledUpdateRemoteState(state);
  };

  React.useEffect(() => {
    setLoaded(true);
  }, []);

  return (
    <>
      <Magazine
        className="inflightMagazine"
        close={remoteState?.close ?? animateClose}
        cover={`${inflightAsset}/1.jpg`}
        remoteState={remoteState}
        pages={inFlightMagazinePages}
        updateRemoteState={updateRemoteState}
        onClose={onCloseAnimation}
        delay={FADE_SPEED}
        background={`${inflightAsset}/bg.jpg`}
        portraitBackground={`${inflightAsset}/bg-m.jpg`}
        overlay={`${inflightAsset}/overlay.png`}
      />
      <ContinueFlightButton
        onClick={() => {
          setAnimateClose(true);
        }}
      />
    </>
  );
};

export default InflightMagazine;
