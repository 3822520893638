import { useDispatch } from 'react-redux';
import { logEvent } from '../../../analytics';
import { LoubiAirwaysPopupContentType } from '../../../interfaces/loubiairways';
import { actionUpdateLoubiAirwaysPopupContentType } from '../../../redux/customActions/loubiAirways';
import { DID_CLOSE_CONTENT_POPUP } from '../../../utils/constants';
import {
  isUserLoubiAirwaysVIP,
  useLoubiAirwaysState
} from '../../hooks/loubiAirways';
import LoubiAirwaysMiniPopupWrapper from '../../VirtualBoutique/CustomComponent/LouboutinCustomComponent/LoubiAirwaysMiniPopupWrapper';

const LoubiAirwaysPopupContent = () => {
  const { popupContentType } = useLoubiAirwaysState();
  const isVip = isUserLoubiAirwaysVIP();
  const dispatch = useDispatch();

  const getPopupContentByType = (type?: LoubiAirwaysPopupContentType) => {
    switch (type) {
      case LoubiAirwaysPopupContentType.LUGGAGE: {
        const description = (
          <>
            <p>
              The OH XTIAN! Print is a playful and fun collage of images taken
              from Christian Louboutin’s various inspirations from fashion
              magazines to retro posters, vintage advertisements and movies
              celebrating multiple interpretations of femininity from the Femme
              fatale and pin-ups through to the Madone.
            </p>
            <style jsx>{`
              p {
                font-size: 1.1em;
                color: #cf152d;
                text-transform: uppercase;
                margin: 20px 0 0 0;
                font-family: 'AntiqueOlive', Arial;
              }
            `}</style>
          </>
        );
        return ['OH XTIAN!', description];
      }
      case LoubiAirwaysPopupContentType.TAROT: {
        const description = (
          <>
            <p>
              This season, Christian Louboutin draws inspiration from the
              flamboyant Tarot de Marseille cards. Applied on patent leather,
              the Tarot print is a celebration of eclecticism and spirit of
              travel.
            </p>
            <h5>CARACABA</h5>
            <p>
              As an annual celebration of artisanal craftmanship, the Caracaba
              is a patchwork of prints and fabrics of its previous editions from
              the Mexicaba to the Portugaba and more.
            </p>
            <style jsx>{`
              p {
                font-size: 1.1em;
                color: #cf152d;
                text-transform: uppercase;
                margin: 20px 0 0 0;
                font-family: 'AntiqueOlive', Arial;
              }

              h5 {
                font-size: 1.2em;
                color: #cf152d;
                text-transform: uppercase;
                margin: 20px 0 0 0;
                font-family: 'AntiqueOlive-Bold', Arial;
              }
            `}</style>
          </>
        );
        return ['TAROT', description];
      }
      case LoubiAirwaysPopupContentType.XRAY: {
        const description = (
          <>
            <p>
              Being an avid lover of stage performance since his teenage years,
              strass is an important element of Christian Louboutin’s creative
              vocabulary. Fresh, pop inspired styles issued in bright colors
              feature strass embedded heels, recalling a distinct retro pin-up
              aesthetic.
            </p>
            <style jsx>{`
              p {
                font-size: 1.1em;
                color: #cf152d;
                text-transform: uppercase;
                margin: 20px 0 0 0;
                font-family: 'AntiqueOlive', Arial;
              }
            `}</style>
          </>
        );
        return ['STRASS HEEL', description];
      }
      case LoubiAirwaysPopupContentType.SAFETY_GUIDE: {
        const description = (
          <>
            <p>
              The sole has always had special significance for Christian
              Louboutin. This season's Urban theme makes a bold statement,
              combining craftsmanship with state-of-the-art savoir faire to
              create a new innovative lug sole made of technical materials.
            </p>
            <style jsx>{`
              p {
                font-size: 1.1em;
                color: #cf152d;
                text-transform: uppercase;
                margin: 20px 0 0 0;
                font-family: 'AntiqueOlive', Arial;
              }
            `}</style>
          </>
        );
        return ['URBAN TWIST', description];
      }
      case LoubiAirwaysPopupContentType.AIRPLANE_WINDOW: {
        const description = (
          <>
            <p>
              As a tribute to Christian Louboutin’s years working as a landscape
              designer, the Tivoli print is made of playful and surprising
              patterns inspired by the funfair lights of Tivoli Garden in
              Copenhagen.
            </p>
            <style jsx>{`
              p {
                font-size: 1.1em;
                color: #cf152d;
                text-transform: uppercase;
                margin: 20px 0 0 0;
                font-family: 'AntiqueOlive', Arial;
              }
            `}</style>
          </>
        );
        return ['LOUBI IN WONDERLAND', description];
      }
      case LoubiAirwaysPopupContentType.TROLLEY: {
        const description = (
          <>
            <p>
              Christian Louboutin didn’t forget about the time we spend at home
              and created shoes and accessories that can be worn indoors as well
              as outdoors. The designer chose materials such as faux shirling on
              pool slides and iridescent velvet to maximize the comfort while
              keeping it luxurious and elegant.
            </p>
            <style jsx>{`
              p {
                font-size: 1.1em;
                color: #cf152d;
                text-transform: uppercase;
                margin: 20px 0 0 0;
                font-family: 'AntiqueOlive', Arial;
              }
            `}</style>
          </>
        );
        return ['COZY', description];
      }
      case LoubiAirwaysPopupContentType.IN_FLIGHT_MAGAZINE: {
        const description = (
          <>
            <p>
              Christian Louboutin’s signature spikes are combined with
              ironmongery hardware, adding a decorative and subversive element
              to the timeless classic shapes.
            </p>
            <style jsx>{`
              p {
                font-size: 1.1em;
                color: #cf152d;
                text-transform: uppercase;
                margin: 20px 0 0 0;
                font-family: 'AntiqueOlive', Arial;
              }
            `}</style>
          </>
        );
        return ['SWING', description];
      }
      case LoubiAirwaysPopupContentType.WELCOME_INSTRUCTION: {
        const description = (
          <ul>
            <li>
              <img src="/asset/louboutin/click_here_red.svg" alt="Click Here" />
              Click on the hot-spots to discover the FW21 collection
            </li>
            <li>
              <img
                src="/asset/louboutin/back_to_plane_red.svg"
                alt="Back to Plane"
              />
              Click here to exit the animations
            </li>
            {isVip && (
              <li>
                <img
                  src="/asset/louboutin/wishlist_filled.svg"
                  alt="Favourites"
                />
                Click here to add to your favourites
              </li>
            )}
            {isVip && (
              <li>
                <img
                  src="/asset/louboutin/my_favorites_red.svg"
                  alt="My Favourites"
                />
                Find here an overview of all your favourites
              </li>
            )}
            <li>
              <img src="/asset/louboutin/plane_plan_red.svg" alt="Plane Plan" />
              Airplane map to discover where the animations are located
            </li>
            <style jsx>{`
              li {
                font-size: 1.1em;
                color: #cf152d;
                text-transform: uppercase;
                margin: 0;
                font-family: 'AntiqueOlive', Arial;
                text-align: left;
                overflow: hidden;
                display: flex;
                align-items: center;
                padding: 10px 0;
              }
              ul {
                padding: 0;
              }
              img {
                width: 70px;
                height: auto;
                margin-left: 10px;
                float: left;
                margin-right: 10px;
              }
              @media (max-width: 768px) {
                li {
                  font-size: 12px;
                }
              }
            `}</style>
          </ul>
        );
        return [
          'FOR YOUR COMFORT DURING THIS FLIGHT PLEASE FOLLOW THESE INSTRUCTIONS:',
          description,
          'Bienvenue à bord LoubiAirways Flight CL1991'
        ];
      }
      default:
        return [];
    }
  };

  const [title, children, subHeader] = getPopupContentByType(popupContentType);
  const onClose = () => {
    logEvent(DID_CLOSE_CONTENT_POPUP, DID_CLOSE_CONTENT_POPUP, {
      popupContentType
    });
    dispatch(actionUpdateLoubiAirwaysPopupContentType(undefined));
  };

  if (title && children) {
    return (
      <LoubiAirwaysMiniPopupWrapper
        title={title as string}
        subHeader={subHeader as string | undefined}
        onClose={() => {
          onClose();
        }}
        maxWidth={
          popupContentType === LoubiAirwaysPopupContentType.WELCOME_INSTRUCTION
            ? 700
            : 500
        }
      >
        {children}
      </LoubiAirwaysMiniPopupWrapper>
    );
  }
  return null;
};

export default LoubiAirwaysPopupContent;
