import React from 'react';
import * as THREE from 'three';
import { assetBaseUrl } from '../../../config';
import { isUserOnMobile } from '../../../utils/deviceDetector';
import {
  IAnimationProduct,
  ILoubiAirwaysLuggagePopAnimationState,
  REMOTE_ACTION
} from '../../../interfaces/loubiairways';

import {
  luggageAnimationProducts,
  modelWorkshopEnvironmentImagePath,
  suitcaseModel,
  luggageTextures
} from './assets';
import BackToAnimationButton from '../../VirtualBoutique/CustomComponent/LouboutinCustomComponent/Buttons/BackToAnimationButton';
let luggageRemoteState = undefined;
let clickBack = false;
let backDiv;
const isMobileOrTab = isUserOnMobile();
const onClickBack = () => {
  clickBack = true;
};
const lowResModel = luggageAnimationProducts.map((p) => p.lowPolyModelUrl);
const highResModel = luggageAnimationProducts.map((p) => p.highPolyModelUrl);

const LuggagePop = ({
  remoteState,
  updateRemoteState,
  didSelectModel,
  onReady,
  viewOnly
}: {
  remoteState?: ILoubiAirwaysLuggagePopAnimationState;
  updateRemoteState: (state: ILoubiAirwaysLuggagePopAnimationState) => void;
  didSelectModel: (model: IAnimationProduct) => void;
  onReady: () => void;
  viewOnly?: boolean;
}) => {
  const maxScale = 0.16;
  const minScale = 0.12;
  const incScale = 0.01;
  const ZoomIn = 0.13,
    ZoomOut = 0.1;
  const focusItemPosition = new THREE.Vector3(0, 0.8, 2.3);
  const rayCasterScale = new THREE.Vector3(1, 2, 2);
  const itemsArray = Array(luggageAnimationProducts.length);
  const itemsChildForCatchingClick = Array(luggageAnimationProducts.length);
  const mouse = new THREE.Vector2();
  const mouse_2 = new THREE.Vector2();

  const mouseDownPos = new THREE.Vector2();
  const raycaster = new THREE.Raycaster();
  const touchType = {
    touchDown: 'touchDown',
    touchMove: 'touchMove',
    touchUp: 'touchUp'
  };
  const animationState = {
    popupProducts: 'popupProducts',
    focusModel: 'focusModel',
    closeLid: 'closeLid'
  };

  let state = animationState.closeLid;
  const lastPosition = new THREE.Vector3();
  let focusedModel = undefined;
  let initScale = 1;
  let controls = undefined;
  let suitcaseLid = undefined;
  let initDistance = 0;
  let startRotating = false;
  let loadingCount = 0;
  let doubletapClick = 0;
  let isDown = false;
  let isTweening = false;
  let transparent_Plane = undefined;
  const transparentMat = new THREE.MeshBasicMaterial({
    transparent: true,
    opacity: 0.01,
    color: 0x000000
  });
  let zoomModel = undefined;
  let isFocused = false;
  let epicTrottaTextureMap, epicTrottaTextureNormal;
  let epicBootyTextureMap, epicBootyTextureNormal;
  let epicSandalTextureMap, epicSandalTextureNormal;
  let epicSlingTextureMap, epicSlingTextureNormal;
  let bagTextureMap, bagTextureNormal;

  const canvasRef = React.useRef();
  let camera, GLTFLoader, RGBELoader, TWEEN, ObjectControls, renderer;
  let scene = new THREE.Scene();
  const loadingManager = new THREE.LoadingManager(() => {
    callback();
  });

  const loadZoom = (id) => {
    if (isMobileOrTab) return;
    const gltfLoader = new GLTFLoader();
    const ItemId = id;
    gltfLoader.load(highResModel[ItemId], (gltf) => {
      const group = new THREE.Group();
      const root = gltf.scene;
      setScalePivot(root, ItemId);
      group.add(root);
      scene.add(group);
      group.scale.set(0.05, 0.05, 0.05);
      if (zoomModel) {
        loadNew(false, 0);
      }
      zoomModel = group;
      zoomModel.ItemId = ItemId;
      if (
        !focusedModel ||
        focusedModel?.ItemId != ItemId ||
        isFocused == false
      ) {
        loadNew(false, 0);
      }
    });
  };
  const loadNew = (isLoad, id) => {
    if (isLoad) {
      if (zoomModel === undefined) loadZoom(id);
    } else {
      if (zoomModel === undefined) return;
      zoomModel.traverse((object) => {
        if (!object.isMesh) return;
        scene.remove(object);
        object.geometry.dispose();
        if (object.material.isMaterial) {
          cleanMaterial(object.material);
        } else {
          // an array of materials
          for (const material of object.material) cleanMaterial(material);
        }
        object.geometry = undefined;
        object = undefined;
      });
      scene.remove(zoomModel);
      zoomModel = undefined;
    }
  };
  const updateObjectControlForModel = (model) => {
    if (
      model === undefined ||
      camera === undefined ||
      renderer === undefined ||
      viewOnly
    )
      return;
    if (!controls) {
      controls = new ObjectControls(camera, renderer.domElement, model);
    } else {
      controls.setObjectToMove(model);
    }
    controls.setDistance(2, 400); // set min - max distance for zoom
    controls.setZoomSpeed(0.5); // set zoom speed
    controls.enableVerticalRotation();
    controls.enableHorizontalRotation();
    controls.disableZoom();
    controls.setRotationSpeed(0.05);
  };
  const updateRemoteObjectControlForModel = () => {
    if (!focusedModel) return;
    updateRemoteState({
      flag: REMOTE_ACTION.MOUSE_CONTROL,
      position: [
        focusedModel.position.x,
        focusedModel.position.y,
        focusedModel.position.z
      ],
      focusItem: focusedModel.ItemId,
      scale: [focusedModel.scale.x, focusedModel.scale.y, focusedModel.scale.z],
      rotation: [
        focusedModel.rotation.x,
        focusedModel.rotation.y,
        focusedModel.rotation.z
      ]
    });
  };
  const findProductByName = (name) =>
    luggageAnimationProducts.find((p) => p.modelName === name);
  const focus = (model, no) => {
    if (model === undefined) return;
    backDiv.style.display = 'block';
    isTweening = true;
    state = animationState.focusModel;
    focusedModel = model;
    isFocused = true;
    focusedModel.indexNo = no;
    loadNew(true, no);
    didSelectModel(findProductByName(focusedModel.name));
    // itemLable.innerHTML = focusedModel.name;
    lastPosition.set(model.position.x, model.position.y, model.position.z);
    new TWEEN.Tween(model.scale)
      .to(
        {
          x: ZoomIn,
          y: ZoomIn,
          z: ZoomIn
        },
        1000
      )
      .easing(TWEEN.Easing.Exponential.InOut)
      .start();
    new TWEEN.Tween(model.position)
      .to(
        {
          x: focusItemPosition.x,
          y: focusItemPosition.y,
          z: focusItemPosition.z
        },
        1000
      )
      .easing(TWEEN.Easing.Exponential.InOut)
      .onComplete(() => {
        updateObjectControlForModel(model);
        isTweening = false;
      })
      .start();

    transparent_Plane.visible = true;
    transparentMat.opacity = 0.01;
    new TWEEN.Tween(transparentMat)
      .to({ opacity: 0.7 }, 1000)
      .easing(TWEEN.Easing.Exponential.InOut)
      .start();
  };
  const createRayCasterCatcher = () =>
    new THREE.Mesh(
      new THREE.BoxBufferGeometry(
        rayCasterScale.x,
        rayCasterScale.y,
        rayCasterScale.z
      ),
      new THREE.MeshPhongMaterial({
        color: 0xff0000,
        transparent: false,
        opacity: 1
      })
    );

  const setScalePivot = (model, ItemId) => {
    if (!model) return;
    let scl = 12;
    if (ItemId == 1) {
      scl = 10;
      model.position.set(0, -0.5, 0);
    } else if (ItemId == 0) {
      scl = 6;
      model.position.set(0, -1, 0);
    } else if (ItemId == 2 || ItemId == 3) {
      model.position.set(0, -0.3, 0);
    } else {
      model.position.set(0, -0.5, 0);
    }
    model.scale.set(scl, scl, scl);
  };
  const setMaterial = (model, map, normal) => {
    if (!model) return;
    model.traverse((object) => {
      if (!object['isMesh']) return;
      if (object['material'].isMaterial) {
        const met = object['material'];
        if (met.name === 'Baguette_01_mat') {
          met.map.image = map.image;
          met.map.needsUpdate = true;
        }
        if (met.name === 'Baguette_02_mat') {
          met.normalMap.image = normal.image;
          met.normalMap.needsUpdate = true;
        }
        if (
          met.name === 'Trotta_mat' ||
          met.name === 'EPICBOOTY70_mat' ||
          met.name === 'EpicSandal_mat' ||
          met.name === 'Sling_mat'
        ) {
          met.map.image = map.image;
          met.map.needsUpdate = true;
          met.normalMap.image = normal.image;
          met.normalMap.needsUpdate = true;
        }
      }
    });
  };
  const loadSneaker = (callback, id) => {
    const gltfLoader = new GLTFLoader(loadingManager);
    const ItemId = id;
    gltfLoader.load(lowResModel[ItemId], (gltf) => {
      const group = new THREE.Group();
      const root = gltf.scene;
      root.position.set(0, 0, 0);
      root.scale.set(1, 1, 1);
      setScalePivot(root, ItemId);

      itemsChildForCatchingClick[ItemId] = createRayCasterCatcher();
      itemsChildForCatchingClick[ItemId].position.set(
        0,
        rayCasterScale.y * 0.25,
        0
      );
      if (ItemId == 0 || ItemId == 3) {
        itemsChildForCatchingClick[ItemId].rotation.set(0, Math.PI * 0.5, 0);
      }

      itemsChildForCatchingClick[ItemId].visible = false;
      itemsArray[ItemId] = group;
      group.name = luggageAnimationProducts[ItemId].modelName;
      group.visible = false;
      group.add(itemsChildForCatchingClick[ItemId]);
      group.add(root);
      group.scale.set(0.02, 0.02, 0.02);
      group.position.set(0, -0.9, 0);
      group['spd'] = 0;
      group['ItemId'] = ItemId;
      scene.add(group);
      if (callback) callback(id);
    });
  };
  const loadSuitcase = () => {
    const gltfLoader = new GLTFLoader(loadingManager);
    gltfLoader.load(suitcaseModel, (gltf) => {
      const root = gltf.scene;
      if (!root) return;
      root.name = 'suitcase';
      scene.add(root);
      suitcaseLid = root.getObjectByName('Group018');
      if (suitcaseLid) suitcaseLid.rotation.set(Math.PI * 0.5, 0, 0);
      root.position.set(0, -0.9, 0);
      root.scale.set(1.0, 1.0, 1.0);
    });
  };
  const loadEnvironment = () => {
    if (renderer == undefined || scene == undefined) {
      return;
    }
    const textureLoader = new THREE.TextureLoader(loadingManager);
    if (isMobileOrTab) {
      epicTrottaTextureMap = textureLoader.load(luggageTextures.epicTrottaMap);
      epicTrottaTextureNormal = textureLoader.load(
        luggageTextures.epicTrottaNormal
      );

      epicBootyTextureMap = textureLoader.load(luggageTextures.epicBootyMap);
      epicBootyTextureNormal = textureLoader.load(
        luggageTextures.epicBootyNormal
      );

      epicSandalTextureMap = textureLoader.load(luggageTextures.epicSandalMap);
      epicSandalTextureNormal = textureLoader.load(
        luggageTextures.epicSandalNormal
      );

      epicSlingTextureMap = textureLoader.load(luggageTextures.epicSlingMap);
      epicSlingTextureNormal = textureLoader.load(
        luggageTextures.epicSlingNormalMap
      );

      bagTextureMap = textureLoader.load(luggageTextures.baguetteBagMap);
      bagTextureNormal = textureLoader.load(
        luggageTextures.baguetteBagNormalMap
      );
    }
    transparent_Plane = new THREE.Mesh(
      new THREE.PlaneGeometry(22, 10),
      transparentMat
    );
    transparent_Plane.rotation.set(-Math.PI * 0.1346, 0, 0);
    transparent_Plane.position.set(0, 0.5, 1.51);
    scene.add(transparent_Plane);
    transparent_Plane.visible = false;
    const loader = new RGBELoader();
    loader.setDataType(THREE.UnsignedByteType);
    loader.load(
      modelWorkshopEnvironmentImagePath,
      (texture) => {
        if (renderer && scene) {
          let pmremGenerator = new THREE.PMREMGenerator(renderer);
          pmremGenerator.compileEquirectangularShader();
          const envMap = pmremGenerator.fromEquirectangular(texture).texture;
          pmremGenerator = undefined;
          scene.environment = envMap;
        }
      },
      undefined,
      undefined
    );
  };

  const pop = (product, coord) => {
    if (product === undefined) return;
    const mtime = 1500;
    state = animationState.popupProducts;
    product.visible = true;
    didSelectModel(undefined);
    isTweening = true;
    isFocused = false;
    loadNew(false, 0);
    new TWEEN.Tween(product.scale)
      .to(
        {
          x: ZoomOut,
          y: ZoomOut,
          z: ZoomOut
        },
        mtime
      )
      .easing(TWEEN.Easing.Exponential.InOut)
      .start();
    new TWEEN.Tween(product.position)
      .to(coord, mtime)
      .easing(TWEEN.Easing.Exponential.InOut)
      .delay(200)
      .onComplete(() => {
        startRotating = true;
        focusedModel = undefined;
      })
      .start();
    new TWEEN.Tween(product.rotation)
      .to(
        {
          x: 0,
          y: Math.PI,
          z: 0
        },
        mtime
      )
      .easing(TWEEN.Easing.Exponential.InOut)
      .delay(200)
      .onComplete(() => {
        state = animationState.popupProducts;
        isTweening = false;
      })
      .start();
    new TWEEN.Tween(transparentMat)
      .to({ opacity: 0.01 }, mtime)
      .easing(TWEEN.Easing.Exponential.InOut)
      .onComplete(() => {
        transparent_Plane.visible = false;
      })
      .start();
  };

  const popupProducts = (product, coord) => {
    if (product === undefined) return;
    const easing = TWEEN.Easing.Exponential.In;
    const mtime = 1000;
    state = animationState.popupProducts;
    product.visible = true;
    didSelectModel(undefined);
    isTweening = true;
    new TWEEN.Tween(product.scale)
      .to({ x: ZoomOut, y: ZoomOut, z: ZoomOut }, mtime)
      .easing(easing)
      .start();
    new TWEEN.Tween(product.position)
      .to(coord, mtime)
      .easing(easing)
      .delay(200)
      .onComplete(() => {
        startRotating = true;
        focusedModel = undefined;
        loadNew(false, 0);
      })
      .start();
    new TWEEN.Tween(product.rotation)
      .to({ x: 0, y: Math.PI * 2, z: 0 }, mtime)
      .easing(easing)
      .delay(200)
      .onComplete(() => {
        state = animationState.popupProducts;
        isTweening = false;
      })
      .start();
  };

  const openLid = () => {
    if (suitcaseLid === undefined) return;
    isTweening = true;
    new TWEEN.Tween(suitcaseLid.rotation)
      .to(
        {
          x: -Math.PI * 0.34
        },
        1000
      )
      .easing(TWEEN.Easing.Exponential.InOut)
      .onComplete(() => {
        isTweening = false;
      })
      .start();
  };
  const openLidCall = () => {
    openLid();
    for (let i = 0; i < itemsArray.length; i++) {
      if (itemsArray[i]) {
        itemsArray[i].spd = 72 * i;
        const radian = (Math.PI / 180) * itemsArray[i].spd; //(i * 90);
        const radian2 = (Math.PI / 180) * itemsArray[i].spd * 3;
        popupProducts(itemsArray[i], {
          x: Math.sin(radian) * (0.3 + i * 0.12),
          y: -0.3 + 0.38 * i + Math.sin(radian2) * 0.01,
          z: Math.cos(radian) * (0.3 + i * 0.12)
        });
      }
    }
    state = animationState.popupProducts;
  };

  const unFocusCall = () => {
    backDiv.style.display = 'none';
    for (let i = 0; i < itemsArray.length; i++) {
      if (focusedModel == itemsArray[i] && focusedModel) {
        if (controls != undefined) {
          controls.disableVerticalRotation();
          controls.disableHorizontalRotation();
          controls.disableZoom();
        }
        const spd = (itemsArray[i].spd += (0.2 + i * 0.02) * 50);
        const radian = (Math.PI / 180) * spd; //(i * 90);
        const radian2 = (Math.PI / 180) * spd * 3;
        pop(itemsArray[i], {
          x: Math.sin(radian) * (0.3 + i * 0.12),
          y: -0.3 + 0.38 * i + Math.sin(radian2) * 0.01,
          z: Math.cos(radian) * (0.3 + i * 0.12)
        });
        loadNew(false, 0);
      }
    }
  };
  const detectFirstTouchedObject = (e, type, sys, objects) => {
    if (!raycaster) {
      return;
    }
    const CANVAS_HEIGHT = window.innerHeight;
    const CANVAS_WIDTH = window.innerWidth;
    if (e.touches != null) {
      if (e.touches.length > 0) {
        mouse.x = (e.touches[0].pageX / window.innerWidth) * 2 - 1;
        mouse.y = -(e.touches[0].pageY / window.innerHeight) * 2 + 1;
      }
    } else {
      const elem = renderer.domElement,
        boundingRect = elem.getBoundingClientRect();
      const x =
        (e.clientX - boundingRect.left) * (elem.width / boundingRect.width);
      const y =
        (e.clientY - boundingRect.top) * (elem.height / boundingRect.height);
      mouse.x = (x / CANVAS_WIDTH) * 2 - 1;
      mouse.y = -(y / CANVAS_HEIGHT) * 2 + 1;
    }
    raycaster.setFromCamera(mouse, camera);
    const intersects = raycaster.intersectObjects(objects);
    if (intersects.length) {
      return intersects[0];
    }
  };

  const touchEvent = (e, type, sys) => {
    if (remoteState != undefined || isTweening || !itemsArray[4]) return;
    setTouchValues(e, type, sys);
    if (touchType.touchDown === type) {
      isDown = true;
    }
    if (touchType.touchUp === type) {
      isDown = false;
    }
    if (
      focusedModel != undefined &&
      isDown == true &&
      state === animationState.focusModel
    ) {
      updateRemoteObjectControlForModel();
    }
    if (
      touchType.touchUp === type &&
      mouseDownPos.x === mouse.x &&
      mouseDownPos.y == mouse.y
    ) {
      if (startRotating == false && state === animationState.closeLid) {
        isTweening = false;
      } else if (state === animationState.focusModel && focusedModel) {
        const clickedModel = detectFirstTouchedObject(e, type, sys, [
          itemsChildForCatchingClick[focusedModel.indexNo]
        ]);
        if (clickedModel) {
          unFocusCall();
          updateRemoteState({ flag: REMOTE_ACTION.UN_FOCUS });
        }
      } else {
        const clickedModel = detectFirstTouchedObject(
          e,
          type,
          sys,
          itemsChildForCatchingClick
        );
        if (clickedModel && clickedModel.object && focusedModel == undefined) {
          for (let i = 0; i < itemsChildForCatchingClick.length; i++) {
            if (
              itemsChildForCatchingClick[i] == clickedModel.object &&
              itemsArray[i]
            ) {
              focus(itemsArray[i], i);
              updateRemoteState({ flag: REMOTE_ACTION.FOCUS, focusItem: i });
            }
          }
        }
      }
    }
  };

  const onwheelEvent = (event) => {
    if (remoteState != undefined) return;
    if (state == animationState.focusModel) {
      let scl = focusedModel.scale.x;
      if (event.deltaY > 0) {
        if (scl < maxScale) scl += incScale;
      } else {
        if (scl > minScale) scl -= incScale;
      }
      focusedModel.scale.set(scl, scl, scl);
      updateRemoteObjectControlForModel();
    }
  };
  const onPinch = (ev) => {
    if (remoteState != undefined) return;

    if (
      state == animationState.focusModel &&
      (ev.type == 'pinchstart' || ev.type == 'pinchmove')
    ) {
      if (ev.type == 'pinchstart') {
        initScale = focusedModel.scale.x || 1;
      }
      focusedModel.scale.set(
        initScale * ev.scale,
        initScale * ev.scale,
        initScale * ev.scale
      );
      if (focusedModel.scale.x > maxScale)
        focusedModel.scale.set(maxScale, maxScale, maxScale);
      if (focusedModel.scale.x < minScale)
        focusedModel.scale.set(minScale, minScale, minScale);
      updateRemoteObjectControlForModel();
    }
  };

  const setTouchValues = (e, type, sys) => {
    const CANVAS_HEIGHT = window.innerHeight;
    const CANVAS_WIDTH = window.innerWidth;
    if (e.touches !== null && e.touches !== undefined) {
      if (e.touches.length > 0) {
        mouse.x = (e.touches[0].pageX / window.innerWidth) * 2 - 1;
        mouse.y = -(e.touches[0].pageY / window.innerHeight) * 2 + 1;
        if (e.touches.length === 2) {
          mouse_2.x = (e.touches[1].pageX / window.innerWidth) * 2 - 1;
          mouse_2.y = -(e.touches[1].pageY / window.innerHeight) * 2 + 1;
          if (type === touchType.touchDown) {
            initDistance = mouse_2.distanceTo(mouse);
            onPinch({ type: 'pinchstart', scale: 1 });
          } else {
            const currentDistance = mouse_2.distanceTo(mouse);
            onPinch({
              type: 'pinchmove',
              scale: currentDistance / initDistance
            });
          }
        }
      }
    } else {
      const elem = renderer.domElement,
        boundingRect = elem.getBoundingClientRect();
      const x =
        (e.clientX - boundingRect.left) * (elem.width / boundingRect.width);
      const y =
        (e.clientY - boundingRect.top) * (elem.height / boundingRect.height);
      mouse.x = (x / CANVAS_WIDTH) * 2 - 1;
      mouse.y = -(y / CANVAS_HEIGHT) * 2 + 1;
    }
    if (type === touchType.touchDown) {
      mouseDownPos.x = mouse.x;
      mouseDownPos.y = mouse.y;
      const diff = new Date().getTime() - doubletapClick;
      if (diff < 300) {
        if (e.touches !== null && e.touches !== undefined) {
          if (e.touches.length === 1) {
            onPinch({
              type: 'doubletap',
              scale: 1
            });
          }
        } else {
          onPinch({
            type: 'doubletap',
            scale: 1
          });
        }
      }
      doubletapClick = new Date().getTime();
    }
    raycaster.setFromCamera(mouse, camera);
  };

  const eventDown = (e) => {
    touchEvent(e, touchType.touchDown, 0);
  };
  const eventMove = (e) => {
    touchEvent(e, touchType.touchMove, 0);
  };
  const eventUp = (e) => {
    touchEvent(e, touchType.touchUp, 0);
  };
  const zoomDisable = (e) => {
    e.preventDefault();
    document.body.style['zoom'] = '1';
  };
  const addEventListeners = () => {
    document.addEventListener('mousedown', eventDown);
    document.addEventListener('touchstart', eventDown);
    document.addEventListener('mousemove', eventMove);
    document.addEventListener('touchmove', eventMove);
    document.addEventListener('mouseup', eventUp);
    document.addEventListener('touchend', eventUp);
    document.addEventListener('wheel', onwheelEvent);
    window.addEventListener('resize', onWindowResize, false);
  };
  const cleanMaterial = (material) => {
    for (const key of Object.keys(material)) {
      const value = material[key];
      if (value && typeof value === 'object' && 'minFilter' in value) {
        value.dispose();
      }
    }
    material.dispose();
    material = undefined;
  };
  const cleanTexture = (value) => {
    if (value && typeof value === 'object' && 'minFilter' in value) {
      value.dispose();
    }
    value = undefined;
    return undefined;
  };
  const cleanUp = () => {
    if (scene != undefined) {
      scene.traverse((object) => {
        if (!object['isMesh']) return;
        object['geometry'].dispose();
        if (object['material'].isMaterial) {
          cleanMaterial(object['material']);
        } else {
          for (const material of object['material']) cleanMaterial(material);
        }
        object['geometry'] = undefined;
        object = undefined;
      });
      scene.children.forEach((model) => {
        scene.remove(model);
      });
    }
    if (renderer != undefined) {
      renderer.dispose();
      renderer && renderer.renderLists.dispose();
    }
    if (controls != undefined) {
      controls.disableVerticalRotation();
      controls.disableHorizontalRotation();
      controls.disableZoom();
    }
    {
      epicTrottaTextureMap = cleanTexture(epicTrottaTextureMap);
      epicTrottaTextureNormal = cleanTexture(epicTrottaTextureNormal);
      epicBootyTextureMap = cleanTexture(epicBootyTextureMap);
      epicBootyTextureNormal = cleanTexture(epicBootyTextureNormal);
      epicSandalTextureMap = cleanTexture(epicSandalTextureMap);
      epicSandalTextureNormal = cleanTexture(epicSandalTextureNormal);
      epicSlingTextureMap = cleanTexture(epicSlingTextureMap);
      epicSlingTextureNormal = cleanTexture(epicSlingTextureNormal);
      bagTextureMap = cleanTexture(bagTextureMap);
      bagTextureNormal = cleanTexture(bagTextureNormal);
    }

    document.removeEventListener('touchstart', eventDown);
    document.removeEventListener('touchmove', eventMove);
    document.removeEventListener('touchend', eventUp);
    document.removeEventListener('mousedown', eventDown);
    document.removeEventListener('mousemove', eventMove);
    document.removeEventListener('mouseup', eventUp);
    document.removeEventListener('wheel', onwheelEvent);
    window.removeEventListener('resize', onWindowResize);

    document.removeEventListener('gesturestart', zoomDisable);
    document.removeEventListener('gesturechange', zoomDisable);
    document.removeEventListener('gestureend', zoomDisable);

    itemsArray.length = 0;
    itemsChildForCatchingClick.length = 0;
    controls = undefined;
    scene = undefined;
    renderer = undefined;
    camera = undefined;
  };

  const callback = () => {
    loadingCount++;
    itemsArray
      .filter((model) => model)
      .forEach((model) => {
        model.visible = true;
      });
    if (isMobileOrTab) {
      if (epicTrottaTextureMap?.image && epicTrottaTextureNormal?.image)
        setMaterial(
          itemsArray[0],
          epicTrottaTextureMap,
          epicTrottaTextureNormal
        );
      if (epicBootyTextureMap?.image && epicBootyTextureNormal?.image)
        setMaterial(itemsArray[1], epicBootyTextureMap, epicBootyTextureNormal);
      if (epicSandalTextureMap?.image && epicSandalTextureNormal?.image)
        setMaterial(
          itemsArray[2],
          epicSandalTextureMap,
          epicSandalTextureNormal
        );
      if (epicSlingTextureMap?.image && epicSlingTextureNormal?.image)
        setMaterial(itemsArray[3], epicSlingTextureMap, epicSlingTextureNormal);
      if (bagTextureNormal?.image && bagTextureMap?.image)
        setMaterial(itemsArray[4], bagTextureMap, bagTextureNormal);
    }
    setTimeout(() => {
      openLidCall();
    }, 600);
    onReady();
  };
  const animate = () => {
    if (renderer == undefined || scene == undefined) {
      return;
    }
    requestAnimationFrame(animate);
    if (startRotating) {
      itemsArray.forEach((item, i) => {
        const radian = (Math.PI / 180) * item.spd; //(i * 90);
        const radian2 = (Math.PI / 180) * item.spd * 3;
        if (item) {
          item.visible = true;
          if (focusedModel !== undefined) {
            if (focusedModel.id !== item.id) {
              item.rotation.y -= 0.005;
              item.position.set(
                Math.sin(radian) * (0.3 + i * 0.12),
                -0.3 + 0.38 * i + Math.sin(radian2) * 0.01,
                Math.cos(radian) * (0.3 + i * 0.12)
              );
              item.spd += 0.2 + i * 0.02;
            }
            if (isTweening == false && focusedModel.id === item.id)
              item.spd += 0.2 + i * 0.02;
          } else {
            item.visible = true;
            item.rotation.y -= 0.005;
            item.position.set(
              Math.sin(radian) * (0.3 + i * 0.12),
              -0.3 + 0.38 * i + Math.sin(radian2) * 0.01,
              Math.cos(radian) * (0.3 + i * 0.12)
            );
            item.spd += 0.2 + i * 0.02;
          }
        }
      });
    }
    if (
      zoomModel !== undefined &&
      focusedModel !== undefined &&
      focusedModel?.ItemId === zoomModel?.ItemId
    ) {
      zoomModel.position.set(
        focusedModel.position.x,
        focusedModel.position.y,
        focusedModel.position.z
      );
      zoomModel.scale.set(
        focusedModel.scale.x,
        focusedModel.scale.y,
        focusedModel.scale.z
      );
      zoomModel.rotation.set(
        focusedModel.rotation.x,
        focusedModel.rotation.y,
        focusedModel.rotation.z
      );
      focusedModel.visible = false;
    } else {
      if (focusedModel) focusedModel.visible = true;
    }
    if (loadingCount > 0) {
      loadingCount++;
    }
    if (clickBack && !viewOnly) {
      clickBack = false;
      unFocusCall();
      updateRemoteState({ flag: REMOTE_ACTION.UN_FOCUS });
    }
    if (
      luggageRemoteState &&
      loadingCount > 10 &&
      state !== animationState.closeLid
    ) {
      if (
        luggageRemoteState.flag === REMOTE_ACTION.OPEN_LID &&
        luggageRemoteState.trunkOpen != undefined
      ) {
        openLidCall();
      }
      if (
        luggageRemoteState.flag === REMOTE_ACTION.FOCUS &&
        luggageRemoteState.focusItem != undefined
      ) {
        focus(
          itemsArray[luggageRemoteState.focusItem],
          luggageRemoteState.focusItem
        );
      }
      if (luggageRemoteState.flag === REMOTE_ACTION.UN_FOCUS) {
        unFocusCall();
      }
      if (
        luggageRemoteState.flag === REMOTE_ACTION.MOUSE_CONTROL &&
        luggageRemoteState.position != undefined &&
        luggageRemoteState.scale != undefined &&
        luggageRemoteState.rotation != undefined
      ) {
        if (focusedModel?.ItemId != luggageRemoteState.focusItem) {
          loadNew(false, 0);
          focusedModel = itemsArray[luggageRemoteState.focusItem];
        }
        if (focusedModel) {
          focusedModel.position.set(
            luggageRemoteState.position[0],
            luggageRemoteState.position[1],
            luggageRemoteState.position[2]
          );
          focusedModel.scale.set(
            luggageRemoteState.scale[0],
            luggageRemoteState.scale[1],
            luggageRemoteState.scale[2]
          );
          focusedModel.rotation.set(
            luggageRemoteState.rotation[0],
            luggageRemoteState.rotation[1],
            luggageRemoteState.rotation[2]
          );
        }
      }
      luggageRemoteState.flag = undefined;
      luggageRemoteState = undefined;
    }

    renderer.render(scene, camera);
    TWEEN.update();
  };

  const onWindowResize = () => {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(window.innerWidth, window.innerHeight);
  };

  const loadThreeJsModulesAsync = async () => {
    GLTFLoader = (await import('three/examples/jsm/loaders/GLTFLoader'))
      .GLTFLoader;
    RGBELoader = (await import('three/examples/jsm/loaders/RGBELoader'))
      .RGBELoader;
    TWEEN = (await import('three/examples/jsm/libs/tween.module.min.js')).TWEEN;
    ObjectControls = (await import('../common/ObjectControls')).ObjectControls;
  };
  React.useEffect(() => {
    if (remoteState) {
      luggageRemoteState = remoteState;
    }
  }, [remoteState]);

  React.useEffect(() => {
    const canvas = document.querySelector('#c') as HTMLCanvasElement;
    backDiv = document.getElementById('topright');
    backDiv.style.display = 'none';
    camera = new THREE.PerspectiveCamera(
      45,
      window.innerWidth / window.innerHeight,
      0.1,
      20
    );
    renderer = new THREE.WebGLRenderer({
      canvas,
      antialias: true,
      alpha: true
    });

    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setClearColor(0x000000, 0);
    renderer.outputEncoding = THREE.sRGBEncoding;

    camera.position.set(0, 1.0, 3);
    camera.lookAt(0, 0.4, 0);
    loadingCount = 0;
    if (isMobileOrTab) {
      document.addEventListener('gesturestart', zoomDisable);
      document.addEventListener('gesturechange', zoomDisable);
      document.addEventListener('gestureend', zoomDisable);
    }
    loadThreeJsModulesAsync().then(() => {
      loadEnvironment();
      loadSuitcase();
      for (let i = 0; i < luggageAnimationProducts.length; i++) {
        loadSneaker(null, i);
      }
      if (!viewOnly) {
        addEventListeners();
      }
      animate();
    });

    return () => {
      cleanUp();
    };
  }, []);

  return (
    <div>
      <canvas id="c" ref={canvasRef}></canvas>
      <p id="topright">
        <BackToAnimationButton onClick={onClickBack} />
      </p>
      <style jsx>
        {`
          div {
            background: url(${`${assetBaseUrl}/loubiairways/luggage-animation/luggage/background/v4/luggage_background.png`})
              no-repeat;
            background-position: center center;
            background-size: cover;
            background-attachment: fixed;
          }
          #c,
          div {
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
          }
          a {
            position: fixed;
            top: 2vh;
            left: 2vh;
            font-size: 0.81em;
            text-align: left;
          }
        `}
      </style>
    </div>
  );
};

export default LuggagePop;
