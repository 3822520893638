import React from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { useDispatch } from 'react-redux';
import { useRemoteLoubiAirwaysAnimationState } from '../../../../hooks/loubiAirways';
import { LoubiAirwaysAnimation } from '../../../../../interfaces/loubiairways';
import { throttle } from 'lodash';
import { logEvent } from '../../../../../analytics';
import { DID_UPDATE_ANIMATION_STATE } from './../../../../../utils/constants';
import { getViewportSize } from '../../../../../utils/window';
import { actionDidUpdateLoubiAirwaysAnimation } from '../../../../../redux/customActions/loubiAirways';
import { animationVoyageThrottleDurationInMs } from '../animationUtils';
//TODO, move out
interface ZoomAndPosition {
  previousScale?: number;
  scale?: number;
  positionX?: number;
  positionY?: number;
}
const NewsPaperContent = ({ children }: { children: React.ReactNode }) => {
  const dispatch = useDispatch();
  const [viewportSize, setViewportSize] = React.useState({ vw: 1, vh: 1 });
  const [transform, setTransform] = React.useState<any>();
  const { vw, vh } = viewportSize;
  const logEventThrottled = throttle(
    (state: ZoomAndPosition) => {
      logEvent(DID_UPDATE_ANIMATION_STATE, DID_UPDATE_ANIMATION_STATE, {
        animation: LoubiAirwaysAnimation.NEWSPAPER,
        state
      });
    },
    1000,
    { trailing: true }
  );

  const remoteState = useRemoteLoubiAirwaysAnimationState(
    LoubiAirwaysAnimation.NEWSPAPER
  );

  const throttledUpdateRemoteState = throttle((state: any) => {
    dispatch(
      actionDidUpdateLoubiAirwaysAnimation(LoubiAirwaysAnimation.NEWSPAPER, {
        scale: [state?.previousScale || 1, state?.scale || 1],
        position: [state?.positionX || null, state?.positionY || null]
      })
    );
  }, animationVoyageThrottleDurationInMs);

  const onZoomChange = (e: any) => {
    const state = {
      previousScale: e?.previousScale,
      scale: e?.scale,
      positionX: e?.positionX / vw,
      positionY: e?.positionY / vh
    };

    if (
      transform?.scale !== state?.scale ||
      transform?.positionX !== state?.positionX ||
      transform?.positionY !== state?.positionY
    ) {
      setTransform(state);
      throttledUpdateRemoteState(state);
      logEventThrottled(state);
    }
  };

  React.useEffect(() => {
    setViewportSize(getViewportSize());
  }, []);

  const props = !remoteState
    ? {
        onZoomChange,
        wheel: { step: 200 },
        onPanning: onZoomChange
      }
    : {
        options: { disabled: true },
        scale: remoteState?.scale?.[1] || 1,
        positionX:
          remoteState?.scale?.[1] !== 1
            ? remoteState?.position?.[0] * vw || 0
            : 0,
        positionY:
          remoteState?.scale?.[1] !== 1
            ? remoteState?.position?.[1] * vh || 0
            : 0
      };

  return (
    <TransformWrapper {...props}>
      <div className="NewsPaper">
        <TransformComponent>{children}</TransformComponent>
      </div>

      <style jsx>{`
        .NewsPaper {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          top: 0;
          z-index: 10;
        }
        .NewsPaper > div {
          margin: auto;
        }

        .backdrop {
          background: rgba(0, 0, 0, 0.4);
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        }
        .close {
          position: fixed;
          top: 20px;
          right: 20px;
          background: #fff;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          z-index: 1001;
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.6);
        }
        .NewsPaper :global(.cover),
        .NewsPaper :global(.img-wrapper) {
          margin: auto;
          display: block;
          height: 100vh;
          width: auto;
        }
        @media (orientation: portrait) {
          .NewsPaper :global(.cover),
          .NewsPaper :global(.img-wrapper) {
            height: auto;
            width: 100vw;
            max-width: calc(100vh * 0.67);
          }
        }
      `}</style>
    </TransformWrapper>
  );
};

export default NewsPaperContent;
