import { assetBaseUrl, videoBaseUrl } from '.';

export const scenesToPlayBackgroundMusic = [
  'scene_000000',
  'scene_000000a',
  'scene_010000',
  'scene_020000',
  'scene_030000',
  'scene_030000a',
  'scene_040000',
  'scene_040000b',
  'scene_050000',
  'scene_050000a',
  'scene_060000',
  'scene_060000a',
  'scene_070000',
  'scene_080000',
  'scene_090000'
];

export const scenesToShowSpotifyPlaylist = [
  'scene_100000',
  'scene_110000',
  'scene_120000',
  'scene_130000'
];

export const countDownEndDate = new Date('2021-03-08T10:00:00+01:00');
export const countDownEndDateJp = new Date('2021-05-20T10:00:00+09:00');

export const jpIconUrlBase = `${assetBaseUrl}/loubiairways/pages/japan/icons_v2`;

export const cockpitVideoUrl = `${videoBaseUrl}/streaming/loubiairways/cockpit_desktop/v1/Cockpit_Desktop.m3u8`;
export const cockpitVideoMobileUrl = `${videoBaseUrl}/streaming/loubiairways/cockpit_mobile/v1/Cockpit_Mobile.m3u8`;
export const stylishDemoVideoUrl = `${videoBaseUrl}/streaming/loubiairways/safety_demo_desktop/v1/LOUBI_AIRWAYS_SAFETY_DEMO_16-9.mp4.m3u8`;
export const stylishDemoVideoMobileUrl = `${videoBaseUrl}/streaming/loubiairways/safety_demo_mobile/v1/LOUBI_AIRWAYS_SAFETY_DEMO_9.16.mp4.m3u8`;
export const beautyDemoVideoUrl = `${videoBaseUrl}/streaming/loubiairways/lipstick_desktop/v1/LOUBI_AIRWAYS_LIPSTICK_16.9.mp4.m3u8`;
export const beautyDemoVideoMobileUrl = `${videoBaseUrl}/streaming/loubiairways/lipstick_mobile/v1/LOUBI_AIRWAYS_LIPSTICK_9.16.mp4.m3u8`;
export const exitVideoUrl = `${videoBaseUrl}/streaming/loubiairways/exit_desktop/v1/Exit Animation - Desktop.m3u8`;
export const exitVideoMobileUrl = `${videoBaseUrl}/streaming/loubiairways/exit_mobile/v1/Exit Animation - Mobile.m3u8`;
