import { throttle } from 'lodash';
import React from 'react';
import { useDispatch } from 'react-redux';
import { logEvent } from '../../../../../analytics';
import { assetBaseUrl } from '../../../../../config';
import {
  ILoubiAirways2dAnimationState,
  LoubiAirwaysAnimation
} from '../../../../../interfaces/loubiairways';
import { actionDidUpdateLoubiAirwaysAnimation } from '../../../../../redux/customActions/loubiAirways';
import { DID_FLIP_MAGAZINE } from '../../../../../utils/constants';
import { SliderNext, SliderPrev } from '../../../../Arrows';
import { useRemoteLoubiAirwaysAnimationState } from '../../../../hooks/loubiAirways';
import {
  AnimationContext,
  FADE_SPEED
} from '../../../../ThreejsAnimation/louboutin/AnimationContext';
import {
  animationVoyageThrottleDurationInMs,
  selectEl
} from '../animationUtils';
import ContinueFlightButton from '../Buttons/ContinueFlightButton';
import SinglePaper from './SinglePaper';

const assetPath = `${assetBaseUrl}/loubiairways/luggage-animation/omg-magazine/v5`;

const OmgMagazine = () => {
  const dispatch = useDispatch();
  const { setLoaded, onCloseAnimation } = React.useContext(AnimationContext);
  const [animateClose, setAnimateClose] = React.useState(false);
  const [flipBack, setFlipBack] = React.useState(false);
  const ref = [
    React.useRef<HTMLDivElement>(),
    React.useRef<HTMLDivElement>(),
    React.useRef<HTMLDivElement>()
  ];

  const remoteState = useRemoteLoubiAirwaysAnimationState(
    LoubiAirwaysAnimation.IN_FLIGHT_MAGAZINE
  ) as ILoubiAirways2dAnimationState;

  const throttledUpdateRemoteState = throttle(
    (state: ILoubiAirways2dAnimationState) => {
      dispatch(
        actionDidUpdateLoubiAirwaysAnimation(
          LoubiAirwaysAnimation.IN_FLIGHT_MAGAZINE,
          state
        )
      );
    },
    animationVoyageThrottleDurationInMs
  );

  const updateRemoteState = (state: ILoubiAirways2dAnimationState) => {
    throttledUpdateRemoteState(state);
  };

  const close = remoteState?.close || animateClose;
  const flip = remoteState?.flip || flipBack;

  const flipToBack = () => {
    selectEl(ref[0]).addClass('flip-to-back').removeClass('flip-to-back', 400);
    selectEl(ref[1]).removeClass('active', 400);
    selectEl(ref[2]).addClass('active', 400);

    logEvent(DID_FLIP_MAGAZINE, DID_FLIP_MAGAZINE, {
      magazine: LoubiAirwaysAnimation.OMG_MAGAZINE,
      page: 'back'
    });
  };

  const flipToCover = () => {
    selectEl(ref[0])
      .addClass('flip-to-cover')
      .removeClass('flip-to-cover', 400);
    selectEl(ref[1]).addClass('active', 400);
    selectEl(ref[2]).removeClass('active', 400);

    logEvent(DID_FLIP_MAGAZINE, DID_FLIP_MAGAZINE, {
      magazine: LoubiAirwaysAnimation.OMG_MAGAZINE,
      page: 'cover'
    });
  };

  const handleClose = () => {
    if (flip) {
      flipToCover();
      updateRemoteState({ flip: !flip });
      setTimeout(() => {
        setAnimateClose(true);
      }, 600);
    } else {
      setAnimateClose(true);
    }
  };

  const handleFlip = () => {
    setFlipBack(!flip);
    updateRemoteState({ flip: !flip });
  };

  React.useEffect(() => {
    setLoaded(true);
  }, []);

  React.useEffect(() => {
    if (flip) {
      flipToBack();
    } else {
      flipToCover();
    }
  }, [flip]);

  React.useEffect(() => {
    if (close) {
      updateRemoteState({ close: true });

      setTimeout(() => {
        updateRemoteState(undefined);
        onCloseAnimation();
      }, FADE_SPEED + 100);
    }
  }, [close]);

  const cover = `${assetPath}/cover.jpg`;

  const content = (
    <>
      <div className="content" ref={ref[0]}>
        <div className="page front active" ref={ref[1]}>
          <img src={cover} onClick={handleFlip} />
        </div>
        <div className="page back" ref={ref[2]}>
          <img src={`${assetPath}/back.jpg`} onClick={handleFlip} />
        </div>
        <style jsx>{`
          img {
            height: 100vh;
            width: auto;
            max-height: none;
          }
          .content {
            display: inline-block;
            position: relative;
            transform-style: preserve-3d;
          }

          .content.flip-to-back {
            -webkit-animation: flip-to-back 0.4s
              cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
            animation: flip-to-back 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955)
              both;
          }
          .content.flip-to-cover {
            -webkit-animation: flip-to-cover 0.4s
              cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
            animation: flip-to-cover 0.4s
              cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
          }

          .page {
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 10;
            transform: rotateY(180deg);
          }

          .active {
            transform: none;
            position: relative;
            z-index: 20;
          }

          @-webkit-keyframes flip-to-back {
            0% {
              -webkit-transform: rotateY(0);
              transform: rotateY(0);
            }
            100% {
              -webkit-transform: rotateY(180deg);
              transform: rotateY(180deg);
            }
          }
          @keyframes flip-to-back {
            0% {
              -webkit-transform: rotateY(0);
              transform: rotateY(0);
            }
            100% {
              -webkit-transform: rotateY(180deg);
              transform: rotateY(180deg);
            }
          }

          @-webkit-keyframes flip-to-cover {
            0% {
              -webkit-transform: rotateY(0);
              transform: rotateY(0);
            }
            100% {
              -webkit-transform: rotateY(-180deg);
              transform: rotateY(-180deg);
            }
          }
          @keyframes flip-to-cover {
            0% {
              -webkit-transform: rotateY(0);
              transform: rotateY(0);
            }
            100% {
              -webkit-transform: rotateY(-180deg);
              transform: rotateY(-180deg);
            }
          }
          @media (orientation: portrait) {
            img {
              height: auto !important;
              width: 100vw !important;
              max-width: calc(100vh * 0.65) !important;
              max-height: none !important;
            }
          }
        `}</style>
      </div>

      {!flipBack && !remoteState && <SliderNext onClick={handleFlip} />}
      {flipBack && !remoteState && <SliderPrev onClick={handleFlip} />}
    </>
  );
  return (
    <>
      <SinglePaper
        background={`${assetPath}/bg.jpg`}
        portraitBackground={`${assetPath}/bg-m.jpg`}
        cover={cover}
        close={close}
        delay={FADE_SPEED + 600}
        overlay={`${assetPath}/overlay.png`}
        className="omgMagazine"
      >
        {content}
      </SinglePaper>
      <ContinueFlightButton onClick={handleClose} />
      <style jsx global>
        {`
          .omgMagazine .paperNav {
            height: 100vh !important;
            width: auto !important;
            max-height: none !important;
            transform: translate(0, 0) !important;
          }

          .omgMagazine .paperNav.closing {
            width: auto !important;
            box-shadow: none !important;
          }

          .omgMagazine .overlay {
            width: 14.8vh;
            margin-top: 9.9vh;
          }

          @media (orientation: landscape) {
            .omgMagazine .slider-arrow {
              text-align: center !important;

              width: 70px;
              height: 70px;
              line-height: 70px;
            }

            .omgMagazine .slider-next {
              right: -20px !important;
              padding-left: 0 !important;
            }
            .omgMagazine .slider-prev {
              padding-right: 0 !important;
              left: -20px !important;
            }
            .omgMagazine .overlay {
              margin-left: -54.6vh;
            }
            .omgMagazine .paperNav.closing {
              transform: translate(-55.5vh, 2vh) rotate(-3deg) skew(1deg, 2deg) !important;
              height: 17.5vh !important;
            }
            .omgMagazine .paperNav.opening {
              height: 17.5vh !important;
              transform: translate(-50.8vh, -12vh) !important;
            }
          }

          @media (orientation: portrait) {
            .omgMagazine .overlay {
              width: 15vh;
              margin-left: -1vh;
              margin-top: 10.1vh;
            }
            .omgMagazine .paperNav {
              height: auto !important;
              width: 100vw !important;
              max-width: calc(100vh * 0.65) !important;
              max-height: none !important;
            }

            .omgMagazine .paperNav.opening {
              width: 15vh !important;
              height: auto !important;
              transform: translate(0, -14vh) !important;
            }

            .omgMagazine .paperNav.closing {
              height: auto !important;
              transform: translate(-2vh, 2vh) rotate(-3deg) skew(1deg, 2deg) !important;
              width: 12vh !important;
            }
          }
        `}
      </style>
    </>
  );
};

export default OmgMagazine;
