import React from 'react';
import * as THREE from 'three';
import { isUserOnMobile } from '../../../utils/deviceDetector';
import {
  IAnimationProduct,
  ILoubiAirwaysAirplaneWindowAnimationState,
  REMOTE_ACTION
} from '../../../interfaces/loubiairways';
import {
  airplaneWindowImagePath,
  getCloudImage,
  modelCloudEnvironmentImagePath,
  modelWorkshopEnvironmentImagePath,
  skyBackgroundImagePath,
  airplaneWindowProducts,
  windowTextures
} from './assets';
import { ModelEnvironment } from './background';
import BackToAnimationButton from '../../VirtualBoutique/CustomComponent/LouboutinCustomComponent/Buttons/BackToAnimationButton';

let airportWindowRemoteState = undefined;
let clickBack = false;
let backDiv;
const isMobileOrTab = isUserOnMobile();
const onClickBack = () => {
  clickBack = true;
};
const lowResModel = airplaneWindowProducts.map((p) => p.lowPolyModelUrl);
const highResModel = airplaneWindowProducts.map((p) => p.highPolyModelUrl);
const scaleSmall = 10,
  neutralScale = 1;

const AirplaneWindow = ({
  modelEnvironment,
  remoteState,
  updateRemoteState,
  didSelectModel,
  onReady,
  viewOnly
}: {
  modelEnvironment: ModelEnvironment;
  remoteState?: ILoubiAirwaysAirplaneWindowAnimationState;
  updateRemoteState: (state: ILoubiAirwaysAirplaneWindowAnimationState) => void;
  didSelectModel: (model: IAnimationProduct) => void;
  onReady: () => void;
  viewOnly?: boolean;
}) => {
  const SCL = 0.12;
  const maxScale = 16 * SCL;
  const minScale = 5 * SCL;
  const incScale = 0.5 * SCL;
  const neutralStateScale = 8 * SCL;
  const hiddenScale = 4 * SCL;
  const zoomedInScale = 10 * SCL;
  const xDiffPosition = 2.5;
  const itemsArray = Array(airplaneWindowProducts.length);
  const itemsChildForCatchingClick = Array(airplaneWindowProducts.length);
  const clouds = Array(4);
  const mouseDownPos = new THREE.Vector2();
  const mouse = new THREE.Vector2();
  const mouseMultiTouch = new THREE.Vector2();
  const raycaster = new THREE.Raycaster();
  const rayCasterScale = new THREE.Vector3(1.5, 2, 2);
  const centerItemPosition = new THREE.Vector3(0, 0.5, 0);
  const OtherItemPosition = new THREE.Vector3(0, -1.3, -2);
  const tweenTime = 800;
  const touchType = {
    touchDown: 'touchDown',
    touchMove: 'touchMove',
    touchUp: 'touchUp'
  };
  let loadingNewItem = true;
  let focusedModel = undefined;
  let zoomModel = undefined;
  let initDistance = 0;
  let initScale = 1;
  let isChangingCenterItem = false;
  let centerItemIndex = 5;
  let controls = undefined;
  let isDown = false;
  let isTweening = false;
  let transparent_Plane = undefined;
  let isMouseDown = false;
  let loadingCount = 0;

  let festividadeTextureMap, festividadeTextureNormalMap;
  let noorTextureMap, noorTextureNormalMap, noorTextureMetalMap;
  let mariaTextureMap, mariaTextureNormalMap, mariaTextureMetalMap;
  let loopingaTextureMap, loopingaTextureNormalMap, loopingaTextureMetalMap;
  let papayaTextureMap, papayaTextureMetalMap;
  let relexTextureMap, relexTextureNormalMap;
  let dandelionTextureMap, dandelionTextureMetalMap;
  let bagTextureMap, bagTextureNormalMap;

  const transparentMat = new THREE.MeshBasicMaterial({
    transparent: true,
    opacity: 0.01,
    color: 0x000000
  });
  const canvasRef = React.useRef();
  let camera, GLTFLoader, RGBELoader, TWEEN, ObjectControls, renderer;
  let scene = new THREE.Scene();
  const loadingManager = new THREE.LoadingManager(() => {
    callback();
  });
  const loadZoom = (id) => {
    if (isMobileOrTab) return;
    const gltfLoader = new GLTFLoader();
    const ItemId = id;
    gltfLoader.load(highResModel[ItemId], (gltf) => {
      const group = new THREE.Group();
      const root = gltf.scene;

      setScalePivot(root, ItemId, true);

      group.add(root);
      scene.add(group);
      group.scale.set(0.05, 0.05, 0.05);
      if (zoomModel) {
        loadNew(false, 0);
      }
      zoomModel = group;
      zoomModel.ItemId = ItemId;
      if (!focusedModel || focusedModel?.ItemId != ItemId) {
        loadNew(false, 0);
      }
    });
  };
  const cleanMaterial = (material) => {
    for (const key of Object.keys(material)) {
      const value = material[key];
      if (value && typeof value === 'object' && 'minFilter' in value) {
        value.dispose();
      }
    }
    material.dispose();
    material = undefined;
  };
  const item_Out_From_Screen = (model, _x, _y, _rx, _ry, _rz) => {
    isChangingCenterItem = true;
    model.visible = true;
    new TWEEN.Tween(model.position)
      .to(
        {
          x: _x,
          y: _y
        },
        tweenTime
      )
      .easing(TWEEN.Easing.Exponential.InOut)
      .onComplete(() => {
        loadNewItemIfRequire(model);
      })
      .start();
    new TWEEN.Tween(model.rotation)
      .to(
        {
          x: Math.PI * _rx,
          y: Math.PI * _ry,
          z: Math.PI * _rz
        },
        tweenTime
      )
      .easing(TWEEN.Easing.Exponential.InOut)
      .start();
  };

  const loadNewItemIfRequire = (model) => {
    if (itemsArray.length == 0) {
      return;
    }
    model.visible = false;
    isChangingCenterItem = false;
    if (loadingNewItem === false) {
      const leftItemFromCenter =
        (itemsArray.length + (centerItemIndex - 2)) % itemsArray.length;
      if (itemsArray[leftItemFromCenter] === undefined) {
        loadSneaker(null, leftItemFromCenter);
        loadingNewItem = true;
      }
      const rightItemFromCenter = (centerItemIndex + 2) % itemsArray.length;
      if (itemsArray[rightItemFromCenter] === undefined) {
        loadSneaker(null, rightItemFromCenter);
        loadingNewItem = true;
      }
    }
  };
  const updateNewPosition = () => {
    showObjectInCenter();
    showLeftObject();
    showRightObject();
    if (controls !== undefined) {
      controls.disableVerticalRotation();
      controls.disableHorizontalRotation();
    }
    loadNew(false, 0);
    focusedModel = undefined;
  };

  const showObjectInCenter = () => {
    if (itemsArray[centerItemIndex]) {
      resetPosition(
        itemsArray[centerItemIndex],
        neutralStateScale,
        centerItemPosition.x,
        centerItemPosition.y,
        centerItemPosition.z,
        0.0,
        0.0,
        -0.0
      );
      itemsArray[centerItemIndex].visible = true;
    }
  };

  const showLeftObject = () => {
    const leftItem =
      (centerItemIndex + itemsArray.length - 1) % itemsArray.length;
    if (itemsArray[leftItem]) {
      resetPosition(
        itemsArray[leftItem],
        hiddenScale,
        -xDiffPosition,
        OtherItemPosition.y,
        OtherItemPosition.z,
        0.0,
        0.0,
        0.1
      );
      itemsArray[leftItem].visible = true;
    }
  };

  const showRightObject = () => {
    const rightItem = (centerItemIndex + 1) % itemsArray.length;
    if (itemsArray[rightItem]) {
      resetPosition(
        itemsArray[rightItem],
        hiddenScale,
        xDiffPosition,
        OtherItemPosition.y,
        OtherItemPosition.z,
        0.0,
        0.0,
        -0.1
      );
      itemsArray[rightItem].visible = true;
    }
  };

  const loadNew = (isLoad, id) => {
    if (isLoad) {
      if (zoomModel === undefined) loadZoom(id);
    } else {
      if (zoomModel === undefined) return;
      zoomModel.traverse((object) => {
        if (!object.isMesh) return;
        scene.remove(object);
        object.geometry.dispose();
        if (object.material.isMaterial) {
          cleanMaterial(object.material);
        } else {
          for (const material of object.material) cleanMaterial(material);
        }
        object.geometry = undefined;
        object = undefined;
      });
      scene.remove(zoomModel);
      zoomModel = undefined;
    }
  };
  const updateObjectControlForModel = (model) => {
    if (
      model === undefined ||
      camera === undefined ||
      renderer === undefined ||
      viewOnly
    )
      return;
    if (!controls) {
      controls = new ObjectControls(camera, renderer.domElement, model);
    } else {
      controls.setObjectToMove(model);
    }
    controls.setDistance(2, 400);
    controls.setZoomSpeed(0.5);
    controls.enableVerticalRotation();
    controls.enableHorizontalRotation();
    controls.disableZoom();
    controls.setRotationSpeed(0.1);
  };
  const updateRemoteObjectControlForModel = () => {
    if (!focusedModel) return;
    updateRemoteState({
      flag: REMOTE_ACTION.MOUSE_CONTROL,
      displayedProductIndices: [centerItemIndex],
      position: [
        focusedModel.position.x,
        focusedModel.position.y,
        focusedModel.position.z
      ],
      scale: [focusedModel.scale.x, focusedModel.scale.y, focusedModel.scale.z],
      rotation: [
        focusedModel.rotation.x,
        focusedModel.rotation.y,
        focusedModel.rotation.z
      ]
    });
  };
  const resetPosition = (model, _scale, _x, _y, _z, _rx, _ry, _rz) => {
    isTweening = true;
    if (!model) return;
    new TWEEN.Tween(model.rotation)
      .to(
        {
          x: Math.PI * _rx,
          y: Math.PI * _ry,
          z: Math.PI * _rz
        },
        tweenTime
      )
      .easing(TWEEN.Easing.Exponential.InOut)
      .start();
    new TWEEN.Tween(model.scale)
      .to(
        {
          x: _scale,
          y: _scale,
          z: _scale
        },
        tweenTime
      )
      .easing(TWEEN.Easing.Exponential.InOut)
      .start();
    new TWEEN.Tween(model.position)
      .to(
        {
          x: _x,
          y: _y,
          z: _z
        },
        tweenTime
      )
      .onComplete(() => {
        isTweening = false;
      })
      .easing(TWEEN.Easing.Exponential.InOut)
      .start();
  };
  const detectFirstTouchedObject = (type, objects) => {
    if (mouseDownPos.x === mouse.x && mouseDownPos.y === mouse.y) {
      const objs = objects.filter((model) => model);
      const intersects = raycaster.intersectObjects(objs);
      if (intersects.length) {
        return intersects[0];
      }
    }
  };
  const onPinch = (ev) => {
    if (remoteState != undefined || itemsArray[centerItemIndex] == undefined)
      return;
    if (ev.type === 'pinchstart') {
      initScale = itemsArray[centerItemIndex].scale.x || 1;
    }
    itemsArray[centerItemIndex].scale.set(
      initScale * ev.scale,
      initScale * ev.scale,
      initScale * ev.scale
    );
    if (itemsArray[centerItemIndex].scale.x > maxScale)
      itemsArray[centerItemIndex].scale.set(maxScale, maxScale, maxScale);
    if (itemsArray[centerItemIndex].scale.x < minScale)
      itemsArray[centerItemIndex].scale.set(minScale, minScale, minScale);
    updateRemoteObjectControlForModel();
  };

  const setTouchValues = (e, type, sys) => {
    const CANVAS_HEIGHT = window.innerHeight;
    const CANVAS_WIDTH = window.innerWidth;
    if (e.touches !== null && e.touches !== undefined) {
      if (e.touches.length > 0) {
        mouse.x = (e.touches[0].pageX / window.innerWidth) * 2 - 1;
        mouse.y = -(e.touches[0].pageY / window.innerHeight) * 2 + 1;
        if (e.touches.length === 2) {
          mouseMultiTouch.x = (e.touches[1].pageX / window.innerWidth) * 2 - 1;
          mouseMultiTouch.y =
            -(e.touches[1].pageY / window.innerHeight) * 2 + 1;
          if (type === touchType.touchDown) {
            initDistance = mouseMultiTouch.distanceTo(mouse);
            onPinch({ type: 'pinchstart', scale: 1 });
          } else {
            const currentDistance = mouseMultiTouch.distanceTo(mouse);
            onPinch({
              type: 'pinchmove',
              scale: currentDistance / initDistance
            });
          }
        }
      }
    } else {
      const elem = renderer.domElement,
        boundingRect = elem.getBoundingClientRect();
      const x =
        (e.clientX - boundingRect.left) * (elem.width / boundingRect.width);
      const y =
        (e.clientY - boundingRect.top) * (elem.height / boundingRect.height);
      mouse.x = (x / CANVAS_WIDTH) * 2 - 1;
      mouse.y = -(y / CANVAS_HEIGHT) * 2 + 1;
    }
    if (type === touchType.touchDown) {
      mouseDownPos.x = mouse.x;
      mouseDownPos.y = mouse.y;
      isMouseDown = true;
      if (e.touches?.length > 1) isMouseDown = false;
    }
    if (type === touchType.touchUp) {
      isMouseDown = false;
    }
    raycaster.setFromCamera(mouse, camera);
  };

  const onwheelEvent = (event) => {
    if (remoteState != undefined || itemsArray[centerItemIndex] == undefined)
      return;
    if (focusedModel != undefined) {
      let modelScale = itemsArray[centerItemIndex].scale.x;
      if (event.deltaY > 0) {
        if (modelScale < maxScale) modelScale += incScale;
      } else {
        if (modelScale > minScale) modelScale -= incScale;
      }
      itemsArray[centerItemIndex].scale.set(modelScale, modelScale, modelScale);
      updateRemoteObjectControlForModel();
    }
  };

  const createRayCasterCatcher = () =>
    new THREE.Mesh(
      new THREE.BoxBufferGeometry(
        rayCasterScale.x,
        rayCasterScale.y,
        rayCasterScale.z
      ),
      new THREE.MeshPhongMaterial({
        transparent: false,
        opacity: 0.001
      })
    );
  const callback = () => {
    if (loadingCount === 0) {
      centerItemIndex = 5;
      itemsArray[centerItemIndex]?.scale.set(
        neutralStateScale,
        neutralStateScale,
        neutralStateScale
      );
      if (isMobileOrTab) {
        if (festividadeTextureMap?.image && festividadeTextureNormalMap?.image)
          setMaterial(
            itemsArray[0],
            festividadeTextureMap,
            festividadeTextureNormalMap,
            undefined
          );
        if (
          noorTextureMap?.image &&
          noorTextureNormalMap?.image &&
          noorTextureMetalMap?.image
        )
          setMaterial(
            itemsArray[1],
            noorTextureMap,
            noorTextureNormalMap,
            noorTextureMetalMap
          );
        if (
          mariaTextureMap?.image &&
          mariaTextureNormalMap?.image &&
          mariaTextureMetalMap?.image
        )
          setMaterial(
            itemsArray[2],
            mariaTextureMap,
            mariaTextureNormalMap,
            mariaTextureMetalMap
          );
        if (
          loopingaTextureMap?.image &&
          loopingaTextureNormalMap?.image &&
          loopingaTextureMetalMap?.image
        )
          setMaterial(
            itemsArray[3],
            loopingaTextureMap,
            loopingaTextureNormalMap,
            loopingaTextureMetalMap
          );
        if (papayaTextureMetalMap?.image)
          setMaterial(
            itemsArray[4],
            undefined,
            undefined,
            papayaTextureMetalMap
          );
        if (relexTextureNormalMap?.image)
          setMaterial(
            itemsArray[5],
            undefined,
            relexTextureNormalMap,
            undefined
          );
        if (dandelionTextureMap?.image && dandelionTextureMetalMap?.image)
          setMaterial(
            itemsArray[6],
            dandelionTextureMap,
            undefined,
            dandelionTextureMetalMap
          );
        if (bagTextureNormalMap?.image && bagTextureMap?.image)
          setMaterial(
            itemsArray[7],
            bagTextureMap,
            bagTextureNormalMap,
            undefined
          );
      }

      setPositionNewLoadedObjet();
      onReady();
    }
    loadingCount++;
  };

  const setPositionNewLoadedObjet = () => {
    loadingNewItem = false;
    if (
      itemsArray[centerItemIndex] == undefined ||
      itemsArray[centerItemIndex - 1] == undefined ||
      itemsArray[centerItemIndex + 1] == undefined
    )
      return;
    itemsArray[centerItemIndex].position.set(
      centerItemPosition.x,
      centerItemPosition.y,
      centerItemPosition.z
    );
    itemsArray[centerItemIndex].visible = true;
    itemsArray[centerItemIndex - 1].position.set(
      -xDiffPosition,
      OtherItemPosition.y,
      OtherItemPosition.z
    );
    itemsArray[centerItemIndex - 1].visible = true;
    itemsArray[centerItemIndex].rotation.set(
      Math.PI * 0.0,
      Math.PI * 0.0,
      Math.PI * 0.0
    );
    itemsArray[centerItemIndex - 1].rotation.set(
      Math.PI * 0.0,
      Math.PI * 0.0,
      Math.PI * 0.1
    );
    itemsArray[centerItemIndex + 1].rotation.set(
      Math.PI * 0.0,
      Math.PI * 0.0,
      -Math.PI * 0.1
    );

    itemsArray[centerItemIndex + 1].position.set(
      xDiffPosition,
      OtherItemPosition.y,
      OtherItemPosition.z
    );
    itemsArray[centerItemIndex + 1].visible = true;
    itemsArray[centerItemIndex - 1].scale.set(
      hiddenScale,
      hiddenScale,
      hiddenScale
    );
    itemsArray[centerItemIndex + 1].scale.set(
      hiddenScale,
      hiddenScale,
      hiddenScale
    );
  };
  const setScalePivot = (model, ItemId, isHigh) => {
    const scl = scaleSmall * neutralScale;
    model.position.set(0, -0.6, 0);
    model.scale.set(scl, scl, scl);
    model.rotation.set(0, Math.PI * 0.5, 0);
    if (ItemId == 1 || ItemId == 3 || ItemId == 4) {
      model.position.set(0, -1.0, 0);
    }
  };
  const setMaterial = (model, map, normal, matlic) => {
    if (!model) return;
    model.traverse((object) => {
      if (!object['isMesh']) return;
      if (object['material'].isMaterial) {
        const met = object['material'];
        if (met.name === 'Festividate_mat') {
          //FESTIVIDADE
          met.map.image = map.image;
          met.map.needsUpdate = true;

          met.normalMap.image = normal.image;
          met.normalMap.needsUpdate = true;
        }
        if (met.name === 'Solid_mat1') {
          //NOOR
          met.map.image = map.image;
          met.map.needsUpdate = true;

          met.metalnessMap.image = matlic.image;
          met.metalnessMap.needsUpdate = true;

          met.normalMap.image = normal.image;
          met.normalMap.needsUpdate = true;
        }

        if (met.name === 'Base_mat4') {
          //MARIE ANNE
          met.map.image = map.image;
          met.map.needsUpdate = true;

          met.metalnessMap.image = matlic.image;
          met.metalnessMap.needsUpdate = true;

          met.normalMap.image = normal.image;
          met.normalMap.needsUpdate = true;
        }

        if (met.name === 'lambert15') {
          //LOOPINGA TOE PLUME
          met.map.image = map.image;
          met.map.needsUpdate = true;

          met.metalnessMap.image = matlic.image;
          met.metalnessMap.needsUpdate = true;
        }
        if (met.name === 'Papaya100_mat2') {
          //PAPAGAYA LEVITA
          met.metalnessMap.image = matlic.image;
          met.metalnessMap.needsUpdate = true;
        }
        if (met.name === 'Relax_mat2') {
          //RELAX MAX
          met.normalMap.image = normal.image;
          met.normalMap.needsUpdate = true;
        }
        if (met.name === 'DANDELION_mat') {
          //DANDELION SPIKES
          met.map.image = map.image;
          met.map.needsUpdate = true;

          met.metalnessMap.image = matlic.image;
          met.metalnessMap.needsUpdate = true;
        }
        if (met.name === 'Baguette_01_mat1') {
          //BAGUETTE_SMALL
          met.map.image = map.image;
          met.map.needsUpdate = true;
        }
        if (met.name === 'Baguette_02_mat1') {
          //BAGUETTE_SMALL
          met.normalMap.image = normal.image;
          met.normalMap.needsUpdate = true;
        }
      }
    });
  };
  const loadSneaker = (callback, id) => {
    const group = new THREE.Group();
    const gltfLoader = new GLTFLoader(loadingManager);
    const ItemId = id;
    gltfLoader.load(lowResModel[ItemId], (gltf) => {
      if (scene != undefined) {
        const root = gltf.scene;
        setScalePivot(root, ItemId, false);
        root.rotation.set(0, Math.PI * 0.5, 0);
        itemsChildForCatchingClick[ItemId] = createRayCasterCatcher();
        itemsChildForCatchingClick[ItemId].position.set(0, 0, 0);
        group.add(itemsChildForCatchingClick[ItemId]);
        itemsChildForCatchingClick[ItemId].visible = false;
        loadingNewItem = false;
        itemsArray[ItemId] = group;
        group.add(root);
        scene.add(group);
        group.visible = true;
        group.name = airplaneWindowProducts[ItemId].modelName;
        group.scale.set(0.001, 0.001, 0.001);
        group.position.x = 4;
        group['ItemId'] = ItemId;
        if (callback) callback(id);
      }
    });
  };

  const getModelBackground = (background: ModelEnvironment) => {
    switch (background) {
      case ModelEnvironment.WORKSHOP:
        return modelWorkshopEnvironmentImagePath;
      case ModelEnvironment.CLOUD:
        return modelCloudEnvironmentImagePath;
      default:
        return null;
    }
  };

  const loadEnvironment = () => {
    if (renderer == undefined || scene == undefined) {
      return;
    }
    const textureLoader = new THREE.TextureLoader(loadingManager);
    if (isMobileOrTab) {
      bagTextureMap = textureLoader.load(windowTextures.palomaBagMap);
      bagTextureNormalMap = textureLoader.load(
        windowTextures.palomaBagNormalMap
      );

      festividadeTextureMap = textureLoader.load(windowTextures.festividadeMap);
      festividadeTextureNormalMap = textureLoader.load(
        windowTextures.festividadeNormalMap
      );

      noorTextureMap = textureLoader.load(windowTextures.noorMap);
      noorTextureMetalMap = textureLoader.load(windowTextures.noorMetalMap);
      noorTextureNormalMap = textureLoader.load(windowTextures.noorNormalMap);

      mariaTextureMap = textureLoader.load(windowTextures.mariaMap);
      mariaTextureMetalMap = textureLoader.load(windowTextures.mariaMetalMap);
      mariaTextureNormalMap = textureLoader.load(windowTextures.mariaNormalMap);

      loopingaTextureMap = textureLoader.load(windowTextures.loopingaMap);
      loopingaTextureMetalMap = textureLoader.load(
        windowTextures.loopingaMetalMap
      );
      loopingaTextureNormalMap = textureLoader.load(
        windowTextures.loopingaNormalMap
      );
      papayaTextureMetalMap = textureLoader.load(windowTextures.papayaMetalMap);
      relexTextureMap = textureLoader.load(windowTextures.relexMap);
      relexTextureNormalMap = textureLoader.load(windowTextures.relexNormalMap);

      dandelionTextureMap = textureLoader.load(windowTextures.dandelionMap);
      dandelionTextureMetalMap = textureLoader.load(
        windowTextures.dandelionMetalMap
      );
    }

    const texture = textureLoader.load(airplaneWindowImagePath);
    texture.encoding = THREE.sRGBEncoding;
    const windowSize = isMobileOrTab ? 0.98 : 1.07;
    const airplaneWindow = new THREE.Mesh(
      new THREE.PlaneGeometry(20 * windowSize, 10 * windowSize),
      new THREE.MeshBasicMaterial({ transparent: true, map: texture })
    );
    airplaneWindow.rotation.set(-Math.PI * 0.115, 0, 0);
    airplaneWindow.position.set(0, windowSize, 1);
    scene.add(airplaneWindow);

    transparent_Plane = new THREE.Mesh(
      new THREE.PlaneGeometry(22, 10),
      transparentMat
    );
    transparent_Plane.rotation.set(-Math.PI * 0.1246, 0, 0);
    transparent_Plane.position.set(0, 1.12, 2);
    scene.add(transparent_Plane);
    transparent_Plane.visible = true;
    const cloudBackground = new THREE.Mesh(
      new THREE.PlaneGeometry(40, 18),
      new THREE.MeshBasicMaterial({
        map: textureLoader.load(skyBackgroundImagePath)
      })
    );
    cloudBackground.rotation.set(-Math.PI * 0.1246, 0, 0);
    cloudBackground.position.set(0, -2, -8);
    scene.add(cloudBackground);

    for (let i = 0; i < 4; i++) {
      if (i < 2)
        clouds[i] = new THREE.Mesh(
          new THREE.PlaneGeometry(4, 2),
          new THREE.MeshBasicMaterial({
            opacity: 0.7,
            transparent: true,
            map: new THREE.TextureLoader().load(getCloudImage(i))
          })
        );
      else clouds[i] = clouds[i % 2].clone();
      scene.add(clouds[i]);
      clouds[i].position.set(-4.5 + 3 * i, -4 + i * 1.9, -5 + i * 0.71);
    }
    clouds[0].position.x = clouds[2].position.x;
    clouds[2].position.x = -4.5;

    const loader = new RGBELoader();
    loader.setDataType(THREE.UnsignedByteType);
    const modelBackgroundPath = getModelBackground(modelEnvironment);
    modelBackgroundPath &&
      loader.load(
        modelBackgroundPath,
        (texture) => {
          if (renderer && scene) {
            let pmremGenerator = new THREE.PMREMGenerator(renderer);
            pmremGenerator.compileEquirectangularShader();
            const envMap = pmremGenerator.fromEquirectangular(texture).texture;
            pmremGenerator = undefined;
            scene.environment = envMap;
          }
        },
        undefined,
        undefined
      );
  };

  const eventDown = (e) => {
    touchEvent(e, touchType.touchDown, 0);
  };
  const eventMove = (e) => {
    touchEvent(e, touchType.touchMove, 0);
  };
  const eventUp = (e) => {
    touchEvent(e, touchType.touchUp, 0);
  };
  const zoomDisable = (e) => {
    e.preventDefault();
    document.body.style['zoom'] = '1';
  };
  const addEventListeners = () => {
    document.addEventListener('mousedown', eventDown);
    document.addEventListener('touchstart', eventDown);

    document.addEventListener('mousemove', eventMove);
    document.addEventListener('touchmove', eventMove);

    document.addEventListener('mouseup', eventUp);
    document.addEventListener('touchend', eventUp);

    document.addEventListener('wheel', onwheelEvent);
    window.addEventListener('resize', onWindowResize, false);
  };

  const touchEvent = (e, type, sys) => {
    if (
      remoteState != undefined ||
      itemsArray[centerItemIndex] == undefined ||
      isTweening
    )
      return;
    setTouchValues(e, type, sys);
    if (
      isMouseDown === true &&
      loadingNewItem === false &&
      !zoomModel &&
      !focusedModel &&
      isChangingCenterItem === false
    ) {
      const scl = window.innerWidth / window.innerHeight;
      if (Math.abs(mouseDownPos.x * scl - mouse.x * scl) > 0.2) {
        const leftItem_2 =
          (itemsArray.length + (centerItemIndex - 2)) % itemsArray.length;
        const leftItem_1 =
          (itemsArray.length + (centerItemIndex - 1)) % itemsArray.length;
        const rightItem_2 = (centerItemIndex + 2) % itemsArray.length;
        const rightItem_1 = (centerItemIndex + 1) % itemsArray.length;
        if (mouseDownPos.x < mouse.x) {
          if (itemsArray[leftItem_2] !== undefined) {
            updateReceiver(REMOTE_ACTION.CLICK_LEFT, leftItem_1);
            updateRemoteState({
              selectedProduct: itemsArray[centerItemIndex].name,
              flag: REMOTE_ACTION.CLICK_LEFT,
              displayedProductIndices: [centerItemIndex]
            });
          }
        } else {
          if (itemsArray[rightItem_2] !== undefined) {
            updateReceiver(REMOTE_ACTION.CLICK_RIGHT, rightItem_1);
            updateRemoteState({
              selectedProduct: itemsArray[centerItemIndex].name,
              flag: REMOTE_ACTION.CLICK_RIGHT,
              displayedProductIndices: [centerItemIndex]
            });
          }
        }
        isMouseDown = false;
      }
    }
    if (touchType.touchDown === type) {
      isDown = true;
    }
    if (touchType.touchUp === type && isChangingCenterItem == false) {
      isDown = false;
      const clickedModel = detectFirstTouchedObject(
        type,
        itemsChildForCatchingClick
      );
      if (clickedModel && loadingNewItem === false) {
        if (
          itemsChildForCatchingClick[centerItemIndex] === clickedModel.object &&
          focusedModel === undefined
        ) {
          updateFocus(true);
          updateRemoteState({
            selectedProduct: itemsArray[centerItemIndex].name,
            flag: REMOTE_ACTION.FOCUS,
            displayedProductIndices: [centerItemIndex]
          });
        } else if (
          itemsChildForCatchingClick[centerItemIndex] === clickedModel.object &&
          focusedModel !== undefined
        ) {
          updateFocus(false);
          updateRemoteState({
            selectedProduct: itemsArray[centerItemIndex].name,
            flag: REMOTE_ACTION.UN_FOCUS,
            displayedProductIndices: [centerItemIndex]
          });
          return;
        }

        for (
          let i = 0;
          i < itemsChildForCatchingClick.length && focusedModel === undefined;
          i++
        ) {
          if (itemsChildForCatchingClick[i] === clickedModel.object) {
            const leftItem_2 =
              (itemsArray.length + (centerItemIndex - 2)) % itemsArray.length;
            const leftItem_1 =
              (itemsArray.length + (centerItemIndex - 1)) % itemsArray.length;
            const rightItem_2 = (centerItemIndex + 2) % itemsArray.length;
            const rightItem_1 = (centerItemIndex + 1) % itemsArray.length;
            if (itemsArray[leftItem_2] !== undefined && i === leftItem_1) {
              updateReceiver(REMOTE_ACTION.CLICK_LEFT, i);

              updateRemoteState({
                selectedProduct: itemsArray[centerItemIndex].name,
                flag: REMOTE_ACTION.CLICK_LEFT,
                displayedProductIndices: [centerItemIndex]
              });
            }
            if (itemsArray[rightItem_2] !== undefined && i === rightItem_1) {
              updateReceiver(REMOTE_ACTION.CLICK_RIGHT, i);

              updateRemoteState({
                selectedProduct: itemsArray[centerItemIndex].name,
                flag: REMOTE_ACTION.CLICK_RIGHT,
                displayedProductIndices: [centerItemIndex]
              });
            }
          }
        }
      }
    }
    if (focusedModel != undefined && isDown == true) {
      updateRemoteObjectControlForModel();
    }
  };
  const animate = () => {
    if (renderer == undefined || scene == undefined) {
      return;
    }
    if (
      isChangingCenterItem === false &&
      focusedModel === undefined &&
      isTweening == false
    ) {
      itemsArray
        .filter((model) => model)
        .forEach((model) => {
          if (
            model.position.x >= -xDiffPosition &&
            model.position.x <= xDiffPosition
          ) {
            model.rotation.y += 0.001;
          }
        });
    }
    clouds
      .filter((model) => model)
      .forEach((model) => {
        model.position.x += 0.003;
        if (model.position.x > 7) {
          model.position.x = -7;
          model.position.y = Math.random() * 6 - 4;
        }
      });

    if (
      zoomModel !== undefined &&
      itemsArray[centerItemIndex] !== undefined &&
      focusedModel?.ItemId === zoomModel?.ItemId
    ) {
      zoomModel.position.set(
        itemsArray[centerItemIndex].position.x,
        itemsArray[centerItemIndex].position.y,
        itemsArray[centerItemIndex].position.z
      );
      zoomModel.scale.set(
        itemsArray[centerItemIndex].scale.x,
        itemsArray[centerItemIndex].scale.y,
        itemsArray[centerItemIndex].scale.z
      );
      zoomModel.rotation.set(
        itemsArray[centerItemIndex].rotation.x,
        itemsArray[centerItemIndex].rotation.y,
        itemsArray[centerItemIndex].rotation.z
      );
      itemsArray[centerItemIndex].visible = false;
    } else {
      if (itemsArray[centerItemIndex])
        itemsArray[centerItemIndex].visible = true;
    }
    if (clickBack && !viewOnly) {
      clickBack = false;
      updateFocus(false);
      updateRemoteState({
        selectedProduct: itemsArray[centerItemIndex].name,
        flag: REMOTE_ACTION.UN_FOCUS,
        displayedProductIndices: [centerItemIndex]
      });
    }
    if (loadingCount > 0) loadingCount++;

    if (
      airportWindowRemoteState?.displayedProductIndices?.length &&
      airportWindowRemoteState?.flag &&
      itemsArray[centerItemIndex] &&
      loadingCount > 10
    ) {
      if (
        airportWindowRemoteState.flag == REMOTE_ACTION.CLICK_LEFT ||
        airportWindowRemoteState.flag == REMOTE_ACTION.CLICK_RIGHT
      )
        updateReceiver(
          airportWindowRemoteState.flag,
          airportWindowRemoteState.displayedProductIndices[0]
        );
      if (airportWindowRemoteState.flag == REMOTE_ACTION.FOCUS) {
        if (
          airportWindowRemoteState?.displayedProductIndices[0] &&
          airportWindowRemoteState?.displayedProductIndices[0] !=
            centerItemIndex
        )
          setCenter(airportWindowRemoteState.displayedProductIndices[0]);
        updateFocus(true);
      }
      if (airportWindowRemoteState.flag == REMOTE_ACTION.UN_FOCUS) {
        if (
          airportWindowRemoteState?.displayedProductIndices[0] &&
          airportWindowRemoteState?.displayedProductIndices[0] !=
            centerItemIndex
        )
          setCenter(airportWindowRemoteState.displayedProductIndices[0]);
        updateFocus(false);
      }
      if (airportWindowRemoteState.flag == REMOTE_ACTION.MOUSE_CONTROL) {
        if (
          airportWindowRemoteState?.displayedProductIndices[0] &&
          airportWindowRemoteState?.displayedProductIndices[0] !=
            centerItemIndex
        )
          setCenter(airportWindowRemoteState.displayedProductIndices[0]);
        if (itemsArray[centerItemIndex]) {
          itemsArray[centerItemIndex].position.set(
            airportWindowRemoteState.position[0],
            airportWindowRemoteState.position[1],
            airportWindowRemoteState.position[2]
          );
          itemsArray[centerItemIndex].scale.set(
            airportWindowRemoteState.scale[0],
            airportWindowRemoteState.scale[1],
            airportWindowRemoteState.scale[2]
          );
          itemsArray[centerItemIndex].rotation.set(
            airportWindowRemoteState.rotation[0],
            airportWindowRemoteState.rotation[1],
            airportWindowRemoteState.rotation[2]
          );
        }
      }
      airportWindowRemoteState.displayedProductIndices.length = 0;
      airportWindowRemoteState.flag = undefined;
      airportWindowRemoteState = undefined;
    }

    TWEEN.update();
    renderer.render(scene, camera);
    requestAnimationFrame(animate);
  };

  const onWindowResize = () => {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(window.innerWidth, window.innerHeight);
  };

  const loadThreeJsModulesAsync = async () => {
    GLTFLoader = (await import('three/examples/jsm/loaders/GLTFLoader'))
      .GLTFLoader;
    RGBELoader = (await import('three/examples/jsm/loaders/RGBELoader'))
      .RGBELoader;
    TWEEN = (await import('three/examples/jsm/libs/tween.module.min.js')).TWEEN;
    ObjectControls = (await import('../common/ObjectControls')).ObjectControls;
  };

  const cleanTexture = (value) => {
    if (value && typeof value === 'object' && 'minFilter' in value) {
      value.dispose();
    }
    value = undefined;
    return undefined;
  };

  const cleanUp = () => {
    itemsArray.length = 0;
    itemsChildForCatchingClick.length = 0;
    clouds.length = 0;
    if (scene != undefined) {
      scene.traverse((object) => {
        if (!object['isMesh']) return;
        object['geometry'].dispose();
        if (object['material'].isMaterial) {
          cleanMaterial(object['material']);
        } else {
          // an array of materials
          for (const material of object['material']) cleanMaterial(material);
        }
        object['geometry'] = undefined;
        object = undefined;
      });
      scene.children.forEach((model) => {
        scene.remove(model);
      });
    }
    {
      festividadeTextureMap = cleanTexture(festividadeTextureMap);
      festividadeTextureNormalMap = cleanTexture(festividadeTextureNormalMap);
      noorTextureMap = cleanTexture(noorTextureMap);
      noorTextureNormalMap = cleanTexture(noorTextureNormalMap);
      noorTextureMetalMap = cleanTexture(noorTextureMetalMap);
      mariaTextureMap = cleanTexture(mariaTextureMap);
      mariaTextureNormalMap = cleanTexture(mariaTextureNormalMap);
      mariaTextureMetalMap = cleanTexture(mariaTextureMetalMap);
      loopingaTextureMap = cleanTexture(loopingaTextureMap);
      loopingaTextureNormalMap = cleanTexture(loopingaTextureNormalMap);
      loopingaTextureMetalMap = cleanTexture(loopingaTextureMetalMap);
      papayaTextureMap = cleanTexture(papayaTextureMap);
      papayaTextureMetalMap = cleanTexture(papayaTextureMetalMap);
      relexTextureMap = cleanTexture(relexTextureMap);
      relexTextureNormalMap = cleanTexture(relexTextureNormalMap);
      dandelionTextureMap = cleanTexture(dandelionTextureMap);
      dandelionTextureMetalMap = cleanTexture(dandelionTextureMetalMap);
      bagTextureMap = cleanTexture(bagTextureMap);
      bagTextureNormalMap = cleanTexture(bagTextureNormalMap);
    }
    if (renderer != undefined) {
      renderer.dispose();
      renderer && renderer.renderLists.dispose();
    }
    if (controls) {
      controls.disableVerticalRotation();
      controls.disableHorizontalRotation();
      controls.disableZoom();
    }
    document.removeEventListener('touchstart', eventDown);
    document.removeEventListener('touchmove', eventMove);
    document.removeEventListener('touchend', eventUp);
    document.removeEventListener('mousedown', eventDown);
    document.removeEventListener('mousemove', eventMove);
    document.removeEventListener('mouseup', eventUp);
    document.removeEventListener('wheel', onwheelEvent);
    window.removeEventListener('resize', onWindowResize);
    document.removeEventListener('gesturestart', zoomDisable);
    document.removeEventListener('gesturechange', zoomDisable);
    document.removeEventListener('gestureend', zoomDisable);
    controls = undefined;
    scene = undefined;
    renderer = undefined;
    camera = undefined;
  };

  const findProductByName = (name) =>
    airplaneWindowProducts.find((p) => p.modelName === name);

  const updateFocus = (isFocus) => {
    if (itemsArray[centerItemIndex] === undefined) return;
    if (isFocus == true) {
      setCenter(centerItemIndex);
      focusedModel = itemsArray[centerItemIndex];
      resetPosition(
        itemsArray[centerItemIndex],
        zoomedInScale,
        0,
        3,
        5,
        0,
        0.0,
        0
      );
      updateObjectControlForModel(itemsArray[centerItemIndex]);
      didSelectModel(findProductByName(itemsArray[centerItemIndex].name));
      backDiv.style.display = 'block';
    } else {
      backDiv.style.display = 'none';
      focusedModel = undefined;
      if (controls) {
        controls.disableVerticalRotation();
        controls.disableHorizontalRotation();
      }
      resetPosition(
        itemsArray[centerItemIndex],
        neutralStateScale,
        centerItemPosition.x,
        centerItemPosition.y,
        centerItemPosition.z,
        0,
        0,
        0
      );
      didSelectModel(undefined);
    }
    const foc = isFocus;
    transparent_Plane.visible = true;
    transparentMat.opacity = foc == true ? 0.01 : 0.7;

    new TWEEN.Tween(transparentMat)
      .to({ opacity: foc === true ? 0.7 : 0.01 }, tweenTime)
      .easing(TWEEN.Easing.Exponential.InOut)
      .onComplete(() => {
        transparent_Plane.visible = foc;
      })
      .start();

    loadNew(isFocus, centerItemIndex);
  };

  const setCenter = (center) => {
    if (!itemsArray[center]) return;
    centerItemIndex = center;
    itemsArray
      .filter((model) => model)
      .forEach((model) => {
        model.visible = false;
      });
    const leftItem = (itemsArray.length + (center - 1)) % itemsArray.length;
    const rightItem = (center + 1) % itemsArray.length;
    itemsArray[center].position.set(
      centerItemPosition.x,
      centerItemPosition.y,
      centerItemPosition.z
    );
    itemsArray[center].scale.set(
      neutralStateScale,
      neutralStateScale,
      neutralStateScale
    );
    itemsArray[leftItem].position.set(
      -xDiffPosition,
      OtherItemPosition.y,
      OtherItemPosition.z
    );
    itemsArray[leftItem].scale.set(hiddenScale, hiddenScale, hiddenScale);
    itemsArray[rightItem].position.set(
      xDiffPosition,
      OtherItemPosition.y,
      OtherItemPosition.z
    );
    itemsArray[rightItem].scale.set(hiddenScale, hiddenScale, hiddenScale);
    itemsArray[center].visible =
      itemsArray[leftItem].visible =
      itemsArray[rightItem].visible =
        true;
  };

  const updateReceiver = (action, newCenter) => {
    itemsArray
      .filter((model) => model)
      .forEach((model) => {
        model.visible = false;
      });

    if (itemsArray[newCenter] === undefined) return;
    const leftItem_2 =
      (itemsArray.length + (newCenter - 2)) % itemsArray.length;
    const leftItem_1 =
      (itemsArray.length + (newCenter - 1)) % itemsArray.length;
    const rightItem_2 = (newCenter + 2) % itemsArray.length;
    const rightItem_1 = (newCenter + 1) % itemsArray.length;
    if (
      action == REMOTE_ACTION.CLICK_LEFT &&
      itemsArray[rightItem_2] !== undefined &&
      itemsArray[leftItem_1] !== undefined
    ) {
      item_Out_From_Screen(
        itemsArray[rightItem_2],
        2 * xDiffPosition,
        OtherItemPosition.y - 1,
        0.0,
        0.0,
        -0.1
      );
      itemsArray[leftItem_1].rotation.set(
        Math.PI * 0.0,
        Math.PI * 0.0,
        Math.PI * 0.2
      );
      itemsArray[leftItem_1].position.set(
        -2 * xDiffPosition,
        OtherItemPosition.y - 1,
        OtherItemPosition.z
      );
    }
    if (
      action == REMOTE_ACTION.CLICK_RIGHT &&
      itemsArray[leftItem_2] !== undefined &&
      itemsArray[rightItem_1] !== undefined
    ) {
      item_Out_From_Screen(
        itemsArray[leftItem_2],
        -2 * xDiffPosition,
        OtherItemPosition.y - 1,
        0.0,
        0.0,
        -0.1
      );
      itemsArray[rightItem_1].rotation.set(
        Math.PI * 0.0,
        Math.PI * 0.0,
        -Math.PI * 0.2
      );
      itemsArray[rightItem_1].position.set(
        2 * xDiffPosition,
        OtherItemPosition.y - 1,
        OtherItemPosition.z
      );
    }
    centerItemIndex = newCenter;
    updateNewPosition();
  };

  React.useEffect(() => {
    if (remoteState?.displayedProductIndices?.length) {
      airportWindowRemoteState = remoteState;
    }
  }, [remoteState]);

  React.useEffect(() => {
    const canvas = document.querySelector('#c') as HTMLCanvasElement;
    backDiv = document.getElementById('topright');
    backDiv.style.display = 'none';
    camera = new THREE.PerspectiveCamera(
      45,
      window.innerWidth / window.innerHeight,
      0.1,
      30
    );
    renderer = new THREE.WebGLRenderer({
      canvas,
      antialias: true,
      alpha: true
    });

    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setClearColor(0x000000, 0);
    renderer.outputEncoding = THREE.sRGBEncoding;

    camera.position.set(0, 5, 12);
    camera.lookAt(new THREE.Vector3(0, 0.5, 0));
    loadingCount = 0;

    if (isMobileOrTab) {
      document.addEventListener('gesturestart', zoomDisable);
      document.addEventListener('gesturechange', zoomDisable);
      document.addEventListener('gestureend', zoomDisable);
    }

    loadThreeJsModulesAsync().then(() => {
      loadEnvironment();
      for (let i = 0; i < airplaneWindowProducts.length; i++) {
        loadSneaker(null, i);
      }
      if (!viewOnly) {
        addEventListeners();
      }
      animate();
    });

    return () => {
      cleanUp();
    };
  }, []);

  return (
    <div>
      <canvas id="c" ref={canvasRef}></canvas>
      <p id="topright">
        <BackToAnimationButton onClick={onClickBack} />
      </p>

      <style jsx>
        {`
          #c {
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
          }
          a {
            position: fixed;
            top: 2vh;
            left: 2vh;
            font-size: 0.81em;
            text-align: left;
          }
        `}
      </style>
    </div>
  );
};

export default AirplaneWindow;
