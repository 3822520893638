import React from 'react';
import { logEvent } from '../../../../../analytics';
import { zIndex } from '../../../../../config';
import { DID_CLICK_CONTINUE_FLIGHT_BUTTON } from '../../../../../utils/constants';
import { AnimationContext } from '../../../../ThreejsAnimation/louboutin/AnimationContext';
import LoubiButtonWrapper from './LoubiButtonWrapper';

const ContinueFlightButton = ({
  onClick,
  theme = 'default'
}: {
  onClick: () => void;
  theme?: 'default' | 'red';
}) => {
  const { selectedModel, setSelectedModel } =
    React.useContext(AnimationContext) || {};
  if (selectedModel) {
    return null;
  }
  return (
    <LoubiButtonWrapper>
      <img
        src={
          theme === 'red'
            ? '/asset/louboutin/back_to_plane_red.svg'
            : '/asset/louboutin/back_to_plane.svg'
        }
        onClick={() => {
          if (setSelectedModel) {
            setSelectedModel(undefined);
          }
          onClick();
          logEvent(DID_CLICK_CONTINUE_FLIGHT_BUTTON);
        }}
        className="loubi-circle-button top-right hide-for-client-meeting"
      />

      <style jsx>{`
        img {
          z-index: ${zIndex.meetingControls + 2};
        }
      `}</style>
    </LoubiButtonWrapper>
  );
};

export default ContinueFlightButton;
