import React from 'react';
import * as THREE from 'three';
import { assetBaseUrl } from '../../../config';
import {
  isUserOnMobileOnly,
  isUserOnMobile
} from '../../../utils/deviceDetector';
import {
  IAnimationProduct,
  ILoubiAirwaysTarotCardAnimationState,
  REMOTE_ACTION
} from '../../../interfaces/loubiairways';
import {
  tarotProducts,
  modelWorkshopEnvironmentImagePath,
  cardTopImage,
  tarotCardImage,
  tarotTextures
} from './assets';
import BackToAnimationButton from '../../VirtualBoutique/CustomComponent/LouboutinCustomComponent/Buttons/BackToAnimationButton';
let tarotCardRemoteState = undefined;
let clickBack = false;
let backDiv;
const isMobile = isUserOnMobileOnly();
const isMobileOrTab = isUserOnMobile();
const onClickBack = () => {
  clickBack = true;
};
const TarotCard = ({
  remoteState,
  updateRemoteState,
  didSelectModel,
  onReady,
  viewOnly
}: {
  remoteState?: ILoubiAirwaysTarotCardAnimationState;
  updateRemoteState: (state: ILoubiAirwaysTarotCardAnimationState) => void;
  didSelectModel: (model: IAnimationProduct) => void;
  onReady: () => void;
  viewOnly?: boolean;
}) => {
  const SCL = 4;
  const maxScale = 1.5 * SCL;
  const minScale = 0.8 * SCL;
  const incScale = 0.1 * SCL;
  const ZoomIn = 1.5;
  let ZoomOut = 4;
  const rayCasterScale = new THREE.Vector3(0.28, 0.001, 0.54);
  const fucusObjectPosition = new THREE.Vector3(0, 0, 0);
  let isTweening = false;
  let focusedModel = undefined;
  let animationOnFocus = false;
  let initScale = 1;
  let selectedItem = -1;
  let controls = undefined;
  let initDistance = 0;
  let textureTop;
  let zoomModel = undefined;
  let loadingCount = 0;

  let elisaTextureMap, elisaTextureNormalMap;
  let elisaTop2TextureMap, elisaTop2TextureNormalMap;
  let elisaBlueTextureMap, elisaBlueTextureNormalMap;
  let elisaTopTextureMap, elisaTopTextureNormalMap;
  let irizaTextureMap, irizaTextureNormalMap;
  let sandaleTextureMap, sandaleTextureNormalMap;
  let caracabaTextureMap, caracabaTextureNormalMap;
  let noLimitTextureMap, noLimitTextureNormalMap;
  let officialitoTextureMap, officialitoTextureNormalMap;

  const itemsArray = Array(tarotCardImage.length);
  const itemsChildForCatchingClick = Array(tarotCardImage.length);
  const itemsChildForCatchingClick2 = Array(tarotCardImage.length);
  const touchType = {
    touchDown: 'touchDown',
    touchMove: 'touchMove',
    touchUp: 'touchUp'
  };
  const color = 0xffffff;

  const mouse = new THREE.Vector2();
  const mouseDown = new THREE.Vector2();
  const mouse_2 = new THREE.Vector2();
  const mouseDownPos = new THREE.Vector2();
  const raycaster = new THREE.Raycaster();
  const canvasRef = React.useRef<HTMLCanvasElement>();
  let isDown = false;
  let camera, GLTFLoader, RGBELoader, TWEEN, ObjectControls, renderer;
  let scene = new THREE.Scene();
  let delta = 0;
  const clock = new THREE.Clock();
  const loadingManager = new THREE.LoadingManager(() => {
    callback();
  });
  let transparent_Plane = undefined;
  const transparentMat = new THREE.MeshBasicMaterial({
    transparent: true,
    opacity: 0.01,
    color: 0x000000
  });
  const loadZoom = (id) => {
    if (isMobileOrTab) return;
    const gltfLoader = new GLTFLoader();
    const ItemId = id;
    gltfLoader.load(tarotProducts[ItemId].highPolyModelUrl, (gltf) => {
      const group = new THREE.Group();
      const root = gltf.scene;
      setScalePivot(root, ItemId, true);
      group.add(root);
      if (scene) scene.add(group);
      group.scale.set(0.05, 0.05, 0.05);
      if (zoomModel) {
        loadNew(false, 0);
      }
      zoomModel = group;
      zoomModel.ItemId = ItemId;
      if (!focusedModel || focusedModel?.ItemId != ItemId) {
        loadNew(false, 0);
      }
    });
  };
  const loadNew = (isLoad, id) => {
    if (isLoad) {
      if (zoomModel === undefined) loadZoom(id);
    } else {
      if (zoomModel === undefined) return;
      zoomModel.traverse((object) => {
        if (!object.isMesh) return;
        scene.remove(object);
        object.geometry.dispose();
        if (object.material.isMaterial) {
          cleanMaterial(object.material);
        } else {
          // an array of materials
          for (const material of object.material) cleanMaterial(material);
        }
        object.geometry = undefined;
        object = undefined;
      });
      scene.remove(zoomModel);
      zoomModel = undefined;
    }
  };
  function TextureSingle(
    textureB,
    tilesHoriz,
    tilesVert,
    numTiles,
    tileDispDuration,
    check
  ) {
    this.tilesHorizontal = tilesHoriz;
    this.tilesVertical = tilesVert;
    this.numberOfTiles = numTiles;
    this.checkk = check;
    textureB.wrapS = textureB.wrapT = THREE.RepeatWrapping;
    textureB.repeat.set(1 / this.tilesHorizontal, 1 / this.tilesVertical);
    this.tileDisplayDuration = tileDispDuration;
    this.currentDisplayTime = 0;
    this.currentTile = -1;
    this.update = function (milliSec) {
      this.currentDisplayTime += milliSec;
      while (this.currentDisplayTime > this.tileDisplayDuration) {
        this.currentDisplayTime -= this.tileDisplayDuration;
        this.currentTile++;
        if (
          this.currentTile == this.numberOfTiles ||
          this.currentTile >= this.checkk
        ) {
          this.currentTile = 0;
        }
        const newone = this.numberOfTiles - this.currentTile - 1;
        const currentColumn = this.currentTile % this.tilesHorizontal;
        textureB.offset.x = currentColumn / this.tilesHorizontal;
        const currentRow = Math.floor(newone / this.tilesHorizontal);
        textureB.offset.y = currentRow / this.tilesVertical;
      }
    };
    this.reset = function (tile) {
      this.currentTile = tile || 0;
      const newone = this.numberOfTiles - this.currentTile - 1;
      const currentColumn = this.currentTile % this.tilesHorizontal;
      textureB.offset.x = currentColumn / this.tilesHorizontal;
      const currentRow = Math.floor(newone / this.tilesHorizontal);
      textureB.offset.y = currentRow / this.tilesVertical;
    };
  }
  const updateObjectControlForModel = (model) => {
    if (
      viewOnly ||
      model === undefined ||
      camera === undefined ||
      renderer === undefined ||
      viewOnly
    )
      return;
    if (!controls) {
      controls = new ObjectControls(camera, renderer.domElement, model);
    } else {
      controls.setObjectToMove(model);
    }
    controls.setDistance(2, 400); // set min - max distance for zoom
    controls.setZoomSpeed(0.5); // set zoom speed
    controls.enableVerticalRotation();
    controls.enableHorizontalRotation();
    controls.disableZoom();
    controls.setRotationSpeed(0.1);
  };
  const updateRemoteObjectControlForModel = () => {
    if (!focusedModel) return;
    updateRemoteState({
      flag: REMOTE_ACTION.MOUSE_CONTROL,
      focusItem: selectedItem,
      position: [
        focusedModel.position.x,
        focusedModel.position.y,
        focusedModel.position.z
      ],
      scale: [focusedModel.scale.x, focusedModel.scale.y, focusedModel.scale.z],
      rotation: [
        focusedModel.rotation.x,
        focusedModel.rotation.y,
        focusedModel.rotation.z
      ]
    });
  };
  const resetPositionForward = (model, _scale, rotX) => {
    if (!model) return;
    isTweening = true;
    focusedModel = model;
    transparent_Plane.visible = true;
    transparentMat.opacity = 0.01;
    animationOnFocus = false;
    new TWEEN.Tween(transparentMat)
      .to({ opacity: 0.7 }, 2000)
      .easing(TWEEN.Easing.Exponential.InOut)
      .start();

    new TWEEN.Tween(model.position)
      .to(
        {
          x: 0,
          y: -0.1,
          z: fucusObjectPosition.z + 5
        },
        1000
      )
      .easing(TWEEN.Easing.Quadratic.InOut)
      .onComplete(() => {
        resetZoomForward();
      })
      .start();

    new TWEEN.Tween(model.rotation)
      .to({ x: -Math.PI * 0.5, y: 0, z: 0 }, 1000)
      .easing(TWEEN.Easing.Quadratic.InOut)
      .start();
  };
  const resetZoomForward = () => {
    if (!focusedModel) return;
    new TWEEN.Tween(focusedModel.position)
      .to(
        {
          x: 0,
          y: -0.1,
          z: fucusObjectPosition.z + 6
        },
        2000
      )
      .easing(TWEEN.Easing.Quadratic.InOut)
      .onComplete(() => {
        resetRotForward();
      })
      .start();
  };
  const resetRotForward = () => {
    if (!focusedModel?.getObjectByName('root')) return;
    animationOnFocus = true;
    focusedModel.getObjectByName('root').visible = true;
    new TWEEN.Tween(focusedModel.rotation)
      .to(
        {
          x: Math.PI * 0.05,
          y: -Math.PI * 0.5,
          z: 0
        },
        1000
      )
      .easing(TWEEN.Easing.Quadratic.InOut)
      .start();

    new TWEEN.Tween(focusedModel.position)
      .to(
        {
          x: 0,
          y: -0.5,
          z: fucusObjectPosition.z + 6
        },
        1000
      )
      .easing(TWEEN.Easing.Quadratic.InOut)
      .onComplete(() => {
        updateObjectControlForModel(focusedModel);
        isTweening = false;
      })
      .start();
  };
  const ComeGoBack = () => {
    if (!focusedModel?.getObjectByName('root')) return;
    loadNew(false, selectedItem);
    isTweening = true;
    const model = focusedModel;
    didSelectModel(undefined);
    new TWEEN.Tween(model.position)
      .to(
        {
          x: model.startPostion.x,
          y: model.startPostion.y,
          z: model.startPostion.z
        },
        1000
      )
      .easing(TWEEN.Easing.Exponential.InOut)
      .onComplete(() => {
        isTweening = false;
        if (model.getObjectByName('root'))
          model.getObjectByName('root').visible = false;
      })
      .start();
    new TWEEN.Tween(model.rotation)
      .to(
        {
          x: model.startRotation.x,
          y: model.startRotation.y,
          z: model.startRotation.z
        },
        1000
      )
      .easing(TWEEN.Easing.Exponential.In)
      .start();
    new TWEEN.Tween(model.scale)
      .to(
        {
          x: ZoomOut,
          y: ZoomOut,
          z: ZoomOut
        },
        1000
      )
      .easing(TWEEN.Easing.Exponential.InOut)
      .start();

    new TWEEN.Tween(transparentMat)
      .to({ opacity: 0.01 }, 900)
      .easing(TWEEN.Easing.Exponential.InOut)
      .onComplete(() => {
        transparent_Plane.visible = false;
      })
      .start();
  };
  const createRayCasterCatcher = () =>
    new THREE.Mesh(
      new THREE.BoxBufferGeometry(rayCasterScale.x, 0.2, 0.3),
      new THREE.MeshPhongMaterial({})
    );
  const createCardRayCastCatcher = (id) => {
    const texture = new THREE.TextureLoader(loadingManager).load(
      tarotCardImage[id].url
    );
    texture.encoding = THREE.sRGBEncoding;
    const tiles = new TextureSingle(
      texture,
      tarotCardImage[id].row,
      tarotCardImage[id].col,
      tarotCardImage[id].row * tarotCardImage[id].col,
      200,
      tarotCardImage[id].frames
    );
    const materialArray = [];
    materialArray.push(new THREE.MeshBasicMaterial({ color: 0xffffff }));
    materialArray.push(new THREE.MeshBasicMaterial({ color: 0xffffff }));
    materialArray.push(new THREE.MeshBasicMaterial({ map: textureTop }));
    materialArray.push(new THREE.MeshBasicMaterial({ map: texture }));
    materialArray.push(new THREE.MeshBasicMaterial({ color: 0xffffff }));
    materialArray.push(new THREE.MeshBasicMaterial({ color: 0xffffff }));
    const mesh = new THREE.Mesh(
      new THREE.BoxBufferGeometry(
        rayCasterScale.x,
        rayCasterScale.y,
        rayCasterScale.z
      ),
      materialArray
    );
    mesh['tiles'] = tiles;
    return mesh;
  };
  const setScalePivot = (model, ItemId, isHigh) => {
    if (!model) return;
    model.rotation.set(0, 0, 0);
    if (ItemId != 4 && ItemId != 5 && ItemId != 7) {
      model.rotation.set(0, Math.PI * 0.5, 0);
    }
    model.scale.set(ZoomIn, ZoomIn, ZoomIn);
    if (ItemId == 0 || ItemId == 2) {
      model.scale.set(ZoomIn * 0.1, ZoomIn * 0.1, ZoomIn * 0.1);
    }
    if (ItemId == 6) {
      model.scale.set(ZoomIn * 0.4, ZoomIn * 0.4, ZoomIn * 0.4);
    }
    if (ItemId == 8) {
      model.position.set(0, 0.0, -0.034);
    }
    model.name = 'root';
  };
  const loadSneaker = (callback, id) => {
    const ItemId = id;
    const gltfLoader = new GLTFLoader(loadingManager);
    gltfLoader.load(tarotProducts[ItemId].lowPolyModelUrl, (gltf) => {
      const group = new THREE.Group();
      const root = gltf.scene;
      setScalePivot(root, ItemId, false);
      if (ItemId == 8) {
        root.scale.set(ZoomIn * 0.1, ZoomIn * 0.1, ZoomIn * 0.1);
      }
      root.visible = true;
      itemsChildForCatchingClick[ItemId] = createCardRayCastCatcher(ItemId);
      itemsChildForCatchingClick2[ItemId] = createRayCasterCatcher();
      itemsChildForCatchingClick2[ItemId].position.set(0, 0.1, 0);
      itemsChildForCatchingClick2[ItemId].visible = false;
      itemsChildForCatchingClick[ItemId].position.set(0, -0.01, 0);
      itemsArray[ItemId] = group;
      group.name = tarotProducts[ItemId].modelName; // + id;
      group.add(itemsChildForCatchingClick[ItemId]);
      group.add(itemsChildForCatchingClick2[ItemId]);
      group.add(root);
      group['ItemId'] = ItemId;
      scene.add(group);
      group.scale.set(ZoomOut, ZoomOut, ZoomOut);
      group.scale.set(0.001, 0.001, 0.001);
      group.visible = true;
      if (callback) callback(id);
    });
  };
  const setMaterial = (model, map, normal, matlic) => {
    if (!model) return;
    model.traverse((object) => {
      if (!object['isMesh']) return;
      if (object['material'].isMaterial) {
        const met = object['material'];
        if (met.name === 'Mexicaba_mat' && model?.name === 'ELISA MINI') {
          //Mexicaba_mat
          met.map.image = map.image;
          met.map.needsUpdate = true;

          met.normalMap.image = normal.image;
          met.normalMap.needsUpdate = true;
        }
        if (met.name === 'ElisaTopHandle_mat') {
          //ElisaTopHandle_mat
          met.map.image = map.image;
          met.map.needsUpdate = true;

          met.normalMap.image = normal.image;
          met.normalMap.needsUpdate = true;
        }

        if (met.name === 'Mexicaba_mat' && model?.name === 'ELISA SMALL') {
          //ELISA SMALL
          met.map.image = map.image;
          met.map.needsUpdate = true;

          met.normalMap.image = normal.image;
          met.normalMap.needsUpdate = true;
        }

        if (met.name === 'lambert3') {
          //Elisa to handle nano
          met.map.image = map.image;
          met.map.needsUpdate = true;

          met.normalMap.image = normal.image;
          met.normalMap.needsUpdate = true;
        }
        if (met.name === 'Iriza_100_mat') {
          //Iriza
          met.map.image = map.image;
          met.map.needsUpdate = true;

          met.normalMap.image = normal.image;
          met.normalMap.needsUpdate = true;
        }
        if (met.name === 'Desert_mat') {
          //SANDALE_DU_DESERT_ALTA_130
          met.map.image = map.image;
          met.map.needsUpdate = true;

          met.normalMap.image = normal.image;
          met.normalMap.needsUpdate = true;
        }
        if (met.name === 'lambert2') {
          //CARACABA_SMALL
          met.map.image = map.image;
          met.map.needsUpdate = true;

          met.normalMap.image = normal.image;
          met.normalMap.needsUpdate = true;
        }
        if (met.name === 'lambert14') {
          //noLimit
          met.map.image = map.image;
          met.map.needsUpdate = true;

          met.normalMap.image = normal.image;
          met.normalMap.needsUpdate = true;
        }
        if (met.name === 'Officialito_mat') {
          //Officialito_mat
          met.map.image = map.image;
          met.map.needsUpdate = true;

          // met.normalMap.image = normal.image;
          // met.normalMap.needsUpdate = true;
        }
      }
    });
  };
  const callback = () => {
    loadingCount++;
    selectedItem = -1;
    for (let i = 0; i < itemsArray.length; i++) {
      if (itemsArray[i] != undefined) {
        itemsArray[i].scale.set(ZoomOut, ZoomOut, ZoomOut);
        if (itemsArray[i].getObjectByName('root'))
          itemsArray[i].getObjectByName('root').visible = false;
        if (isMobile) {
          ZoomOut = 3;
          itemsArray[i].scale.set(ZoomOut, ZoomOut, ZoomOut);
          if (i < 4) {
            itemsArray[i].position.x = 0;
            itemsArray[i].position.y = -2.5 + i * 1.0;
            itemsArray[i].position.z = 0;
            itemsArray[i].rotation.set(-Math.PI * 0.5, -Math.PI * 0.5, 0);
          } else {
            const radian = (Math.PI / 180) * (-104 + (i - 4) * 52); //(i * 90);
            itemsArray[i].position.set(
              Math.sin(radian) * 1.8,
              0.33 + Math.cos(radian) * 1.8,
              0
            );
            itemsArray[i].rotation.set(-Math.PI * 0.5, radian, 0);
          }
        } else {
          if (i < 4) {
            itemsArray[i].position.x = -2.7 + i * 1.32;
            itemsArray[i].position.y = 0;
            itemsArray[i].position.z = 0;
            itemsArray[i].rotation.set(-Math.PI * 0.5, 0, 0);
          } else {
            const radian = (Math.PI / 180) * (-14 + (i - 4) * 52); //(i * 90);
            itemsArray[i].position.set(
              0.5 + Math.sin(radian) * 2.8,
              Math.cos(radian) * 2.8,
              0
            );
            itemsArray[i].rotation.set(-Math.PI * 0.5, radian, 0);
          }
        }
        itemsArray[i].visible = true;
        itemsArray[i]['startPostion'] = {
          x: itemsArray[i].position.x,
          y: itemsArray[i].position.y,
          z: itemsArray[i].position.z
        };
        itemsArray[i]['startRotation'] = {
          x: itemsArray[i].rotation.x,
          y: itemsArray[i].rotation.y,
          z: itemsArray[i].rotation.z
        };
      }
    }

    if (isMobileOrTab) {
      if (elisaTextureMap?.image && elisaTextureNormalMap?.image)
        setMaterial(
          itemsArray[0],
          elisaTextureMap,
          elisaTextureNormalMap,
          undefined
        );
      if (elisaTop2TextureMap?.image && elisaTop2TextureNormalMap?.image)
        setMaterial(
          itemsArray[1],
          elisaTop2TextureMap,
          elisaTop2TextureNormalMap,
          undefined
        );
      if (elisaBlueTextureMap?.image && elisaBlueTextureNormalMap?.image)
        setMaterial(
          itemsArray[2],
          elisaBlueTextureMap,
          elisaBlueTextureNormalMap,
          undefined
        );
      if (elisaTopTextureMap?.image && elisaTopTextureNormalMap?.image)
        setMaterial(
          itemsArray[3],
          elisaTopTextureMap,
          elisaTopTextureNormalMap,
          undefined
        );
      if (irizaTextureMap?.image && irizaTextureNormalMap?.image)
        setMaterial(
          itemsArray[4],
          irizaTextureMap,
          irizaTextureNormalMap,
          undefined
        );

      if (sandaleTextureMap?.image && sandaleTextureNormalMap?.image)
        setMaterial(
          itemsArray[5],
          sandaleTextureMap,
          sandaleTextureNormalMap,
          undefined
        );
      if (caracabaTextureMap?.image && caracabaTextureNormalMap?.image)
        setMaterial(
          itemsArray[6],
          caracabaTextureMap,
          caracabaTextureNormalMap,
          undefined
        );
      if (noLimitTextureMap?.image && noLimitTextureNormalMap?.image)
        setMaterial(
          itemsArray[7],
          noLimitTextureMap,
          noLimitTextureNormalMap,
          undefined
        );
      if (officialitoTextureMap?.image)
        setMaterial(
          itemsArray[8],
          officialitoTextureMap,
          officialitoTextureNormalMap,
          undefined
        );
    }
    onReady();
  };
  const loadEnvironment = () => {
    if (renderer == undefined || scene == undefined) {
      return;
    }
    const textureLoader = new THREE.TextureLoader(loadingManager);

    if (isMobileOrTab) {
      elisaTextureMap = textureLoader.load(tarotTextures.elisaMap);
      elisaTextureNormalMap = textureLoader.load(tarotTextures.elisaNormalMap);

      elisaTop2TextureMap = textureLoader.load(tarotTextures.elisaTop2Map);
      elisaTop2TextureNormalMap = textureLoader.load(
        tarotTextures.elisaTop2NormalMap
      );

      elisaBlueTextureMap = textureLoader.load(tarotTextures.elisaBlueMap);
      elisaBlueTextureNormalMap = textureLoader.load(
        tarotTextures.elisaBlueNormalMap
      );

      elisaTopTextureMap = textureLoader.load(tarotTextures.elisaTopMap);
      elisaTopTextureNormalMap = textureLoader.load(
        tarotTextures.elisaTopNormalMap
      );

      irizaTextureMap = textureLoader.load(tarotTextures.irizaMap);
      irizaTextureNormalMap = textureLoader.load(tarotTextures.irizaNormalMap);

      sandaleTextureMap = textureLoader.load(tarotTextures.sandaleMap);
      sandaleTextureNormalMap = textureLoader.load(
        tarotTextures.sandaleNormalMap
      );

      caracabaTextureMap = textureLoader.load(tarotTextures.caracabaMap);
      caracabaTextureNormalMap = textureLoader.load(
        tarotTextures.caracabaNormalMap
      );

      noLimitTextureMap = textureLoader.load(tarotTextures.noLimitMap);
      noLimitTextureNormalMap = textureLoader.load(
        tarotTextures.noLimitNormalMap
      );

      officialitoTextureMap = textureLoader.load(tarotTextures.officialitoMap);
    }

    transparent_Plane = new THREE.Mesh(
      new THREE.PlaneGeometry(32, 16),
      transparentMat
    );
    transparent_Plane.rotation.set(0, 0, 0);
    transparent_Plane.position.set(0, 0, 1.21);
    scene.add(transparent_Plane);
    transparent_Plane.visible = false;

    const loader = new RGBELoader();
    loader.setDataType(THREE.UnsignedByteType);
    loader.load(
      modelWorkshopEnvironmentImagePath,
      (texture) => {
        let pmremGenerator = new THREE.PMREMGenerator(renderer);
        pmremGenerator.compileEquirectangularShader();
        const envMap = pmremGenerator.fromEquirectangular(texture).texture;
        pmremGenerator = undefined;
        scene.environment = envMap;
      },
      undefined,
      undefined
    );
  };
  const detectFirstTouchedObject = (e, type, sys, objects) => {
    const CANVAS_HEIGHT = window.innerHeight;
    const CANVAS_WIDTH = window.innerWidth;
    if (e.touches != null) {
      if (e.touches.length > 0) {
        mouse.x = (e.touches[0].pageX / window.innerWidth) * 2 - 1;
        mouse.y = -(e.touches[0].pageY / window.innerHeight) * 2 + 1;
      }
    } else {
      const elem = renderer.domElement,
        boundingRect = elem.getBoundingClientRect();
      const x =
        (e.clientX - boundingRect.left) * (elem.width / boundingRect.width);
      const y =
        (e.clientY - boundingRect.top) * (elem.height / boundingRect.height);
      mouse.x = (x / CANVAS_WIDTH) * 2 - 1;
      mouse.y = -(y / CANVAS_HEIGHT) * 2 + 1;
    }
    raycaster.setFromCamera(mouse, camera);
    if (type === touchType.touchDown) {
      mouseDown.x = mouse.x;
      mouseDown.y = mouse.y;
    }
    if (
      type === touchType.touchUp &&
      mouseDown.x === mouse.x &&
      mouseDown.y === mouse.y
    ) {
      const objs = objects.filter((model) => model);
      const intersects = raycaster.intersectObjects(objs);
      if (intersects.length) {
        return intersects[0];
      }
    }
  };
  const focusCall = (no) => {
    backDiv.style.display = 'block';
    if (selectedItem != no) ComeGoBack();
    loadNew(false, 0);
    selectedItem = no;
    if (!itemsArray[selectedItem]) return;
    if (!itemsArray[selectedItem]) return;
    resetPositionForward(itemsArray[selectedItem], ZoomOut, Math.PI * 0.1); //Come forword
    loadNew(true, selectedItem);
    updateObjectControlForModel(focusedModel);
    didSelectModel(
      tarotProducts.find((p) => p.modelName === focusedModel.name)
    );
    fucusObjectPosition.set(
      focusedModel.position.x,
      focusedModel.position.y,
      focusedModel.position.z
    );
    if (controls) {
      controls.disableVerticalRotation();
      controls.disableHorizontalRotation();
    }
  };
  const touchEvent = (e, type, sys) => {
    if (remoteState || isTweening || !itemsArray[5]) return;

    setTouchValues(e, type, sys);
    const clickedModel = detectFirstTouchedObject(
      e,
      type,
      sys,
      itemsChildForCatchingClick
    );
    if (touchType.touchDown === type) {
      isDown = true;
    }
    if (touchType.touchUp === type) {
      isDown = false;
    }
    if (focusedModel && isDown == true) {
      updateRemoteObjectControlForModel();
    }
    if (!focusedModel) {
      if (clickedModel) {
        for (
          let i = 0;
          i < itemsChildForCatchingClick.length && isTweening == false;
          i++
        ) {
          if (
            itemsChildForCatchingClick[i] == clickedModel.object &&
            selectedItem != i &&
            itemsArray[i]
          ) {
            focusCall(i);
            updateRemoteState({ flag: REMOTE_ACTION.FOCUS, focusItem: i });
          }
        }
      }
    } else {
      const clickedModel2 = detectFirstTouchedObject(
        e,
        type,
        sys,
        itemsChildForCatchingClick2
      );
      if (
        clickedModel2 &&
        focusedModel &&
        itemsChildForCatchingClick2[selectedItem] == clickedModel2.object
      ) {
        unFocusedCall();
        updateRemoteState({
          flag: REMOTE_ACTION.UN_FOCUS,
          focusItem: selectedItem
        });
      }
    }
  };
  const onwheelEvent = (event) => {
    if (selectedItem < 0 || remoteState || focusedModel === undefined) return;
    let modelScale = focusedModel.scale.x;
    if (event.deltaY > 0) {
      if (modelScale < maxScale) modelScale += incScale;
    } else {
      if (modelScale > minScale) modelScale -= incScale;
    }
    focusedModel.scale.set(modelScale, modelScale, modelScale);
    updateRemoteObjectControlForModel();
  };
  const unFocusedCall = () => {
    if (!focusedModel?.getObjectByName('root')) return;
    backDiv.style.display = 'none';
    ComeGoBack();
    focusedModel.getObjectByName('root').visible = true;
    focusedModel = undefined;
    selectedItem = -1;
    if (controls) {
      controls.disableVerticalRotation();
      controls.disableHorizontalRotation();
    }
  };
  const onPinch = (ev) => {
    if (remoteState) return;
    if (ev.type == 'doubletap' && selectedItem >= 0 && isTweening == false) {
      updateRemoteState({
        flag: REMOTE_ACTION.UN_FOCUS,
        focusItem: selectedItem
      });
      unFocusedCall();
    }
    if (
      focusedModel &&
      selectedItem >= 0 &&
      (ev.type == 'pinchstart' || ev.type == 'pinchmove')
    ) {
      if (ev.type == 'pinchstart') {
        initScale = focusedModel.scale.x || 1;
      }
      focusedModel.scale.set(
        initScale * ev.scale,
        initScale * ev.scale,
        initScale * ev.scale
      );
      if (focusedModel.scale.x > maxScale)
        focusedModel.scale.set(maxScale, maxScale, maxScale);
      if (focusedModel.scale.x < minScale)
        focusedModel.scale.set(minScale, minScale, minScale);
      updateRemoteObjectControlForModel();
    }
  };
  const setTouchValues = (e, type, sys) => {
    const CANVAS_HEIGHT = window.innerHeight;
    const CANVAS_WIDTH = window.innerWidth;
    if (e.touches !== null && e.touches !== undefined) {
      if (e.touches.length > 0) {
        mouse.x = (e.touches[0].pageX / window.innerWidth) * 2 - 1;
        mouse.y = -(e.touches[0].pageY / window.innerHeight) * 2 + 1;
        if (e.touches.length === 2) {
          mouse_2.x = (e.touches[1].pageX / window.innerWidth) * 2 - 1;
          mouse_2.y = -(e.touches[1].pageY / window.innerHeight) * 2 + 1;
          if (type === touchType.touchDown) {
            initDistance = mouse_2.distanceTo(mouse);
            onPinch({ type: 'pinchstart', scale: 1 });
          } else {
            const currentDistance = mouse_2.distanceTo(mouse);
            onPinch({
              type: 'pinchmove',
              scale: currentDistance / initDistance
            });
          }
        }
      }
    } else {
      const elem = renderer.domElement,
        boundingRect = elem.getBoundingClientRect();
      const x =
        (e.clientX - boundingRect.left) * (elem.width / boundingRect.width);
      const y =
        (e.clientY - boundingRect.top) * (elem.height / boundingRect.height);
      mouse.x = (x / CANVAS_WIDTH) * 2 - 1;
      mouse.y = -(y / CANVAS_HEIGHT) * 2 + 1;
    }
    if (type === touchType.touchDown) {
      mouseDownPos.x = mouse.x;
      mouseDownPos.y = mouse.y;
    }
    raycaster.setFromCamera(mouse, camera);
  };
  const eventDown = (e) => {
    touchEvent(e, touchType.touchDown, 0);
  };
  const eventMove = (e) => {
    touchEvent(e, touchType.touchMove, 0);
  };
  const eventUp = (e) => {
    touchEvent(e, touchType.touchUp, 0);
  };
  const zoomDisable = (e) => {
    e.preventDefault();
    document.body.style['zoom'] = '1';
  };
  const addEventListeners = () => {
    canvasRef.current?.addEventListener('mousedown', eventDown);
    canvasRef.current?.addEventListener('touchstart', eventDown);
    canvasRef.current?.addEventListener('mousemove', eventMove);
    canvasRef.current?.addEventListener('touchmove', eventMove);
    canvasRef.current?.addEventListener('mouseup', eventUp);
    canvasRef.current?.addEventListener('touchend', eventUp);
    canvasRef.current?.addEventListener('wheel', onwheelEvent);
    window.addEventListener('resize', onWindowResize, false);
  };
  const cleanMaterial = (material) => {
    for (const key of Object.keys(material)) {
      const value = material[key];
      if (value && typeof value === 'object' && 'minFilter' in value) {
        value.dispose();
      }
    }
    material.dispose();
    material = undefined;
  };
  const cleanTexture = (value) => {
    if (value && typeof value === 'object' && 'minFilter' in value) {
      value.dispose();
    }
    value = undefined;
    return undefined;
  };
  const cleanUp = () => {
    itemsArray.length = 0;
    itemsChildForCatchingClick.length = 0;
    if (scene != undefined) {
      scene.traverse((object) => {
        if (!object['isMesh']) return;
        object['geometry'].dispose();
        if (object['material'].isMaterial) {
          cleanMaterial(object['material']);
        } else {
          for (const material of object['material']) cleanMaterial(material);
        }
        object['geometry'] = undefined;
        object = undefined;
      });
      scene.children.forEach((model) => {
        scene.remove(model);
      });
    }
    if (renderer != undefined) {
      renderer.dispose();
      renderer && renderer.renderLists.dispose();
    }
    if (controls != undefined) {
      controls.disableVerticalRotation();
      controls.disableHorizontalRotation();
      controls.disableZoom();
    }

    elisaTextureMap = cleanTexture(elisaTextureMap);
    elisaTextureNormalMap = cleanTexture(elisaTextureNormalMap);
    elisaTop2TextureMap = cleanTexture(elisaTop2TextureMap);
    elisaTop2TextureNormalMap = cleanTexture(elisaTop2TextureNormalMap);
    elisaBlueTextureMap = cleanTexture(elisaBlueTextureMap);
    elisaBlueTextureNormalMap = cleanTexture(elisaBlueTextureNormalMap);
    elisaTopTextureMap = cleanTexture(elisaTopTextureMap);
    elisaTopTextureNormalMap = cleanTexture(elisaTopTextureNormalMap);
    irizaTextureMap = cleanTexture(irizaTextureMap);
    irizaTextureNormalMap = cleanTexture(irizaTextureNormalMap);
    sandaleTextureMap = cleanTexture(sandaleTextureMap);
    sandaleTextureNormalMap = cleanTexture(sandaleTextureNormalMap);
    caracabaTextureMap = cleanTexture(caracabaTextureMap);
    caracabaTextureNormalMap = cleanTexture(caracabaTextureNormalMap);
    noLimitTextureMap = cleanTexture(noLimitTextureMap);
    noLimitTextureNormalMap = cleanTexture(noLimitTextureNormalMap);
    officialitoTextureMap = cleanTexture(officialitoTextureMap);
    officialitoTextureNormalMap = cleanTexture(officialitoTextureNormalMap);

    canvasRef.current?.removeEventListener('touchstart', eventDown);
    canvasRef.current?.removeEventListener('touchmove', eventMove);
    canvasRef.current?.removeEventListener('touchend', eventUp);
    canvasRef.current?.removeEventListener('mousedown', eventDown);
    canvasRef.current?.removeEventListener('mousemove', eventMove);
    canvasRef.current?.removeEventListener('mouseup', eventUp);
    canvasRef.current?.removeEventListener('wheel', onwheelEvent);
    window.removeEventListener('resize', onWindowResize);

    document.removeEventListener('gesturestart', zoomDisable);
    document.removeEventListener('gesturechange', zoomDisable);
    document.removeEventListener('gestureend', zoomDisable);

    controls = undefined;
    scene = undefined;
    renderer = undefined;
    camera = undefined;
  };

  const animate = () => {
    if (renderer == undefined || scene == undefined) {
      return;
    }

    if (clickBack && !viewOnly && !isTweening) {
      clickBack = false;
      unFocusedCall();
      updateRemoteState({
        flag: REMOTE_ACTION.UN_FOCUS,
        focusItem: selectedItem
      });
    }

    delta = clock.getDelta();
    if (
      zoomModel &&
      focusedModel &&
      focusedModel?.ItemId === zoomModel?.ItemId
    ) {
      zoomModel.position.set(
        focusedModel.position.x,
        focusedModel.position.y,
        focusedModel.position.z
      );
      zoomModel.scale.set(
        focusedModel.scale.x,
        focusedModel.scale.y,
        focusedModel.scale.z
      );
      zoomModel.rotation.set(
        focusedModel.rotation.x,
        focusedModel.rotation.y,
        focusedModel.rotation.z
      );
      if (focusedModel?.getObjectByName('root'))
        focusedModel.getObjectByName('root').visible = false;
      zoomModel.visible = animationOnFocus;
    }
    if (loadingCount > 0) loadingCount++;
    if (
      tarotCardRemoteState &&
      tarotCardRemoteState?.flag &&
      itemsArray[5] &&
      loadingCount > 10
    ) {
      if (tarotCardRemoteState.flag == REMOTE_ACTION.FOCUS) {
        focusCall(tarotCardRemoteState.focusItem);
      }
      if (tarotCardRemoteState.flag == REMOTE_ACTION.UN_FOCUS) {
        unFocusedCall();
      }
      if (
        tarotCardRemoteState.flag == REMOTE_ACTION.MOUSE_CONTROL &&
        !isTweening
      ) {
        if (
          focusedModel?.ItemId != selectedItem &&
          tarotCardRemoteState?.focusItem >= 0 &&
          tarotCardRemoteState?.focusItem < itemsArray.length
        ) {
          callback();
          focusedModel = itemsArray[tarotCardRemoteState.focusItem];
          selectedItem = tarotCardRemoteState.focusItem;
        }
        if (focusedModel) {
          focusedModel.position.set(
            tarotCardRemoteState.position[0],
            tarotCardRemoteState.position[1],
            tarotCardRemoteState.position[2]
          );
          focusedModel.scale.set(
            tarotCardRemoteState.scale[0],
            tarotCardRemoteState.scale[1],
            tarotCardRemoteState.scale[2]
          );
          focusedModel.rotation.set(
            tarotCardRemoteState.rotation[0],
            tarotCardRemoteState.rotation[1],
            tarotCardRemoteState.rotation[2]
          );
          animationOnFocus = true;
          if (focusedModel?.getObjectByName('root') && !zoomModel)
            focusedModel.getObjectByName('root').visible = true;
        }
      }
      tarotCardRemoteState.flag = undefined;
      tarotCardRemoteState = undefined;
    }
    itemsChildForCatchingClick
      .filter((model) => model)
      .forEach((model) => {
        model.tiles.update(delta * 1000);
      });

    renderer.render(scene, camera);
    TWEEN.update();
    requestAnimationFrame(animate);
  };

  const onWindowResize = () => {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(window.innerWidth, window.innerHeight);
  };

  const loadThreeJsModulesAsync = async () => {
    GLTFLoader = (await import('three/examples/jsm/loaders/GLTFLoader'))
      .GLTFLoader;
    RGBELoader = (await import('three/examples/jsm/loaders/RGBELoader'))
      .RGBELoader;
    TWEEN = (await import('three/examples/jsm/libs/tween.module.min.js')).TWEEN;
    ObjectControls = (await import('../common/ObjectControls')).ObjectControls;
  };
  React.useEffect(() => {
    if (remoteState) {
      tarotCardRemoteState = remoteState;
    }
  }, [remoteState]);
  React.useEffect(() => {
    const canvas = document.querySelector('#c') as HTMLCanvasElement;
    backDiv = document.getElementById('topright');
    backDiv.style.display = 'none';
    camera = new THREE.PerspectiveCamera(
      45,
      window.innerWidth / window.innerHeight,
      0.1,
      20
    );
    renderer = new THREE.WebGLRenderer({
      canvas,
      antialias: true,
      alpha: true
    });

    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setClearColor(0x000000, 0);
    renderer.outputEncoding = THREE.sRGBEncoding;

    camera.position.set(0, 0, 10);
    camera.lookAt(new THREE.Vector3(0, 0, 0));
    if (isMobile) {
      document.addEventListener('gesturestart', zoomDisable);
      document.addEventListener('gesturechange', zoomDisable);
      document.addEventListener('gestureend', zoomDisable);
    }
    loadThreeJsModulesAsync().then(() => {
      textureTop = new THREE.TextureLoader(loadingManager).load(cardTopImage);
      textureTop.encoding = THREE.sRGBEncoding;
      for (let i = 0; i < 9; i++) {
        loadSneaker(null, i);
      }
      loadEnvironment();
      if (!viewOnly) {
        addEventListeners();
      }
      animate();
    });

    return () => {
      cleanUp();
    };
  }, []);

  return (
    <div>
      <canvas id="c" ref={canvasRef}></canvas>
      <p id="topright">
        <BackToAnimationButton onClick={onClickBack} />
      </p>
      <style jsx>
        {`
          div {
            background: url(${`${assetBaseUrl}/loubiairways/luggage-animation/tarot/background/v4/tarot_background.jpg`})
              no-repeat;
            background-position: center center;
            background-size: cover;
            background-attachment: fixed;
          }

          div,
          #c {
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
          }
          a {
            position: fixed;
            top: 2vh;
            left: 2vh;
            font-size: 0.81em;
            text-align: left;
          }
        `}
      </style>
    </div>
  );
};

export default TarotCard;
