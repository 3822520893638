import React from 'react';
import { selectEl } from '../animationUtils';
import NewsPaperContent from './NewsPaperContent';
import { ILoubiAirways2dAnimationState } from '../../../../../interfaces/loubiairways';
import { MDLandscapeNormalSpec } from '../../../../Meeting/MeetingLayout/MDLandscapeNormal';
import { SMLandscapeNormalSpec } from '../../../../Meeting/MeetingLayout/SMLandscapeNormal';
const NewsPaper = ({
  speed = 1000,
  coverTop,
  coverBottom,
  children,
  background,
  portraitBackground,
  onClose,
  delay = 0,
  close,
  updateRemoteState,
  overlay
}: {
  speed?: number;
  coverTop: string;
  coverBottom: string;
  children: React.ReactNode;
  background: string;
  portraitBackground: string;
  onClose?: () => void;
  delay?: number;
  close?: boolean;
  remoteState?: ILoubiAirways2dAnimationState;
  updateRemoteState?: (state: ILoubiAirways2dAnimationState) => void;
  overlay?: string;
}) => {
  const [show, setShow] = React.useState(false);
  const paperNav = React.useRef<HTMLDivElement>();
  const handleClose = () => {
    selectEl(paperNav)
      .removeClass(['flip-in', 'hide'])
      .addClass('opening', speed / 2)
      .removeClass('opening', speed)
      .addClass('closing', speed);
    updateRemoteState({ close: true });
    setTimeout(() => {
      setShow(false);
    }, 1);

    if (onClose) {
      updateRemoteState(undefined);
      setTimeout(onClose, delay);
    }
  };

  const handleOpen = () => {
    selectEl(paperNav)
      .removeClass('closing')
      .addClass('opening')
      .removeClass('opening', speed / 2)
      .addClass('flip-in', speed)
      .addClass('hide', speed + 200);

    setTimeout(() => {
      setShow(true);
    }, speed + speed / 2);
  };

  React.useEffect(() => {
    setTimeout(handleOpen, delay);
  }, []);

  React.useEffect(() => {
    if (close) {
      handleClose();
    }
  }, [close]);
  return (
    <div className="newspaper fixed-full">
      <div ref={paperNav} className="imgNav paperNav closing">
        <img className="paper paperTop" src={coverTop} />
        <img className="paper paperBottom" src={coverBottom} />
        <div className="tint" />
      </div>

      {overlay && <img src={overlay} className="overlay" />}
      {show && <NewsPaperContent>{children}</NewsPaperContent>}

      <style jsx>{`
        .newspaper {
          background: #000 url('${background}') center center no-repeat;
          background-size: auto 100vh;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }

        :global(.in-meeting.MDLandscape) .newspaper,
        :global(.in-meeting.MDPortrait) .newspaper {
          top: ${MDLandscapeNormalSpec.contentArea.top}px;
        }
        :global(.in-meeting.SMLandscape) .newspaper ,
        :global(.in-meeting.SMPortrait) .newspaper {
          top: ${SMLandscapeNormalSpec.contentArea.top}px;
        }
      
        .overlay {
          position: fixed;
          z-index: 3;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          height: 17.2vh;
          margin-top: -15.05vh;
          margin-left: 0;
        }
        :global(.in-meeting.MDLandscape) .overlay,
        :global(.in-meeting.MDPortrait) .overlay {
          margin-top: ${MDLandscapeNormalSpec.contentArea.top/2}px;
        }
        :global(.in-meeting.SMLandscape) .overlay ,
        :global(.in-meeting.SMPortrait) .overlay {
          margin-top: ${SMLandscapeNormalSpec.contentArea.top/2}px;
        }
        :global(.in-meeting.MDLandscape) .overlay {
          margin-left: ${MDLandscapeNormalSpec.contentArea.left / 2}px;
        }
        :global(.in-meeting.SMLandscape) .overlay {
          margin-left: ${SMLandscapeNormalSpec.contentArea.left / 2}px;
        }

        .paperNav {
          position: relative;
          margin: auto;
          transition: all ${(speed / 2) * 0.001}s ease-in-out;
          cursor: pointer;
          z-index: 100;
        }
        .tint {
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 50%;
          opacity: 0;
          background: #be4f40;
          box-shadow: 0 15px 15px #c05b5a;
          z-index: 10;
          transition: all ${(speed / 2) * 0.001}s ease-in-out;
        }
        .paper {
          height: 50vh;
          transition: all ${(speed / 2) * 0.001}s ease-in-out;
          display: block;
          position: relative;
          z-index: 2;
        }

        .paperNav.closing {
          transform: translate(0.5vh, -14vh);
          z-index: 2;
        }
        .paperNav.closing .tint {
          opacity: 0.5;
        }

        .closing .paper,
        .opening .paper {
          height: 18.3vh;
        }

        .paperNav.opening {
          z-index: 2;
          transform: translate(0, -25vh);
        }

        .paperNav.hide {
          visibility: hidden;
        }

        .closing .paperBottom {
          visibility: hidden;
        }

        .paperBottom {
          z-index: 0;
          -webkit-animation: flip-close ${(speed / 2) * 0.001}s ease-in-out both;
          animation: flip-close ${(speed / 2) * 0.001}s ease-in-out both;
        }

        .flip-in .paperBottom {
          -webkit-animation: flip-open ${(speed / 2) * 0.001}s ease-in-out both;
          animation: flip-open ${(speed / 2) * 0.001}s ease-in-out both;
        }

        @media (orientation: portrait) {
          .newspaper {
            background: #000 url('${portraitBackground}') center center
              no-repeat;
            background-size: auto 100vh;
          }
          .paper {
            height: auto !important;
            width: 100vw !important;
            max-width: calc(100vh * 0.67);
            max-height: none !important;
          }

          .opening .paper {
            height: auto !important;
            width: 25.5vh !important;
          }
          .closing .paper {
            height: auto !important;
            width: 25.5vh !important;
          }
        }

        @-webkit-keyframes flip-open {
          0% {
            -webkit-transform: rotateX(-100deg);
            transform: rotateX(-100deg);
            -webkit-transform-origin: top;
            transform-origin: top;
          }
          100% {
            -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
            -webkit-transform-origin: top;
            transform-origin: top;
          }
        }
        @keyframes flip-open {
          0% {
            -webkit-transform: rotateX(-100deg);
            transform: rotateX(-100deg);
            -webkit-transform-origin: top;
            transform-origin: top;
          }
          100% {
            -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
            -webkit-transform-origin: top;
            transform-origin: top;
          }
        }

        @-webkit-keyframes flip-close {
          0% {
            -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
            -webkit-transform-origin: top;
            transform-origin: top;
          }
          100% {
            -webkit-transform: rotateX(-100deg);
            transform: rotateX(-100deg);
            -webkit-transform-origin: top;
            transform-origin: top;
          }
        }
        @keyframes flip-close {
          0% {
            -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
            -webkit-transform-origin: top;
            transform-origin: top;
          }
          100% {
            -webkit-transform: rotateX(-100deg);
            transform: rotateX(-100deg);
            -webkit-transform-origin: top;
            transform-origin: top;
          }
        }
      `}</style>
    </div>
  );
};

export default NewsPaper;
