import React from 'react';
import { useDispatch } from 'react-redux';
import { IPlanePlan } from '../../../../../interfaces/loubiairways';
import { actionUpdateLoubiAirwaysPlanePlan } from '../../../../../redux/customActions/loubiAirways';
import { useLoubiAirwaysState } from '../../../../hooks/loubiAirways';
import ContinueFlightButton from '../Buttons/ContinueFlightButton';
import LoubiAirwaysPlanMapAccessButton from '../Buttons/LoubiAirwaysPlanMapAccessButton';
import LoubiAirwaysPlan from './LoubiAirwaysPlan';
const PlanePlan = () => {
  const dispatch = useDispatch();
  const [show, setShow] = React.useState(false);
  const [showBack, setShowBack] = React.useState(false);
  const state = useLoubiAirwaysState()?.planePlan;
  const updateState = (s: IPlanePlan) => {
    dispatch(
      actionUpdateLoubiAirwaysPlanePlan({ ...state, ...s, open: false })
    );
  };

  React.useEffect(() => {
    if (state?.open) {
      setShow(true);
      setTimeout(() => {
        setShowBack(true);
      }, 600);
    } else {
      setShowBack(false);
      setTimeout(() => {
        setShow(false);
      }, 500);
    }
  }, [state]);

  return (
    <>
      {show && <LoubiAirwaysPlan state={state} updateState={updateState} />}
      {showBack && (
        <ContinueFlightButton
          onClick={() => {
            updateState({});
          }}
        />
      )}
      {!show && <LoubiAirwaysPlanMapAccessButton />}
    </>
  );
};

export default PlanePlan;
