import React from 'react';
import { MDLandscapeNormalSpec } from '../../../../Meeting/MeetingLayout/MDLandscapeNormal';
import { SMLandscapeNormalSpec } from '../../../../Meeting/MeetingLayout/SMLandscapeNormal';
import { selectEl } from '../animationUtils';
import SinglePaperContent from './SinglePaperContent';

const SPEED = 700;
interface Props {
  background: string;
  cover: string;
  portraitBackground: string;
  children: React.ReactNode;
  close: boolean;
  delay?: number;
  overlay?: string;
  className?: string;
}
const SinglePaper = ({
  background,
  portraitBackground,
  cover,
  children,
  close,
  delay = 0,
  overlay,
  className = ''
}: Props) => {
  const [show, setShow] = React.useState(false);
  const paperNav = React.useRef<HTMLImageElement>();

  React.useEffect(() => {
    selectEl(paperNav).addClass('opening', delay).removeClass('closing', delay);
    selectEl(paperNav).removeClass('opening', delay + SPEED);
    setTimeout(() => {
      selectEl(paperNav).addClass('hide', 600);
      setShow(true);
    }, delay + SPEED + 600);
  }, []);

  React.useEffect(() => {
    if (close) {
      selectEl(paperNav).removeClass('hide').addClass('opening');
      selectEl(paperNav)
        .removeClass('opening', SPEED)
        .addClass('closing', SPEED);
      setTimeout(() => {
        setShow(false);
      }, 1);
    }
  }, [close]);

  return (
    <div className={`seat fixed-full ${className}`}>
      <img className="paperNav closing" src={cover} ref={paperNav} />
      {overlay && <img src={overlay} className="overlay" />}
      {show && <SinglePaperContent>{children}</SinglePaperContent>}

      <style jsx>{`
        .seat {
          background: url('${background}') center center no-repeat;
          background-size: auto 100vh;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
        .overlay {
          position: fixed;
          z-index: 2;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }

        :global(.in-meeting.SMLandscape) .overlay {
          left: calc(50% + ${SMLandscapeNormalSpec.contentArea.left / 2}px);
        }

        :global(.in-meeting.MDLandscape) .overlay {
          left: calc(50% + ${MDLandscapeNormalSpec.contentArea.left / 2}px);
        }

        .paperNav {
          margin: auto;
          transition: all ${SPEED * 0.001}s ease-in-out;
          cursor: pointer;
          height: 100%;
          max-height: 800px;
          z-index: 100;
        }
        .paperNav.closing {
          height: 250px;
          box-shadow: 0 5px 5px rgba(0, 0, 0, 0.5);
          transform: translate(-95px, 174px) rotate(-12deg);
          z-index: 2;
        }

        .paperNav.opening {
          height: 250px;
          z-index: 2;
          transform: translate(-50%, -50%) rotate(0);
        }

        .paperNav.hide {
          visibility: hidden;
        }
        @media (orientation: portrait) {
          .seat {
            background: url('${portraitBackground}') center center no-repeat;
            background-size: auto 100vh;
          }
          .paperNav {
            height: auto;
            width: 100vw;
            max-height: none;
            transform: translate(-50%, -50%);
          }
          .paperNav.opening {
            height: auto;
            width: 200px;
            z-index: 2;
            transform: translate(-50%, -50%);
          }
          .paperNav.closing {
            height: auto;
            width: 200px;
            top: 50%;
            box-shadow: 0 5px 5px rgba(0, 0, 0, 0.5);
            transform: translate(-95px, 174px) rotate(-12deg);
          }
        }
      `}</style>
    </div>
  );
};

export default SinglePaper;
