import React from 'react';
import { logEvent } from '../../../../../analytics';
import { assetBaseUrl, zIndex } from '../../../../../config';
import {
  IPlanePlan,
  PlanSpotLight
} from '../../../../../interfaces/loubiairways';
import { DID_CLICK_PLANE_PLAN_SPOT } from '../../../../../utils/constants';
import { getViewportSize } from '../../../../../utils/window';

const asset = `${assetBaseUrl}/loubiairways/luggage-animation/plane-plan/v1`;
const LoubiAirwaysPlan = ({
  state,
  updateState
}: {
  state: IPlanePlan;
  updateState: (state: IPlanePlan) => void;
}) => {
  const [{ vh }, setViewport] = React.useState({ vh: 0, vw: 0 });

  React.useEffect(() => {
    setViewport(getViewportSize());
  }, []);
  const spotLight = (
    spot: PlanSpotLight,
    lTop: number,
    lLeft: number,
    pTop: number,
    pLeft: number
  ) => (
    <>
    <button
      className="btn"
      onClick={() => {
        updateState({
          selectedSpot: spot
        });
        logEvent(DID_CLICK_PLANE_PLAN_SPOT, DID_CLICK_PLANE_PLAN_SPOT, {
          spot
        });
      }}
    />
     
     <img
        src={`${asset}/${
          state?.visitedSpot?.includes(spot)
            ? 'CL-Hotspot-Red.gif'
            : 'CL-Hotspot.gif'
        }`}
      />
      <style jsx>{`
        button,img {
          position: fixed;
          border:none;
          background: rgba(0, 0, 0, 0.3);
          top: ${vh / 2}px;
          left: 50vw;
          border-radius: 100%;
          transform: translate(-50%, -50%);
          padding: 0;
        }
       img{
         pointer-events:none;
       }
        @media (orientation: landscape) {
          button,img {
            width: 3vw;
            height: 3vw;
            margin-top: ${lTop}vw;
            margin-left: ${lLeft}vw;
          }
          img{
            width:calc(3vw - 4px);
            height:auto;
          }
        }

        @media (orientation: portrait) {
          button,img {
            width: ${vh * 0.04}px;
            height: ${vh * 0.04}px;
            margin-top: ${pTop * 0.01 * vh}px;
            margin-left: ${pLeft * 0.01 * vh}px;
          }
          img{
            height:auto;
            width: ${vh * 0.04 - 2}px;
          }
        }
      `}</style>
    </>
  );
  return (
    <div className={`PlanePlan fixed-full disable-click-for-client-meeting ${!state?.open ? 'closing' : ''}`}>
      <div className="backdrop fixed-full" />
      <div className="plan-container fixed-full">
        <img
          src={`${asset}/plane-landscape.png`}
          alt="Plane landscape"
          className="plane plane-landscape"
        />
        <img
          src={`${asset}/plane-portrait.png`}
          alt="Plane portrait"
          className="plane plane-portrait"
        />
        <img
          src={`${asset}/route-landscape.gif`}
          alt="route landscape"
          className="route route-landscape"
        />
        <img
          src={`${asset}/route-portrait.gif`}
          alt="route portrait"
          className="route route-portrait"
        />
      </div>
      <div className="spot-container fixed-full">
        {spotLight(PlanSpotLight.NEWSPAPER, 4.5, -25.5, -25, -4.5)}
        {spotLight(PlanSpotLight.COCKPIT, 0, -25.5, -25, 0)}
        {spotLight(PlanSpotLight.STYLISH_VIDEO, -4.5, -25.5, -25, 4.5)}
        {spotLight(PlanSpotLight.LUGGAGE, -3.8, -12.8, -12.9, 3.8)}
        {spotLight(PlanSpotLight.TROLEY, 5, -4.2, -4.2, -5)}
        {spotLight(PlanSpotLight.BEAUTY_CART, -5, -4.2, -4.2, 5)}
        {spotLight(PlanSpotLight.WINDOW, -7, 0.2, 0, 7)}
        {spotLight(PlanSpotLight.XRAY, 3, 4, 3.5, -2.7)}
        {spotLight(PlanSpotLight.TRAY, 5.7, 7, 7, -5.7)}
        {spotLight(PlanSpotLight.IN_FLIGHT_MAGAZINE, -3.4, 12, 12, 3.7)}
        {spotLight(PlanSpotLight.OMG_MAGAZINE, -7, 18, 18, 7)}
        {spotLight(PlanSpotLight.STYLISH_GUIDE, -7, 23.2, 23, 7)}
        {spotLight(PlanSpotLight.PHONE, 7, 22.5, 22.5, -7)}
        {spotLight(PlanSpotLight.HEADSET, -3.8, 33.8, 33.7, 3.5)}
        {spotLight(PlanSpotLight.TARROT, 0, 43.6, 43.6, 0)}
      </div>
      <style jsx>{`
        :global(.fixed-full){
          left:0  !important;
        }
        .backdrop {
          background: black;
          opacity: 0.7;
          z-index: 0;
          animation: fade-in 0.5s linear both;
        }
        .PlanePlan{
          z-index:${zIndex.meetingControls + 1};
        }
        .PlanePlan.closing {
          animation: fade-out 0.5s linear both;
        }
        .plan-container img {
          position: absolute;
          z-index: 1;
          display: none;
        }
        .spot-container {
          animation: spot-fade-in 0.5s linear both;
        }

        @keyframes spot-fade-in {
          0%,
          99% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
        @keyframes fade-in {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 0.7;
          }
        }
        @keyframes fade-out {
          0% {
            opacity: 0.7;
          }
          100% {
            opacity: 0;
          }
        }

        @media (orientation: landscape) {
          .plane-landscape,
          .route-landscape {
            display: block !important;
            width: 100vw;
            top: ${vh / 2}px;
            left: 0;
            transform: translateY(-50%);
          }
          .plane-landscape {
            animation: plane-slide-left 0.5s ease-out both;
          }
          .route-landscape {
            margin-left: 24.7vw;
            width: 74.7vw;
            animation: route-slide-left 0.5s ease-out both;
            opacity: 0;
          }
          @keyframes plane-slide-left {
            0% {
              left: 100vw;
            }
            100% {
              left: 0;
            }
          }
          @keyframes route-slide-left {
            0%,
            70% {
              left: 100vw;
              opacity: 0;
            }

            100% {
              left: 0;
              opacity: 1;
            }
          }
        }

        @media (orientation: portrait) {
          .plane-portrait,
          .route-portrait {
            display: block !important;
            height: ${vh}px;
            left: 50%;
            top: 0;
            transform: translateX(-50%);
          }
          .plane-portrait {
            animation: plane-slide-top 0.5s ease-out both;
          }
          .route-portrait {
            margin-top: ${0.247 * vh}px;
            height: ${0.747 * vh}px;
            animation: route-slide-top 0.5s ease-out both;
            opacity: 0;
          }
          @keyframes plane-slide-top {
            0% {
              top: ${vh}px;
            }
            100% {
              top: 0;
            }
          }
          @keyframes route-slide-top {
            0%,
            70% {
              top: ${vh}px;
              opacity: 0;
            }

            100% {
              top: 0;
              opacity: 1;
            }
          }
        }
      `}</style>
    </div>
  );
};

export default LoubiAirwaysPlan;
