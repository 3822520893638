import React from 'react';
import { logEvent } from '../../../../../analytics';
import { AnimationContext } from '../../../../ThreejsAnimation/louboutin/AnimationContext';
import { selectEl } from '../animationUtils';
import {
  AnimationProductModel,
  get2DAnimationProductByModel
} from '../TwoDProduct/TwoDProductsList';
import { trayAsset } from './';
import { DID_OPEN_TRAY_LID } from './../../../../../utils/constants';
const TrayLid = () => {
  const animateStep = 16.6666;

  const { setSelectedModel, selectedModel } = React.useContext(
    AnimationContext
  );

  const [currentLid, setCurrentLid] = React.useState<
    React.MutableRefObject<HTMLElement>
  >(undefined);

  const ref = [
    React.useRef<HTMLDivElement>(),
    React.useRef<HTMLDivElement>(),
    React.useRef<HTMLDivElement>(),
    React.useRef<HTMLDivElement>(),
    React.useRef<HTMLDivElement>()
  ];

  const animateOpen = (
    el: React.MutableRefObject<HTMLElement>,
    model: string
  ) => {
    setCurrentLid(el);
    selectEl(el)
      .addClass('open')
      .removeClass('open', 500)
      .addClass('opened', 500);
    logEvent(DID_OPEN_TRAY_LID, DID_OPEN_TRAY_LID, { model, open: true });
  };

  const animateClose = (el: React.MutableRefObject<HTMLElement>) => {
    setCurrentLid(undefined);
    selectEl(el)
      .removeClass('opened', 300)
      .addClass('closing', 300)
      .removeClass('closing', 800);
    logEvent(DID_OPEN_TRAY_LID, DID_OPEN_TRAY_LID, {
      model: selectedModel,
      open: false
    });
  };

  const getRefByModel = (model: string) => {
    if (model === AnimationProductModel.CLLOUBIAIRWAYS025) return ref[0];
    if (model === AnimationProductModel.CLLOUBIAIRWAYS024) return ref[1];
    if (model === AnimationProductModel.CLLOUBIAIRWAYS026) return ref[2];
    if (model === AnimationProductModel.CLLOUBIAIRWAYS123) return ref[3];
    return ref[4];
  };

  const handleOpenProduct = (model: string) => {
    setSelectedModel(get2DAnimationProductByModel(model));
  };

  React.useEffect(() => {
    if (selectedModel) {
      animateOpen(
        getRefByModel(selectedModel?.modelCode),
        selectedModel?.modelCode
      );
    } else {
      animateClose(currentLid);
    }
  }, [selectedModel]);

  return (
    <>
      <div
        className="lid lid-big lid-a"
        ref={ref[0]}
        onClick={() =>
          handleOpenProduct(AnimationProductModel.CLLOUBIAIRWAYS025)
        }
      >
        <div>
          <img
            className="ratio-keeper"
            src={`${trayAsset}/top-a-placeholder.png`}
            alt=""
          />
          <div className="cover" />
        </div>
      </div>

      <div
        className="lid lid-big lid-b"
        ref={ref[1]}
        onClick={() =>
          handleOpenProduct(AnimationProductModel.CLLOUBIAIRWAYS024)
        }
      >
        <div>
          <img
            className="ratio-keeper"
            src={`${trayAsset}/top-b-placeholder.png`}
            alt=""
          />
          <div className="cover" />
        </div>
      </div>

      <div
        className="lid lid-small lid-c"
        ref={ref[2]}
        onClick={() =>
          handleOpenProduct(AnimationProductModel.CLLOUBIAIRWAYS026)
        }
      >
        <div>
          <img
            className="ratio-keeper"
            src={`${trayAsset}/top-small-placeholder.png`}
            alt=""
          />
          <div className="cover" />
        </div>
      </div>

      <div
        className="lid lid-small lid-d"
        ref={ref[3]}
        onClick={() =>
          handleOpenProduct(AnimationProductModel.CLLOUBIAIRWAYS123)
        }
      >
        <div>
          <img
            className="ratio-keeper"
            src={`${trayAsset}/top-small-placeholder.png`}
            alt=""
          />
          <div className="cover" />
        </div>
      </div>

      <div
        className="lid lid-small lid-e"
        ref={ref[4]}
        onClick={() =>
          handleOpenProduct(AnimationProductModel.CLLOUBIAIRWAYS027)
        }
      >
        <div>
          <img
            className="ratio-keeper"
            src={`${trayAsset}/top-small-placeholder.png`}
            alt=""
          />
          <div className="cover" />
        </div>
      </div>

      <style jsx>{`
        p {
          color: #fff;
          position: fixed;
          left: 0;
          bottom: 0;
          font-size: 20px;
          font-family: Courier;
        }
        .ratio-keeper {
          width: 100%;
          visibility: hidden;
        }
        .lid {
          position: absolute;
          z-index: 100;
          cursor: pointer;
          transition: none;
        }
        .lid > div {
          position: relative;
        }
        .cover {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
        }

        .lid.open .cover,
        .lid.closing .cover,
        .lid:hover .cover {
          visibility: visible;
        }

        .lid.opened .cover {
          visibility: hidden;
        }

        .lid:hover .cover {
          animation: hover-lid-big 0.5s steps(1) both;
        }

        .lid.open .cover {
          animation: open-lid-big 0.5s steps(1) both;
        }
        .lid.closing .cover {
          animation: close-lid-big 0.5s steps(1) both;
        }

        .lid-big {
          width: 34.125%;
          left: 18.28%;
        }

        .lid-small {
          width: 25.3125%;
          left: 54%;
        }

        .lid-a {
          top: 10.18%;
        }

        .lid-a .cover {
          background: url('${trayAsset}/top-a-sprite.png') center top no-repeat;
          background-size: 100% auto;
        }

        .lid-b {
          top: 49.4%;
        }

        .lid-b .cover {
          background: url('${trayAsset}/top-b-sprite.png') center top no-repeat;
          background-size: 100% auto;
        }

        .lid-c {
          top: 10.8%;
        }

        .lid-c .cover {
          background: url('${trayAsset}/top-small-sprite.png') center top
            no-repeat;
          background-size: 100% auto;
        }

        .lid-d {
          top: 36.7%;
        }

        .lid-d .cover {
          background: url('${trayAsset}/top-small-sprite.png') center top
            no-repeat;
          background-size: 100% auto;
        }

        .lid-e {
          top: 62.7%;
        }

        .lid-e .cover {
          background: url('${trayAsset}/top-small-sprite.png') center top
            no-repeat;
          background-size: 100% auto;
        }

        @keyframes hover-lid-big {
          0% {
            background-position: center ${animateStep * 0}%;
          }
          25% {
            background-position: center ${animateStep * 1}%;
          }
          50% {
            background-position: center ${animateStep * 2}%;
          }
          75% {
            background-position: center ${animateStep * 3}%;
          }
          100% {
            background-position: center ${animateStep * 4}%;
          }
        }
        @keyframes open-lid-big {
          0% {
            background-position: center ${animateStep * 5}%;
          }
          50% {
            background-position: center ${animateStep * 6}%;
          }
          100% {
            background-position: center ${animateStep * 7}%;
          }
        }
        @keyframes close-lid-big {
          0% {
            background-position: center ${animateStep * 7}%;
          }
          14% {
            background-position: center ${animateStep * 6}%;
          }
          28% {
            background-position: center ${animateStep * 5}%;
          }
          42% {
            background-position: center ${animateStep * 4}%;
          }
          56% {
            background-position: center ${animateStep * 3}%;
          }
          70% {
            background-position: center ${animateStep * 2}%;
          }
          84% {
            background-position: center ${animateStep * 1}%;
          }
          100% {
            background-position: center ${animateStep * 0}%;
          }
        }
      `}</style>
    </>
  );
};

export default TrayLid;
