import React from 'react';
import { useDispatch } from 'react-redux';
import { Transition } from 'react-transition-group';
import { logEvent } from '../../../analytics';
import {
  IAnimationProduct,
  LoubiAirwaysAnimation,
  REMOTE_ACTION
} from '../../../interfaces/loubiairways';
import {
  actionUpdateActiveAnimationInPano,
  actionUpdateLoubiAirwaysPopupContentType,
  actionUpdateLoubiAirwaysSelectedModel
} from '../../../redux/customActions/loubiAirways';
import {
  DID_CLOSE_ANIMATION,
  DID_SELECT_ANIMATION_MODEL,
  DID_SHOW_ANIMATION
} from '../../../utils/constants';
import {
  isUserLoubiAirwaysVIP,
  useLoubiAirwaysState
} from '../../hooks/loubiAirways';
import { MDLandscapeNormalSpec } from '../../Meeting/MeetingLayout/MDLandscapeNormal';
import { SMLandscapeNormalSpec } from '../../Meeting/MeetingLayout/SMLandscapeNormal';
import PreventMobileZoomContainer from '../../PreventMobileZoomContainer';
import {
  getPopupContentTypeByAnimation,
  is2DAnimation
} from '../../VirtualBoutique/CustomComponent/LouboutinCustomComponent/animationUtils';
import FlipBoardText from '../../VirtualBoutique/CustomComponent/LouboutinCustomComponent/FlipBoardText';
import LoubiAirwaysWishlist from '../../VirtualBoutique/CustomComponent/LouboutinCustomComponent/Wishlist/LoubiAirwaysWishlist';
import TwoDProductImageContainer from './../../VirtualBoutique/CustomComponent/LouboutinCustomComponent/TwoDProduct/TwoDProductImageContainer';
import LoubiAirwaysPopupContent from './LoubiAirwaysPopupContent';

export interface IAnimationContext {
  setSelectedModel: (selectedModel: IAnimationProduct) => void;
  setLoaded: (loaded: boolean) => void;
  onCloseAnimation: () => void;
  selectedModel: IAnimationProduct;
  activeAnimation: LoubiAirwaysAnimation;
}

export const AnimationContext =
  React.createContext<IAnimationContext>(undefined);

export const FADE_SPEED = 1000;

const AnimationContextContainer = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const [showAnimation, setShowAnimation] = React.useState<boolean>(false);
  const [loaded, setLoaded] = React.useState<boolean>(false);

  const dispatch = useDispatch();

  const loubiAirwaysState = useLoubiAirwaysState();
  const { activeAnimation } = loubiAirwaysState;

  const setSelectedModel = (model: IAnimationProduct) => {
    dispatch(
      actionUpdateLoubiAirwaysSelectedModel(
        model ? { model, state: REMOTE_ACTION.OPEN } : undefined
      )
    );
  };
  const selectedModel =
    loubiAirwaysState.selectedModel?.state === REMOTE_ACTION.OPEN
      ? loubiAirwaysState.selectedModel?.model
      : undefined;

  const shouldShowWishlist = isUserLoubiAirwaysVIP();

  React.useEffect(() => {
    if (selectedModel) {
      logEvent(DID_SELECT_ANIMATION_MODEL, DID_SELECT_ANIMATION_MODEL, {
        animation: activeAnimation,
        selectedModel
      });
    }
  }, [selectedModel]);

  React.useEffect(() => {
    if (activeAnimation) {
      logEvent(DID_SHOW_ANIMATION, DID_SHOW_ANIMATION, {
        animation: activeAnimation
      });
      const popupContentType = getPopupContentTypeByAnimation(activeAnimation);
      dispatch(actionUpdateLoubiAirwaysPopupContentType(popupContentType));
    }
    setShowAnimation(!!activeAnimation);
  }, [activeAnimation]);

  const onCloseAnimation = () => {
    setSelectedModel(undefined);
    setShowAnimation(false);
    setTimeout(() => {
      dispatch(actionUpdateActiveAnimationInPano(undefined));
    }, FADE_SPEED);
    logEvent(DID_CLOSE_ANIMATION, DID_CLOSE_ANIMATION, {
      animation: activeAnimation
    });
  };

  return (
    <AnimationContext.Provider
      value={{
        setSelectedModel,
        setLoaded,
        onCloseAnimation,
        selectedModel,
        activeAnimation
      }}
    >
      <PreventMobileZoomContainer>
        {!loaded && showAnimation && (
          <div className="loading">
            <img src="/asset/louboutin/loading.gif" alt="Loading..." />
            <style jsx>
              {`
                .loading {
                  position: fixed;
                  top: 0;
                  bottom: 0;
                  left: 0;
                  right: 0;
                  z-index: 99;
                }
                .loading img {
                  position: fixed;
                  left: 50%;
                  width: 100px;
                  height: 100px;
                  top: 50%;
                  transform: translate(-50px, -50px);
                }
                :global(.in-meeting.SMLandscape) .loading img{
                  left:calc(50% + ${SMLandscapeNormalSpec.videosWrapper.width / 2}px)
                }
                :global(.in-meeting.MDLandscape) .loading img{
                  left:calc(50% + ${MDLandscapeNormalSpec.videosWrapper.width / 2}px)
                }
              `}
            </style>
          </div>
        )}
        <Transition
          in={showAnimation}
          timeout={FADE_SPEED}
          mountOnEnter
          unmountOnExit
        >
          {(state) => (
            <div className={`loubi-airways-animation ${state}`}>
              <LoubiAirwaysPopupContent />

              {children}
              {is2DAnimation(activeAnimation) ? (
                <TwoDProductImageContainer />
              ) : (
                <div className="letter-box-container">
                  <FlipBoardText text={selectedModel?.modelName} />
                </div>
              )}

              {selectedModel && shouldShowWishlist && (
                <LoubiAirwaysWishlist product={selectedModel} />
              )}

              <style jsx>{`
                .loubi-airways-animation {
                  height: 100%;
                  transition: opacity 1s ease-in-out;
                }

                .loubi-airways-animation.entering {
                  opacity: 0;
                }
                .loubi-airways-animation.entered {
                  opacity: 1;
                }
                .loubi-airways-animation.exiting {
                  opacity: 0;
                }
                .loubi-airways-animation.exited {
                  opacity: 0;
                }
              `}</style>
            </div>
          )}
        </Transition>
      </PreventMobileZoomContainer>
    </AnimationContext.Provider>
  );
};

export default AnimationContextContainer;
