export enum ModelEnvironment {
  WORKSHOP = 'workshop',
  CLOUD = 'cloud'
}

export const getModelEnvironment = (
  value: string
): ModelEnvironment | undefined => {
  const match = Object.keys(ModelEnvironment).find(
    (key) => value === ModelEnvironment[key]
  );
  if (match) {
    return ModelEnvironment[match];
  }
};
