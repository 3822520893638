import React from 'react';
const SinglePaperContent = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      <div className="SinglePaperContent">
        <div className="inner">{children}</div>
      </div>

      <style jsx global>{`
        .btn-spot {
          position: absolute;
          padding: 20px;
          z-index: 200;
          transform: translate(-50%, -50%);
        }
        .btn-spot:focus {
          box-shadow: none;
          outline: none;
        }

        .btn-spot div {
          pointer-events: none;
        }
      `}</style>
      <style jsx>{`
        .SinglePaperContent {
          z-index: 1000;
          transform: translate(-50%, -50%);
          position: absolute;
          left: 50%;
          top: 50%;
          display:flex;
          align-items:center;
          justify-content:center;
        }
        .inner {
          position: relative;
        }
        .backdrop {
          background: rgba(0, 0, 0, 0.4);
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        }
        .close {
          position: fixed;
          top: 20px;
          right: 20px;
          background: #fff;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          z-index: 1001;
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
        }
      `}</style>
    </>
  );
};

export default SinglePaperContent;
