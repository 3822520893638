import React from 'react';
import { AnimationContext } from '../../../../ThreejsAnimation/louboutin/AnimationContext';
import ContinueFlightButton from '../Buttons/ContinueFlightButton';
import TrayLid from './TrayLid';
import TwoDProductHotSpot from '../TwoDProduct/TwoDProductHotSpot';
import { AnimationProductModel } from '../TwoDProduct/TwoDProductsList';
import { assetBaseUrl } from '../../../../../config';

export const trayAsset = `${assetBaseUrl}/loubiairways/luggage-animation/tray-table/v7`;

const Tray = () => {
  const { setLoaded, onCloseAnimation } = React.useContext(AnimationContext);
  React.useEffect(() => {
    setLoaded(true);
  }, []);

  return (
    <>
      <div className="tray fixed-full">
        <div className="tray-container">
          <div>
            <img src={`${trayAsset}/tray-empty.png`} className="tray-box" />
            <TwoDProductHotSpot
              width={27}
              top={-27}
              left={-28}
              model={AnimationProductModel.CLLOUBIAIRWAYS025}
            />
            <TwoDProductHotSpot
              width={27}
              top={0}
              left={-28}
              model={AnimationProductModel.CLLOUBIAIRWAYS024}
            />
            <TwoDProductHotSpot
              width={17}
              top={-26.5}
              left={8.5}
              model={AnimationProductModel.CLLOUBIAIRWAYS026}
            />
            <TwoDProductHotSpot
              width={17}
              top={-8.5}
              left={8.5}
              model={AnimationProductModel.CLLOUBIAIRWAYS123}
            />
            <TwoDProductHotSpot
              width={17}
              top={9}
              left={8.5}
              model={AnimationProductModel.CLLOUBIAIRWAYS027}
            />
            <TrayLid />
          </div>
        </div>

        <style jsx>{`
          .tray {
            background: #000 url('${trayAsset}/bg.jpg') center center no-repeat;
            background-size: auto 100vh;
          }
          .tray-box {
            width: 100%;
            height: auto;
          }
          .tray-container {
            position: absolute;
            display: inline-block;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
          .tray-container :global(.img-spot.closing) {
            opacity: 1 !important;
            filter: drop-shadow(0 0 0.75rem #9c0f21) !important;
          }
          .tray-container :global(.img-spot.appear) {
            filter: none !important;
          }
          .tray-container > div {
            position: relative;
          }

          @media (orientation: landscape) {
            .tray-box {
              height: 48vh;
              width: auto;
            }
          }
          @media (orientation: portrait) {
            .tray {
              background-image: url('${trayAsset}/bg-m.jpg');
              background-size: 100vw auto;
            }
            .tray-container {
              margin-left: 5%;
              margin-top: -5vh;
              margin: auto;
              width: 90vw;
            }
            .tray-box {
              height: auto;
              max-width: 1500px;
            }
          }
        `}</style>
      </div>
      <ContinueFlightButton onClick={onCloseAnimation} />
    </>
  );
};

export default Tray;
